import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    DEFAULT_CURRENCY_CODE,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { SystemApiService } from '@wdx/clmi/api-services/services';
import { CustomRouteSerializer } from '@wdx/clmi/api-services/state';
import { WdxDialogService } from '@wdx/shared/components/wdx-dialog';
import { WdxToastComponent } from '@wdx/shared/components/wdx-toast';
import {
    IThemeService,
    LocalStorageService,
    WdxAppThemeService,
} from '@wdx/shared/utils';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MoleculeLoaderOverlayModule } from './components/molecules/molecule-loader-overlay/molecule-loader-overlay.module';
import { OrganismDynamicFormModalModule } from './components/organisms/organism-dynamic-form-modal/organism-dynamic-form-modal.module';
import { OrganismEngagementBarModule } from './components/organisms/organism-engagement-bar/organism-engagement-bar.module';
import { OrganismInfoCardCheckDuplicationsModalModule } from './components/organisms/organism-info-card-check-duplications-modal/organism-info-card-check-duplications-modal.module';
import { GlobalErrorHandler } from './global-error-handler';
import { AcceptedLanguageInterceptor } from './interceptors/accepted-language.interceptor';
import { ApiErrorInterceptor } from './interceptors/api-error.interceptor';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { DuplicateDetectionInterceptor } from './interceptors/duplicate-detection.interceptor';
import { ToastInterceptor } from './interceptors/toast.interceptor';
import { MappersModule } from './pipes/mappers/mappers.module';
import { PipesModule } from './pipes/pipes.module';
import { ApiPaginationService } from './services/api-pagination.service';
import { AuthenticationFactory } from './services/authentication.factor';
import { AuthenticationService } from './services/authentication.service';
import { ConfigService } from './services/config.service';
import { DynamicFormsManagementService } from './services/dynamic-forms-management.service';
import { EncodedQueryParamsService } from './services/encoded-query-params.service';
import { ModalManagementService } from './services/modal-management.service';
import { OffcanvasManagementService } from './services/offcanvas-management.service';
import { SignalRService } from './services/signalr.service';
import { AccessVisibilityModule } from './shared/features/access-visibility/access-visibility.module';
import { CommentsModule } from './shared/features/comments';
import { MessageModule } from './shared/features/message/message.module';
import { SystemSettingsService } from './shared/services';
import { globalEffects as rootEffects } from './state/_setup/effects';
import * as rootReducer from './state/_setup/reducers';
import { rootServices } from './state/_setup/services';
import { WINDOW_PROVIDERS } from './window.provider';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AccessVisibilityModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        HttpClientModule,
        MappersModule,
        MessageModule,
        PipesModule,
        ReactiveFormsModule,
        StoreModule.forRoot(rootReducer.reducers),
        EffectsModule.forRoot(rootEffects),
        StoreDevtoolsModule.instrument({
            maxAge: 500,
            logOnly: environment.production,
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomRouteSerializer,
        }),
        NgbModule,
        AuthModule.forRoot(),
        OrganismInfoCardCheckDuplicationsModalModule,
        CommentsModule,
        MoleculeLoaderOverlayModule,
        OrganismDynamicFormModalModule,
        OrganismEngagementBarModule,
        WdxToastComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => {
                return () => configService.load();
            },
            deps: [ConfigService, Sentry.TraceService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-GB',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'GBP',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ToastInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DuplicateDetectionInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptedLanguageInterceptor,
            multi: true,
        },
        {
            provide: AuthenticationService,
            useFactory: AuthenticationFactory,
            deps: [
                Router,
                ConfigService,
                LocalStorageService,
                SystemSettingsService,
                SystemApiService,
            ],
        },
        WdxAppThemeService,
        {
            provide: IThemeService,
            useExisting: WdxAppThemeService,
        },

        ApiPaginationService,
        ConfigService,
        DynamicFormsManagementService,
        EncodedQueryParamsService,
        ModalManagementService,
        OffcanvasManagementService,
        SignalRService,
        WdxDialogService,
        WINDOW_PROVIDERS,
        ...rootServices,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
