import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { AtomGuidanceTextIconModule } from '../../atoms/atom-guidance-text-icon/atom-guidance-text-icon.module';
import { MoleculeInputWrapperComponent } from './molecule-input-wrapper.component';

@NgModule({
    imports: [AtomGuidanceTextIconModule, CommonModule, WdxIconModule],
    declarations: [MoleculeInputWrapperComponent],
    exports: [MoleculeInputWrapperComponent],
})
export class MoleculeInputWrapperModule {}
