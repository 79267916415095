<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-ff-input-control
        *ngIf="INPUT_CONTROL_TYPES.includes($any(formElement?.fieldType))"
        [formElement]="formElement"
    ></wdx-ff-input-control>

    <wdx-ff-select
        *ngIf="
            MULTI_SELECT_CONTROL_TYPES.includes($any(formElement?.fieldType))
        "
        [formElement]="formElement"
    ></wdx-ff-select>

    <ng-container
        *ngIf="SELECT_CONTROL_TYPES.includes($any(formElement?.fieldType))"
    >
        <wdx-ff-checkboxes-control
            *ngIf="
                CHECKBOX_CONTROL_TYPES.includes($any(formElement?.elementStyle))
            "
            [formElement]="formElement"
        ></wdx-ff-checkboxes-control>

        <wdx-ff-radio-buttons-control
            *ngIf="
                RADIO_BUTTONS_CONTROL_TYPES.includes(
                    $any(formElement?.elementStyle)
                )
            "
            [formElement]="formElement"
        ></wdx-ff-radio-buttons-control>

        <wdx-ff-select
            *ngIf="
                !(
                    CHECKBOX_CONTROL_TYPES.includes(
                        $any(formElement?.elementStyle)
                    ) ||
                    RADIO_BUTTONS_CONTROL_TYPES.includes(
                        $any(formElement?.elementStyle)
                    )
                )
            "
            [formElement]="formElement"
        ></wdx-ff-select>
    </ng-container>

    <wdx-ff-date-control
        *ngIf="DATE_CONTROL_TYPES.includes($any(formElement?.fieldType))"
        [formElement]="formElement"
    ></wdx-ff-date-control>

    <wdx-ff-file-upload-control
        *ngIf="FILE_UPLOAD_CONTROL_TYPES.includes($any(formElement?.fieldType))"
        [formElement]="formElement"
    ></wdx-ff-file-upload-control>

    <wdx-ff-currency-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Currency"
        [formElement]="formElement"
    ></wdx-ff-currency-control>

    <wdx-ff-telephone-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.PhoneNumber"
        [formElement]="formElement"
    ></wdx-ff-telephone-control>

    <wdx-ff-rich-text-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.RichText"
        [formElement]="formElement"
    ></wdx-ff-rich-text-control>

    <wdx-ff-hierarchical-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Hierarchical"
        [formElement]="formElement"
    ></wdx-ff-hierarchical-control>

    <wdx-ff-document-lookup-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.DocumentLookup"
        [formElement]="formElement"
    ></wdx-ff-document-lookup-control>

    <wdx-ff-boolean-control
        *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Boolean"
        [formElement]="formElement"
    ></wdx-ff-boolean-control>

    <wdx-ff-form-wrapped-control
        *ngIf="WRAPPED_CONTROL_TYPES.includes($any(formElement?.fieldType))"
        [formElement]="formElement"
        [formContext]="formContext"
        [formControlName]="$any(formElement.name)"
    ></wdx-ff-form-wrapped-control>
</ng-container>
