import { Injectable } from '@angular/core';
import { SitemapEntry } from '@wdx/clmi/api-models';
import { ROUTE_PREFIX } from '@wdx/shared/utils';
import { NavigationMenu } from '../../../models/navigation-menu.model';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _siteMap: NavigationMenu[];

    public get siteMap(): NavigationMenu[] {
        return this._siteMap;
    }

    public setSiteMap(siteMap: SitemapEntry[]): void {
        this._siteMap = siteMap.map((menuItem) =>
            this.createNavigationMenu(menuItem)
        );
    }

    private createNavigationMenu(
        menuItem: SitemapEntry,
        parentItem?: SitemapEntry
    ): NavigationMenu {
        return {
            id: menuItem.code,
            pageType: menuItem.pageType,
            translationKey: menuItem?.displayName?.key,
            ...(menuItem?.icon ? { icon: menuItem?.icon } : {}),
            routerLink: parentItem
                ? [...ROUTE_PREFIX, parentItem.code, menuItem.code]
                : [...ROUTE_PREFIX, menuItem.code],
            cySelector: menuItem.code,
            disabledBy: [],
            ...(menuItem?.children?.length
                ? {
                      menu: menuItem?.children.map((child) =>
                          this.createNavigationMenu(child, menuItem)
                      ),
                  }
                : {}),
        };
    }
}
