import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { newSystemEvent } from './system-events.actions';
import { saveCompletionSummarySuccess } from '@wdx/shared/infrastructure/form-framework';
import * as appActions from '../apps/apps.actions';

@Injectable()
export class SystemEventsEffects {
    private actions$ = inject(Actions);

    captureEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveCompletionSummarySuccess, appActions.confirmSuccess),
            map((action) =>
                newSystemEvent({
                    event: { sourceAction: action, timeStamp: Date.now() },
                })
            )
        )
    );
}
