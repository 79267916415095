import { Action } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/api-models';

export type DialogOptionsData = {
    name?: string;
} & Record<string, any>;

export type DialogComponentOptionsData = {
    title?: string;
    entityId?: string;
    entityType?: SystemEntity;
    bodyText?: string[];
} & Record<string, any>;

export interface DialogOptions {
    dialogMode: DialogMode;
    confirmAction?: Action;
    callback?: () => any;
    data?: DialogOptionsData;
}

export enum DialogMode {
    CancelActivity = 'CancelActivity',
    CancelCase = 'CancelCase',
    CancelDocumentRequirement = 'CancelDocumentRequirement',
    DeleteActivity = 'DeleteActivity',
    DeleteBatchList = 'DeleteBatchList',
    DeleteBatchMessageRow = 'DeleteBatchMessageRow',
    DeleteBenchmark = 'DeleteBenchmark',
    DeleteDashboard = 'DeleteDashboard',
    DeleteDocument = 'DeleteDocument',
    DeleteDocumentRequirement = 'DeleteDocumentRequirement',
    DeleteDocumentRequirementType = 'DeleteDocumentRequirementType',
    DeleteDocumentTemplate = 'DeleteDocumentTemplate',
    DeleteFeeStructure = 'DeleteFeeStructure',
    DeleteFiledDocument = 'DeleteFiledDocument',
    DeleteMandate = 'DeleteMandate',
    DeleteMandateGroup = 'DeleteMandateGroup',
    DeleteMultipleMessage = 'DeleteMultipleMessage',
    DeleteObjective = 'DeleteObjective',
    DeleteOpportunity = 'DeleteOpportunity',
    DeleteRiskLevel = 'DeleteRiskLevel',
    DeleteSalutation = 'DeleteSalutation',
    DeleteView = 'DeleteView',
    HasAtLeastOneMessageCannotBeReplayed = 'HasAtLeastOneMessageCannotBeReplayed',
    RemoveTeamMember = 'RemoveTeamMember',
    ReplayMessageBatch = 'ReplayMessageBatch',
    ReplayMultipleMessage = 'ReplayMultipleMessage',
    RunPepAndSanctionsCheck = 'RunPepAndSanctionsCheck',
}
