import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushModule } from '@ngrx/component';
import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import { WdxToggleButtonComponent } from '@wdx/shared/components/wdx-toggle-button';
import { AtomAddButtonModule } from '../../../components/atoms/atom-add-button/atom-add-button.module';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonDumbComponent } from '../../../components/molecules/molecule-action-button/molecule-action-button-dumb/molecule-action-button-dumb.component';
import { MoleculeActionButtonComponent } from '../../../components/molecules/molecule-action-button/molecule-action-button/molecule-action-button.component';
import { MoleculeContextMenuDropdownModule } from '../../../components/molecules/molecule-context-menu-dropdown/molecule-context-menu-dropdown.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { OrganismModalModule } from '../../organisms/organism-modal/organism-modal.module';
import { ActionsModalComponent } from './shared/actions-modal/actions-modal.component';

@NgModule({
    imports: [
        AtomAddButtonModule,
        AtomIconButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeContextMenuDropdownModule,
        PushModule,
        WdxIconContainerModule,
        WdxIconModule,
        WdxIconButtonModule,
        WdxToggleButtonComponent,
        OrganismModalModule,
        WdxButtonModule,
        WdxIsLoadingModule,
    ],
    declarations: [
        MoleculeActionButtonComponent,
        MoleculeActionButtonDumbComponent,
        ActionsModalComponent,
    ],
    exports: [MoleculeActionButtonComponent, MoleculeActionButtonDumbComponent],
})
export class MoleculeActionButtonModule {}
