import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '../../services/translations';

@Pipe({
    name: 'translate',
    standalone: true,
})
export class TranslatePipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(value: string): string {
        if (!value) {
            return '';
        }
        return this.translationsService.getTranslationByKey(
            value?.toUpperCase()
        );
    }
}
