import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Action, ActionsSubject } from '@ngrx/store';

import { SystemEntity } from '@wdx/clmi/api-models';
import * as viewActions from '../../state/views/views.actions';
import { ofType } from '@ngrx/effects';

@Injectable()
export class ViewsFacadeService {
    constructor(private actionsSubject$: ActionsSubject) {}

    /**
     * The method should be used to delete a personal view.
     *
     * @param systemEntityType: SystemEntity
     * @param viewId: string
     * @returns Action
     */
    deleteViewAction(systemEntityType: SystemEntity, viewId: string): Action {
        return viewActions.deleteByEntityType({
            entityType: systemEntityType,
            viewId,
        });
    }

    /**
     * An ActionsSubject that emits action payload on `deleteByEntityTypeSuccess`
     * @returns Observable<View>
     */
    deleteViewSuccess$(): Observable<{
        entityType: SystemEntity;
        viewId: string;
    }> {
        return this.actionsSubject$.pipe(
            ofType(viewActions.deleteByEntityTypeSuccess)
        );
    }
}
