import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { NgxMaskModule } from 'ngx-mask';

import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxDocumentInfoComponent } from '@wdx/shared/components/wdx-document-info';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import {
    AddressFormatService,
    FormSummaryValuePipe,
    IFormDynamicData,
    TelephonePatternPipe,
    WdxFormFrameworkModule,
} from '@wdx/shared/infrastructure/form-framework';
import {
    DisplayDatePipe,
    FileNamePipe,
    TitleCasePipe,
    WdxMarkdownToHtmlPipe,
} from '@wdx/shared/utils';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomHighlightTextModule } from '../../../components/atoms/atom-highlight-text/atom-highlight-text.module';
import { MoleculeAvatarGroupModule } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeSummaryFieldModule } from '../../../components/molecules/molecule-summary-field/molecule-summary-field.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ReactiveFormDynamicDataService } from '../../services';
import { OrganismFormSummaryContentComponent } from './components/organism-form-summary/organism-form-summary-content/organism-form-summary-content.component';
import { OrganismFormSummaryComponent } from './components/organism-form-summary/organism-form-summary.component';
import { FormSummaryPipesModule } from './pipes/form-summary-pipes.module';

@NgModule({
    declarations: [
        OrganismFormSummaryComponent,
        OrganismFormSummaryContentComponent,
    ],
    imports: [
        AtomAsyncDataModule,
        AtomHighlightTextModule,
        CommonModule,
        FormSummaryPipesModule,
        MoleculeAvatarGroupModule,
        WdxDocumentInfoComponent,
        MoleculeSummaryFieldModule,
        NgbAccordionModule,
        NgxMaskModule.forRoot(),
        PipesModule,
        PushModule,
        WdxBadgeModule,
        WdxIconModule,
        WdxInfoCardModule,
        WdxMarkdownToHtmlPipe,
        WdxFormFrameworkModule,
        WdxIconContainerModule,
        FileNamePipe,
        FormSummaryValuePipe,
        TitleCasePipe,
        TelephonePatternPipe,
        DisplayDatePipe,
    ],
    exports: [
        OrganismFormSummaryComponent,
        OrganismFormSummaryContentComponent,
    ],
    providers: [
        AddressFormatService,
        { provide: IFormDynamicData, useClass: ReactiveFormDynamicDataService },
    ],
})
export class FormSummaryModule {}
