import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../molecule-action-button/molecule-action-button.module';
import { MoleculeAvatarGroupModule } from '../molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeCaseCardComponent } from './molecule-case-card.component';
import { WdxToggleButtonComponent } from '@wdx/shared/components/wdx-toggle-button';
import { DisplayDatePipe, FunctionPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        AtomIconButtonModule,
        CommonModule,
        MoleculeActionButtonModule,
        MoleculeAvatarGroupModule,
        NgbProgressbarModule,
        PipesModule,
        RouterModule,
        WdxIconModule,
        WdxToggleButtonComponent,
        DisplayDatePipe,
        FunctionPipe,
    ],
    declarations: [MoleculeCaseCardComponent],
    exports: [MoleculeCaseCardComponent],
})
export class MoleculeCaseCardModule {}
