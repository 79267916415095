import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FormSummaryHistoricService {
    formUuid: string;
    loadHistoricData: boolean;

    resetData(): void {
        this.formUuid = null;
        this.loadHistoricData = null;
    }
}
