import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxPaginationComponent } from './wdx-pagination.component';

export const IMPORTS = [CommonModule, WdxIconModule];

@NgModule({
    declarations: [WdxPaginationComponent],
    imports: IMPORTS,
    exports: [WdxPaginationComponent],
})
export class WdxPaginationModule {}
