import { Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';

import { EntityRouteService } from '@wdx/clmi/utils/services';
import { Severity } from '@wdx/shared/utils';
import { ICON_UNLINK } from '../../../constants/icons.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { OpportunityCardItem } from '../../../models/opportunity-card-item.model';
import { PermissionsService } from '../../../shared/services/permissions/permissions.service';
import * as relatedRecordsActions from '../../../state/related-records/related-records.actions';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-opportunity-card',
    templateUrl: './molecule-opportunity-card.component.html',
    styleUrls: ['./molecule-opportunity-card.component.scss'],
})
export class MoleculeOpportunityCardComponent implements OnInit {
    @Input() opportunity: OpportunityCardItem;
    @Input() fullCardLink: boolean;

    canLink$: Observable<boolean>;

    readonly SEVERITY = Severity;
    readonly SYSTEM_ENTITY = SystemEntity;

    now: Date = new Date();

    linkActionButton: ActionButton = {
        mode: ActionButtonMode.IconButton,
        icon: 'link',
    };

    unLinkActionButton: ActionButton = {
        mode: ActionButtonMode.IconButton,
        label: 'Unlink',
        icon: ICON_UNLINK.icon,
    };

    protected entityRouteService = inject(EntityRouteService);

    constructor(
        private store$: Store,
        private permissionsService: PermissionsService
    ) {}

    ngOnInit(): void {
        this.canLink$ = this.permissionsService.canEdit$(
            this.opportunity?.linkToEntityType,
            this.opportunity?.linkToEntityId
        );
    }

    getStageArray(max: number): number[] {
        // The pipeline stage array from the API is 1-indexed hence having to add 1 to the index
        if (max) {
            return new Array(max).fill(0).map((x, i) => i + 1);
        }
    }

    getRouterLink(
        opportunity: OpportunityCardItem,
        entityRouteService: EntityRouteService,
        disable?: boolean
    ): string[] {
        if (!disable && opportunity.primaryContact) {
            return entityRouteService.routeForEntity(
                SystemEntity.Opportunity,
                opportunity.id
            );
        }
        return null;
    }

    onCreateLinkToOpportunityForEntity(
        opportunityCard: OpportunityCardItem
    ): void {
        this.store$.dispatch(
            relatedRecordsActions.createLinkToOpportunityForEntity({
                entityType: opportunityCard.linkToEntityType,
                entityId: opportunityCard.linkToEntityId,
                opportunityId: opportunityCard.id,
            })
        );
    }

    onDeleteRelatedRecordClicked(opportunityCard: OpportunityCardItem): void {
        this.store$.dispatch(
            relatedRecordsActions.deleteLinkToOpportunityForEntity({
                entityType: opportunityCard.linkToEntityType,
                entityId: opportunityCard.linkToEntityId,
                opportunityId: opportunityCard.id,
            })
        );
    }
}
