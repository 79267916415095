<ng-container *ngIf="avatars | fn: getAvatarGroup; let avatarGroup">
    <ng-container
        *ngIf="avatarGroup?.length > 1 || forceGroup; else singleAvatar"
    >
        <div
            class="avatar-group avatar-group-{{
                size
            }} d-flex align-items-center"
            [class.fixed-width]="fixedWidth"
        >
            <ng-container *ngFor="let avatar of avatarGroup; let i = index">
                <molecule-avatar
                    *ngIf="i < limit - 1 || avatarGroup.length === limit"
                    class="avatar-group-avatar rounded-circle pointer"
                    [class.on-muted-bg]="onMutedBg"
                    [class.on-active-bg]="onActiveBg"
                    [style.z-index]="limit - i"
                    [avatar]="avatar"
                    [size]="size"
                    [isAvatarInfoEnabled]="avatar?.isAvatarInfoEnabled"
                ></molecule-avatar>
            </ng-container>

            <ng-container
                *ngIf="
                    avatarGroup | fn: getOverflowAvatarsMenu:limit;
                    let overflowAvatarsMenu
                "
            >
                <div ngbDropdown container="body" class="z-index-0">
                    <button
                        ngbDropdownToggle
                        class="btn btn-icon avatar avatar-{{
                            size
                        }} bg-icon border-transparent z-index-0 position-relative"
                        (click)="$event.stopPropagation()"
                    >
                        <span class="avatar-alt-initials"
                            >+{{ avatarGroup.length - limit + 1 }}</span
                        >
                    </button>
                    <molecule-context-menu
                        ngbDropdownMenu
                        [menu]="overflowAvatarsMenu"
                    ></molecule-context-menu>
                </div>
                <atom-modal-wrapper
                    *ngFor="let overflowAvatar of overflowAvatarsMenu"
                    [modalId]="overflowAvatar.modalId"
                    [modalTemplate]="avatarInfoModal"
                >
                    <ng-template #avatarInfoModal>
                        <organism-modal modalTitle="Avatar Information">
                            <molecule-avatar-info
                                [avatar]="overflowAvatar.avatar"
                                [editModalId]="editModalId"
                            ></molecule-avatar-info>
                        </organism-modal>
                    </ng-template>
                </atom-modal-wrapper>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #singleAvatar>
    <molecule-avatar
        class="avatar-single pointer avatar-group-border-transparent"
        [avatar]="avatars[0]"
        [size]="size"
        [isAvatarInfoEnabled]="avatars[0]?.isAvatarInfoEnabled"
        [editModalId]="editModalId"
    ></molecule-avatar>
</ng-template>
