<wdx-dialog
    cancelButtonLabel="Cancel"
    actionButtonLabel="Confirm"
    [title]="'Reject Item'"
    [actionButtonStyle]="WDX_BUTTON_STYLE.Danger"
    (wdxOnClick)="onClick()"
>
    <p class="mb-4">To reject this item you must leave a comment.</p>
    <form [formGroup]="commentForm">
        <wdx-form-field label="Comment" class="mb-3 d-block">
            <textarea
                title="comment"
                rows="3"
                formControlName="comment"
                wdxInputText
                [attr.maxlength]="500"
            ></textarea>
        </wdx-form-field>

        <wdx-form-field label="Reason">
            <wdx-multi-select
                [formControlNameOrIndex]="'reason'"
                [options]="rejectionReasons$ | ngrxPush"
                [multiple]="false"
                [searchable]="false"
            ></wdx-multi-select>
        </wdx-form-field>
    </form>
</wdx-dialog>
