import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
} from '@angular/core';
import { FormDefinition, FormLayout } from '@wdx/clmi/api-models';
import { FormDisplayMode, FormSetup } from '@wdx/shared/utils';
import { DynamicFormsManagementService } from '../services/dynamic-forms-management.service';

@Directive({
    selector: '[dynamicFormId] , [dynamicFormSetup]',
})
export class DynamicFormDirective {
    @Input() dynamicFormId: string;
    @Input() dynamicFormSetup: FormSetup;
    @Input() entityId: string;
    @Input() formDisplayMode: FormDisplayMode;
    @Input() hardCodedFormDefinition: FormDefinition;
    @Input() hardCodedFormLayout: FormLayout;

    @HostListener('click') onClick() {
        if (!this.dynamicFormId && !this.dynamicFormSetup) {
            return;
        }

        const formSetup: FormSetup = {
            ...(this.dynamicFormId
                ? { formId: this.dynamicFormId || this.dynamicFormSetup.formId }
                : {}),
            ...(this.entityId ? { entityId: this.entityId } : {}),
            ...(this.formDisplayMode
                ? { formDisplayMode: this.formDisplayMode }
                : {}),
            ...(this.hardCodedFormDefinition
                ? { hardCodedFormDefinition: this.hardCodedFormDefinition }
                : {}),
            ...(this.hardCodedFormLayout
                ? { hardCodedFormLayout: this.hardCodedFormLayout }
                : {}),
            ...(this.dynamicFormSetup?.initialisationMode
                ? {
                      initialisationMode:
                          this.dynamicFormSetup.initialisationMode,
                  }
                : {}),
            ...(this.dynamicFormSetup || {}),
        };

        this.dynamicFormsManagementService.openDynamicFormModal(formSetup);
    }

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2,
        private dynamicFormsManagementService: DynamicFormsManagementService
    ) {
        renderer.setAttribute(
            elementRef.nativeElement,
            'href',
            'javascript:void(0)'
        );
    }
}
