<widget-activities-list
    widgetTitle="Activities"
    allActivitiesModalTitle="All Activities"
    [activities]="relatedActivities$ | ngrxPush"
    [activitiesPerRow]="cardsPerRow"
    [isLoading]="relatedActivitiesIsLoading$ | ngrxPush"
    [hasError]="relatedActivitiesHasError$ | ngrxPush"
    [spokeButton]="{ label: 'See all activities' }"
    [showActivitiesListActionsButtons]="true"
    [totalRecordCount]="(relatedActivities$ | ngrxPush)?.length"
    [scrollMode]="SCROLL_MODE.Responsive"
>
    <div class="activities-list-action-buttons">
        <molecule-action-button
            [actionButton]="createActivityActionButton"
        ></molecule-action-button>
    </div>
</widget-activities-list>
