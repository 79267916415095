import { SystemEntity } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import {
    MEETING_FORM_ID,
    PHONECALL_FORM_ID,
    SECURE_MESSAGE_FORM_ID,
} from '@wdx/shared/utils';
import {
    ICON_ADD,
    ICON_ENVELOPE_DOT,
    ICON_MEETING,
    ICON_PHONECALL,
} from '../../constants/icons.constants';
import { ActionButton } from '../../models/action-button.model';
import { ActionButtonMode } from '../../models/action-buttons-mode.model';
import { Privilege } from '../../models/privilege.model';
import { ActivityQueryType } from '@wdx/clmi/api-services/models';

export const INBOX_DATA = {
    viewType: SystemEntity.Activity,
    queryType: ActivityQueryType.Inbox,
};

export const ADD_ACTION_BTN: ActionButton = {
    mode: ActionButtonMode.DropdownButtonIcon,
    icon: ICON_ADD.icon,
    privileges: [Privilege.CreateActivity],
    cySelector: `action-inbox-quick-create`,
    dropdownMenu: [
        {
            label: 'Phone Call',
            value: 'phoneCall',
            cySelector: 'option-phoneCall',
            icon: ICON_PHONECALL.icon,
            formSetup: {
                formId: PHONECALL_FORM_ID,
                initialisationParams: {},
            },
        },
        {
            label: 'Meeting',
            value: 'meeting',
            cySelector: 'option-meeting',
            icon: ICON_MEETING.icon,
            formSetup: {
                formId: MEETING_FORM_ID,
                initialisationParams: {},
            },
        },
        {
            label: 'Secure Message',
            value: 'secureMessage',
            cySelector: 'secure-message',
            icon: ICON_ENVELOPE_DOT.icon,
            formSetup: {
                formId: SECURE_MESSAGE_FORM_ID,
                initialisationParams: {},
            },
            feature: FeatureFlag.SecureMessage,
        },
    ],
};
