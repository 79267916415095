import { Action, createReducer, on } from '@ngrx/store';
import {
    DocumentRequirement,
    DocumentRequirementStatusType,
    DocumentRequirementType,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as documentChecklistActions from './document-checklist.actions';

export interface State {
    documentRequirements?: CrudStateObject<DocumentRequirement>;
    documentRequirementTypes?: CrudState<DocumentRequirementType>;
}

export const initialState: State = {
    documentRequirements: {},
    documentRequirementTypes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        documentChecklistActions.getDocumentRequirements,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props?.entityType]: {
                    ...state.documentRequirements[props?.entityType],
                    [props?.entityId]: {
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementsSuccess,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props?.entityType]: {
                    ...state.documentRequirements[props?.entityType],
                    [props?.entityId]: {
                        isLoadingList: false,
                        hasLoadingListError: false,
                        list: props.documentRequirements,
                        filteredList: props.documentRequirements,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementsFailure,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props?.entityType]: {
                    ...state.documentRequirements[props?.entityType],
                    [props?.entityId]: {
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.filterDocumentRequirementsForClient,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.clientId]: {
                    ...state.documentRequirements[props.clientId],
                    filteredList:
                        props.searchText.length < 2
                            ? state.documentRequirements[props.clientId].list
                            : state.documentRequirements[props.clientId].list
                                  .filter(
                                      (documentRequirement) =>
                                          documentRequirement.requirementType.name
                                              .toLowerCase()
                                              .indexOf(
                                                  props.searchText.toLowerCase()
                                              ) > -1
                                  )
                                  .slice(0, 10),
                },
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementTypes,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementTypesSuccess,
        (state, props): State => ({
            ...state,
            documentRequirementTypes: {
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.documentRequirementTypes,
            },
        })
    ),

    on(
        documentChecklistActions.getDocumentRequirementTypesFailure,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                isLoadingList: false,
                hasLoadingListError: true,
                list: null,
            },
        })
    ),

    on(
        documentChecklistActions.cancelDocumentRequirement,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.entityType]: {
                    ...state.documentRequirements[props.entityType],
                    [props.entityId]: {
                        ...state.documentRequirements[props.entityType][
                            props.entityId
                        ],
                        isUpdating: true,
                        hasUpdatingError: false,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.cancelDocumentRequirementSuccess,
        (state, props): State => {
            const list = [
                ...state.documentRequirements[props.entityType][props.entityId]
                    .list,
            ] as DocumentRequirement[];

            props.documentRequirementIds.forEach((documentRequirementId) => {
                const recordIndex = list.findIndex(
                    (record) => record.id === documentRequirementId
                );
                if (recordIndex > -1) {
                    list[recordIndex] = {
                        ...list[recordIndex],
                        status: DocumentRequirementStatusType.Cancelled,
                        workflowStep: null,
                    };
                }
            });

            return {
                ...state,
                documentRequirements: {
                    ...state.documentRequirements,
                    [props.entityType]: {
                        ...state.documentRequirements[props.entityType],
                        [props.entityId]: {
                            ...state.documentRequirements[props.entityType][
                                props.entityId
                            ],
                            isUpdating: false,
                            hasUpdatingError: false,
                            list,
                        },
                    },
                },
            };
        }
    ),

    on(
        documentChecklistActions.cancelDocumentRequirementError,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.entityType]: {
                    ...state.documentRequirements[props.entityType],
                    [props.entityId]: {
                        ...state.documentRequirements[props.entityType][
                            props.entityId
                        ],
                        isUpdating: false,
                        hasUpdatingError: true,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.deleteDocumentRequirement,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.entityType]: {
                    ...state.documentRequirements[props.entityType],
                    [props.entityId]: {
                        ...state.documentRequirements[props.entityType][
                            props.entityId
                        ],
                        isUpdating: true,
                        hasUpdatingError: false,
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.deleteDocumentRequirementSuccess,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.entityType]: {
                    ...state.documentRequirements[props.entityType],
                    [props.entityId]: {
                        ...state.documentRequirements[props.entityType][
                            props.entityId
                        ],
                        isUpdating: false,
                        hasUpdatingError: false,
                        list:
                            state.documentRequirements[props.entityType][
                                props.entityId
                            ]?.list?.filter(
                                (documentRequirementType) =>
                                    documentRequirementType.id !==
                                    props.documentRequirementId
                            ) ?? [],
                    },
                },
            },
        })
    ),

    on(
        documentChecklistActions.deleteDocumentRequirementError,
        (state, props): State => ({
            ...state,
            documentRequirements: {
                ...state.documentRequirements,
                [props.entityType]: {
                    ...state.documentRequirements[props.entityType],
                    [props.entityId]: {
                        ...state.documentRequirements[props.entityType][
                            props.entityId
                        ],
                        isUpdating: false,
                        hasUpdatingError: true,
                    },
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
