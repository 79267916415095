import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { View } from '@wdx/clmi/api-models';
import { NavItem, WdxSize } from '@wdx/shared/utils';
import { USER_FEEDBACK_NO_DATA } from '../constants/user-feedback.constants';
import { SpokeButton, SpokeButtonPosition } from '../models/spoke-button.model';
import { RandomStringPipe } from '../pipes/random-string.pipe';
import { BaseDiDependantClass } from './base-di-dependant.class';

@Directive()
export class BaseWidgetContainerClass
    extends BaseDiDependantClass
    implements OnInit
{
    @Input() widgetTitle: string;
    @Input() widgetSize: WdxSize;
    @Input() spokeButton: SpokeButton;
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() isForInfinityScroll: boolean;
    @Input() count: number;
    @Input() hideCount: boolean;
    @Input() limit = 4;
    @Input() showNoDataMessage: boolean;
    @Input() showNoDataSVG: boolean;
    @Input() noDataMessage = USER_FEEDBACK_NO_DATA;
    @Input() containerClass: string;
    @Input() useDefaultWidgetModal = true;

    @Output() spokeButtonClicked = new EventEmitter<SpokeButton>();
    @Output() infinityScrollFired = new EventEmitter();
    @Output() viewSelected = new EventEmitter<View>();

    instanceId: string;

    readonly SPOKE_BUTTON_POSITION = SpokeButtonPosition;

    ngOnInit(): void {
        this.instanceId = new RandomStringPipe().transform();
    }

    setDefaultModal(id: string) {
        if (this.spokeButton && this.useDefaultWidgetModal) {
            this.spokeButton.modalId = id;
        }
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }

    onViewSelected(viewMenuItem: NavItem): void {
        const view = viewMenuItem.data;
        if (this.spokeButton) {
            this.spokeButton.activeView = view;
        }
        this.viewSelected.emit(view);
    }

    onSpokeButtonClicked(): void {
        this.spokeButtonClicked.emit(this.spokeButton);
    }
}
