import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Activity, FileIndex, SystemEntity } from '@wdx/clmi/api-models';
import { operationsActions } from '@wdx/clmi/api-services/state';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../_setup/reducers';
import * as activitiesActions from './activities.actions';
import * as activitiesSelectors from './activities.selectors';
import { ActivitiesService } from './activities.service';

@Injectable()
export class ActivitiesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private activitiesService = inject(ActivitiesService);
    private toastService = inject(WdxToastService);

    getAttachmentsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getAttachmentsForId),
            mergeMap((action) =>
                this.activitiesService
                    .getAttachmentsForId(action.activityId)
                    .pipe(
                        map((attachments) =>
                            activitiesActions.getAttachmentsForIdSuccess({
                                activityId: action.activityId,
                                attachments,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.getAttachmentsForIdFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getDocumentsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getDocumentsForId),
            withLatestFrom(
                this.store$.select(activitiesSelectors.getInfinityPaging, {
                    id: GLOBAL_STATE_INDEX_ID,
                })
            ),
            switchMap(([action, paging]) =>
                this.activitiesService
                    .getDocumentsForId(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                        },
                        action.activityId
                    )
                    .pipe(
                        map((documents) =>
                            activitiesActions.getDocumentsForIdSuccess({
                                documents,
                                activityId: action.activityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.getDocumentsForIdFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getLastActivityForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getLastActivityForParty),
            mergeMap((action) =>
                this.activitiesService
                    .getLastActivityForParty(action.partyId)
                    .pipe(
                        map((activity) =>
                            activitiesActions.getLastActivityForPartySuccess({
                                partyId: action.partyId,
                                activity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.getLastActivityForPartyFailure(
                                    {
                                        partyId: action.partyId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getNextActivityForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getNextActivityForParty),
            mergeMap((action) =>
                this.activitiesService
                    .getNextActivityForParty(action.partyId)
                    .pipe(
                        map((activity) =>
                            activitiesActions.getNextActivityForPartySuccess({
                                partyId: action.partyId,
                                activity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.getNextActivityForPartyFailure(
                                    {
                                        partyId: action.partyId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getForClientProduct),
            withLatestFrom(
                this.store$.select(activitiesSelectors.getInfinityPaging, {
                    id: GLOBAL_STATE_INDEX_ID,
                })
            ),
            switchMap(([action, paging]) =>
                this.activitiesService
                    .getForClientProduct(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                        },
                        action.clientProductId
                    )
                    .pipe(
                        map((activities) =>
                            activitiesActions.getForClientProductSuccess({
                                activities,
                                clientProductId: action.clientProductId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.getForClientProductFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getForClient),
            withLatestFrom(
                this.store$.select(
                    activitiesSelectors.getClientActivitiesInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID }
                )
            ),
            switchMap(([action, paging]) =>
                this.activitiesService
                    .getForClient(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                            sortDescending: true,
                        },
                        action.clientId
                    )
                    .pipe(
                        map((clientActivities) =>
                            activitiesActions.getForClientSuccess({
                                clientActivities,
                                clientId: action.clientId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.getForClientFailure({
                                    clientId: action.clientId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getForId),
            switchMap((action) =>
                this.activitiesService.getForId(action.activityId).pipe(
                    map((activity) =>
                        activitiesActions.getForIdSuccess({
                            activity,
                            activityId: action.activityId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            activitiesActions.getForIdFailure({
                                activityId: action.activityId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.deleteActivity),
            switchMap((action) =>
                this.activitiesService.deleteForId(action.activityId).pipe(
                    map(() => activitiesActions.deleteActivitySuccess()),
                    catchError((error) =>
                        of(
                            activitiesActions.deleteActivityFailure({
                                activityId: action.activityId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteActivitySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(activitiesActions.deleteActivitySuccess),
                tap(() => {
                    this.toastService.show({
                        body: ['Record successfully deleted'],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    getHistoryForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getHistoryForId),
            switchMap((action) =>
                this.activitiesService.getHistoryForId(action.activityId).pipe(
                    map((activityHistory) =>
                        activitiesActions.getHistoryForIdSuccess({
                            activityHistory,
                            activityId: action.activityId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            activitiesActions.getHistoryForIdFailure({
                                activityId: action.activityId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    setStatusForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.setStatusForId),
            switchMap((action) =>
                this.activitiesService
                    .setStatusForId(action.activityId, action.status)
                    .pipe(
                        map((activity: Activity) =>
                            activitiesActions.setStatusForIdSuccess({
                                activity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.setStatusForIdFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    setStatusForIdSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.setStatusForIdSuccess),
            map((action) =>
                operationsActions.getOperationsForId({
                    entityType: SystemEntity.Activity,
                    entityId: action.activity?.id,
                })
            )
        )
    );

    setDueDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.setDueDate),
            switchMap((action) =>
                this.activitiesService
                    .setDueDate(action.activityId, action.date)
                    .pipe(
                        map((activity) =>
                            activitiesActions.setDueDateSuccess({
                                activity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                activitiesActions.setDueDateFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    cancelActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.cancelActivity),
            switchMap((action) =>
                this.activitiesService.cancelActivity(action.activityId).pipe(
                    map((activity) =>
                        activitiesActions.cancelActivitySuccess({
                            activity,
                        })
                    ),
                    catchError((error) =>
                        of(
                            activitiesActions.cancelActivityFailure({
                                activityId: action.activityId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    cancelActivitySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.cancelActivitySuccess),
            tap(() => {
                this.toastService.show({
                    body: ['Activity successfully cancelled'],
                    severity: Severity.Success,
                });
            }),
            map((action) =>
                operationsActions.getOperationsForId({
                    entityType: SystemEntity.Activity,
                    entityId: action.activity?.id,
                })
            )
        )
    );

    uploadAttachment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.uploadAttachment),
            switchMap((action) =>
                this.activitiesService
                    .uploadAttachment(action.activityId, action.file)
                    .pipe(
                        takeUntil(
                            this.actions$.pipe(
                                ofType(activitiesActions.uploadAttachmentCancel)
                            )
                        ),
                        map((event) => {
                            if (event) {
                                switch (event.type) {
                                    case HttpEventType.Sent: {
                                        return activitiesActions.uploadAttachmentStarted(
                                            { activityId: action.activityId }
                                        );
                                    }
                                    case HttpEventType.UploadProgress: {
                                        return activitiesActions.uploadAttachmentProgress(
                                            {
                                                activityId: action.activityId,
                                                progress: Math.round(
                                                    (100 * event.loaded) /
                                                        event.total
                                                ),
                                            }
                                        );
                                    }
                                    case HttpEventType.ResponseHeader:
                                    case HttpEventType.DownloadProgress: {
                                        return activitiesActions.uploadAttachmentDownloading(
                                            {
                                                activityId: action.activityId,
                                            }
                                        );
                                    }
                                    case HttpEventType.Response: {
                                        const body = (
                                            event as HttpResponse<any>
                                        ).body as FileIndex[];

                                        if (event.status === 200) {
                                            return activitiesActions.uploadAttachmentComplete(
                                                {
                                                    activityId:
                                                        action.activityId,
                                                    fileIndex: body[0],
                                                }
                                            );
                                        } else {
                                            return activitiesActions.uploadAttachmentFailure(
                                                {
                                                    activityId:
                                                        action.activityId,
                                                    error: event.statusText,
                                                }
                                            );
                                        }
                                    }
                                }
                            } else {
                                const fileIndex = {
                                    contentType: action.file.type,
                                    name: action.file.name,
                                    sizeInBytes: action.file.size,
                                };
                                return activitiesActions.uploadAttachmentComplete(
                                    {
                                        activityId: action.activityId,
                                        fileIndex: fileIndex,
                                    }
                                );
                            }
                        }),
                        catchError((error) =>
                            of(
                                activitiesActions.uploadAttachmentFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getVisibilityForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activitiesActions.getVisibilityForId),
            mergeMap((action) =>
                this.activitiesService.getVisibilityForId(action.id).pipe(
                    map((visibility) =>
                        activitiesActions.getVisibilityForIdSuccess({
                            id: action.id,
                            visibility,
                        })
                    ),
                    catchError((error) =>
                        of(
                            activitiesActions.getVisibilityForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
