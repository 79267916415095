import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInputLookupModule } from '../../../components/molecules/molecule-input-lookup/molecule-input-lookup.module';
import { MoleculeActionButtonModule } from '../../molecules/molecule-action-button/molecule-action-button.module';
import { OrganismCardsListModule } from '../organism-cards-list/organism-cards-list.module';
import { OrganismCasesListComponent } from './organism-cases-list.component';
import { FunctionPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        MoleculeInputLookupModule,
        OrganismCardsListModule,
        FunctionPipe,
    ],
    declarations: [OrganismCasesListComponent],
    exports: [OrganismCasesListComponent],
})
export class OrganismCasesListModule {}
