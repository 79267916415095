<organism-modal [modalTitle]="!formTitle ? 'Loading...' : formTitle">
    <atom-async-data [isLoading]="!formTitle" [throbberContainerStyle]="false">
        <wdx-ff-form-container
            [formId]="formId"
            [entityId]="entityId"
            [isQuickCreate]="true"
            [initialisationParams]="initialisationParams"
            [modalInstanceId]="modalInstanceId"
            (formTitleReceived)="onFormTitleReceived($event)"
        ></wdx-ff-form-container>
    </atom-async-data>
</organism-modal>
