import { View } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';

export interface SpokeButton {
    label: string;
    position?: SpokeButtonPosition;
    isHidden?: boolean;
    modalId?: string;
    routerLink?: string[];
    queryParams?: KeyValueObject;
    activeView?: View;
}

export enum SpokeButtonPosition {
    Bottom = 'bottom',
    Header = 'header',
    PerGroup = 'per group',
    None = 'none',
}
