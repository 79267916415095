import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ExtendedFieldDefinitionOption } from '../../../../../../models/extended-field-definition-option.model';
import { FilterFieldDefinition, PossibleValue } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { FilterDataItem } from '../../interfaces';
import { FilterDataProviderService } from '../../services/filter-data/filter-data-provider.service';

@Component({
    selector: 'clmi-filter-select',
    templateUrl: './filter-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSelectComponent implements OnInit {
    @Input() definition: FilterFieldDefinition;
    @Input() formControlNameOrIndex: string | number;
    @Input() multiple = false;
    @Input() options: PossibleValue[];
    options$: Observable<FilterDataItem[] | ExtendedFieldDefinitionOption[]>;

    constructor(
        private readonly filterDataProviderService: FilterDataProviderService
    ) {}

    ngOnInit(): void {
        this.options$ = this.filterDataProviderService.getData$(
            this.definition,
            this.options
        );
    }
}
