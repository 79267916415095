<atom-modal-wrapper
    [modalId]="IMPORT_FORM_MODAL"
    [modalTemplate]="importFormModal"
>
    <ng-template #importFormModal>
        <organism-modal modalTitle="File Import" (modalClosed)="onClose()">
            <section
                class="form-action-buttons px-4 py-2 active-component d-flex align-items-center justify-content-end"
            >
                <button
                    type="button"
                    class="btn btn-outline-primary me-2"
                    (click)="onClose()"
                >
                    Cancel
                </button>

                <atom-async-button
                    btnClass="btn btn-primary"
                    [isLoading]="isLoadingData"
                    [disabled]="isLoadingData || !files?.length"
                    (onClick)="onClick()"
                >
                    Save
                </atom-async-button>
            </section>

            <section class="form-container">
                <wdx-form-field
                    label="File uploader"
                    [guidanceText]="
                        'Please ensure you are using a compatible file type and format. For best results, directly populate the relevant template, which can be downloaded by selecting the `Download template` option accessible via Contacts `Lead` search'
                    "
                    [isRequired]="true"
                >
                    <ngx-dropzone
                        #drop
                        class="d-flex flex-column justify-content-center"
                        [accept]="'text/csv,xls'"
                        [multiple]="false"
                        [disableClick]="true"
                        (change)="onSelect($event)"
                    >
                        <ng-container *ngIf="!files?.length">
                            <div class="text-center body-text-medium">
                                <div>Drag and drop your file here</div>
                                <div>or</div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <atom-icon-button
                                    class="mx-1"
                                    icon="file-upload"
                                    (click)="drop.showFileSelector()"
                                ></atom-icon-button>
                            </div>
                        </ng-container>

                        <ngx-dropzone-preview
                            *ngFor="let f of files"
                            [removable]="true"
                            (removed)="onRemove(f)"
                        >
                            <ngx-dropzone-label>
                                {{ f.name }} ({{ f.type }})
                            </ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                </wdx-form-field>
            </section>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
