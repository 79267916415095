import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { BaseInputClass } from '../../../classes/base-input-class';
import { afterLifecyleEvents } from '../../../shared/helpers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-search',
    templateUrl: './molecule-input-search.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'input-search' },
})
export class MoleculeInputSearchComponent
    extends BaseInputClass
    implements AfterViewInit
{
    @ViewChild('inputSearch') inputSearch: ElementRef;

    @Input() placeholder: string;
    @Input() autoFocus: boolean;
    @Input() cySelector: string;

    constructor(elementRef: ElementRef) {
        super();

        this.patchInjectedItems({ elementRef });

        this.useDebouncer = true;
    }

    ngAfterViewInit(): void {
        afterLifecyleEvents(() => {
            if (this.autoFocus) {
                this.inputSearch.nativeElement.focus();
            }
        });
    }
}
