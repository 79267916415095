<div
    *ngIf="opportunity"
    class="card h-100"
    [attr.data-cy]="'opportunity-card-' + opportunity?.id"
    [class.card-clickable]="
        (opportunity | fn : getRouterLink : entityRouteService : !fullCardLink)
            ?.length
    "
    [routerLink]="
        opportunity | fn : getRouterLink : entityRouteService : !fullCardLink
    "
>
    <div class="card-body d-flex">
        <div class="d-flex flex-column flex-grow-1 text-truncate">
            <div class="small">{{ opportunity?.internalIdentifier }}</div>
            <molecule-info-card
                class="text-truncate"
                [cySelector]="'action-' + opportunity?.name"
                [primaryInfo]="opportunity?.name"
                [tertiaryInfo]="[
                    opportunity?.product?.name,
                    opportunity?.stageName
                ]"
                [routerLinkData]="
                    opportunity | fn : getRouterLink : entityRouteService
                "
            ></molecule-info-card>
            <div class="mt-1">
                <a
                    *ngIf="opportunity?.organisation"
                    class="small"
                    [routerLink]="
                        SYSTEM_ENTITY.Organisation
                            | entityRoute : opportunity?.organisation?.id
                    "
                    >{{ opportunity?.organisation?.name }}</a
                >
                <a
                    *ngIf="
                        !opportunity?.organisation &&
                        opportunity?.primaryContact
                    "
                    class="small"
                    [routerLink]="
                        SYSTEM_ENTITY.Party
                            | entityRoute : opportunity?.primaryContact?.id
                    "
                    >{{ opportunity?.primaryContact?.name }}</a
                >
            </div>
        </div>

        <ng-container *ngIf="canLink$ | ngrxPush">
            <molecule-action-button
                *ngIf="opportunity.isSuggestedCardsList"
                class="flex-shrink-0"
                [actionButton]="linkActionButton"
                [cySelector]="'link-opp-' + opportunity?.id"
                (onClick)="onCreateLinkToOpportunityForEntity(opportunity)"
                (click)="$event.stopPropagation()"
            ></molecule-action-button>

            <molecule-action-button
                *ngIf="opportunity.showDeleteLinkMenuItem"
                class="flex-shrink-0"
                [actionButton]="unLinkActionButton"
                [cySelector]="'unlink-opp-' + opportunity?.id"
                (onClick)="onDeleteRelatedRecordClicked(opportunity)"
                (click)="$event.stopPropagation()"
            ></molecule-action-button>
        </ng-container>

        <molecule-opportunity-context-menu
            *ngIf="
                !opportunity.isSuggestedCardsList &&
                !opportunity.showDeleteLinkMenuItem
            "
            [opportunity]="opportunity"
        ></molecule-opportunity-context-menu>
    </div>
    <div class="stages-graphic">
        <ng-container
            *ngFor="let i of opportunity.stageIndex.max | fn : getStageArray"
        >
            <ng-container
                *ngTemplateOutlet="
                    stageMarker;
                    context: {
                        i: i,
                        complete: i < opportunity.stageIndex.index,
                        active: i === opportunity.stageIndex.index,
                        severity: opportunity.stageSeverity
                    }
                "
            ></ng-container>
        </ng-container>
    </div>
    <div class="card-body small text-muted">
        Updated {{ opportunity.lastUpdated | displayDate : { relative: true } }}
    </div>
    <molecule-card-footer [priority]="opportunity.priority">
        <div class="card-footer-left fw-bold">
            {{
                opportunity.estimatedValue?.value
                    | abbreviateCurrency : opportunity.estimatedValue?.isoCode
            }}
        </div>
        <div class="card-footer-center d-flex align-items-center">
            <wdx-icon
                *ngIf="opportunity.estimatedCloseDate"
                class="me-1 d-flex d-flex justify-content-center align-items-center icon-container-size-sm"
                icon="calendar-alt"
            >
            </wdx-icon>
            <span class="text-muted">{{
                opportunity.estimatedCloseDate | displayDate
            }}</span>
        </div>
    </molecule-card-footer>
</div>

<ng-template
    #stageMarker
    let-i="i"
    let-complete="complete"
    let-active="active"
    let-severity="severity"
>
    <div
        class="stage-marker stage-marker--{{ i }} stage-marker--{{ severity }}"
        [class.complete]="complete"
        [class.active]="active"
    >
        <div class="stage-marker__active-center" [class.d-none]="!active"></div>
    </div>
</ng-template>
