import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Salutation } from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from './../../state/_setup/reducers';
import * as salutationActions from './salutation.actions';
import * as salutationSelectors from './salutation.selectors';
import { SalutationService } from './salutation.service';

@Injectable()
export class SalutationEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private salutationService = inject(SalutationService);

    getSalutation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(salutationActions.getSalutation),
            withLatestFrom(
                this.store$.select(
                    salutationSelectors.getSalutationInfinityPaging({
                        id: GLOBAL_STATE_INDEX_ID,
                    })
                )
            ),
            switchMap(([action, paging]) =>
                this.salutationService
                    .getSalutation({
                        pageNumber: action.reset ? 1 : (paging?.page || 0) + 1,
                    })
                    .pipe(
                        map((salutation) => {
                            return salutationActions.getSalutationSuccess({
                                salutation,
                            });
                        }),
                        catchError((error) =>
                            of(
                                salutationActions.getSalutationFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateSalutation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(salutationActions.updateSalutation),
            switchMap((action) =>
                this.salutationService
                    .updateSalutation(action.paginationOptions)
                    .pipe(
                        map((payload) =>
                            salutationActions.updateSalutationSuccess({
                                payload,
                            })
                        ),
                        catchError((error) =>
                            of(
                                salutationActions.updateSalutationFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    querySalutation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(salutationActions.querySalutation),
            switchMap((action) =>
                this.salutationService.querySalutation(action.query).pipe(
                    map((salutation) =>
                        salutationActions.querySalutationSuccess({
                            salutation,
                        })
                    ),
                    catchError((error) =>
                        of(
                            salutationActions.querySalutationFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    disableSalutation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(salutationActions.disableSalutation),
            switchMap((action) =>
                this.salutationService
                    .disableSalutation(action.id, action.isDisabled)
                    .pipe(
                        map((updatedSalutation: Salutation) =>
                            salutationActions.disableSalutationSuccess({
                                id: action.id,
                                isDisabled: action.isDisabled,
                                updatedSalutation,
                            })
                        ),
                        catchError(() =>
                            of(
                                salutationActions.disableSalutationFailure({
                                    id: action.id,
                                })
                            )
                        )
                    )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(salutationActions.deleteSalutation),
            switchMap((action) =>
                this.salutationService.deleteSalutation(action.id).pipe(
                    map(() =>
                        salutationActions.deleteSalutationSuccess({
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            salutationActions.deleteSalutationFailure({
                                id: action.id,
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );
}
