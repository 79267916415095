import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    ROUTE_PREFIX,
    Severity,
    SignalrEvent,
    URL_DATA_EXPORT,
} from '@wdx/shared/utils';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as exportsActions from './exports.actions';
import { ExportsService } from './exports.service';

@Injectable()
export class ExportsEffects {
    private actions$ = inject(Actions);
    private exportsService = inject(ExportsService);
    private toastService = inject(WdxToastService);

    doExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(exportsActions.doExport),
            mergeMap((action) =>
                this.exportsService
                    .doExport({
                        queryType: action.queryType,
                        payload: action.payload,
                        entityId: action.entityId,
                    })
                    .pipe(
                        map(() => {
                            return exportsActions.doExportSuccess({
                                queryType: action.queryType,
                            });
                        }),
                        catchError((error) => {
                            return of(
                                exportsActions.doExportFailure({
                                    error,
                                })
                            );
                        })
                    )
            )
        )
    );

    doExportSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(exportsActions.doExportSuccess),
                tap(() => {
                    this.toastService.show({
                        body: [`Export background job successfully generated`],
                        severity: Severity.Success,
                        closeOthers: true,
                        bodyRouterLink: [...ROUTE_PREFIX, ...URL_DATA_EXPORT],
                    });
                })
            ),
        { dispatch: false }
    );

    listenToEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub
                        .on(SignalrEvent.ExportGenerated)
                        .pipe(map(() => exportsActions.refreshExports()))
                )
            )
        )
    );
}
