import { FormDefinition, FormSectionLayoutDefinition } from '@wdx/shared/utils';

export const formDefinitionToLayoutAndDefinition = (
    definition: FormDefinition
) => ({
    definition: definition,
    layoutAndDefinitions: definition?.layout?.sectionLayoutDefinitions?.map(
        (sectionLayoutDefinition) => {
            return {
                name: sectionLayoutDefinition.name,
                label: sectionLayoutDefinition.label,
                elementLayoutDefinitions:
                    sectionLayoutDefinition.elementLayoutDefinitions?.map(
                        (elementLayoutDefinition) => {
                            const schema = definition.schema?.find(
                                (definitionSchema) =>
                                    definitionSchema.name ===
                                    elementLayoutDefinition.name
                            );
                            const elementLayoutWithSchema = {
                                ...elementLayoutDefinition,
                                sectionLayoutDefinitions:
                                    elementLayoutDefinition.sectionLayoutDefinitions?.map(
                                        (sectionLayoutDefinition) => {
                                            const defsWithChildSchema =
                                                sectionLayoutDefinition.elementLayoutDefinitions?.map(
                                                    (def) => {
                                                        const childSchema =
                                                            schema?.childSchema?.find(
                                                                (
                                                                    definitionChildSchema
                                                                ) =>
                                                                    definitionChildSchema.name ===
                                                                    def.name
                                                            );
                                                        return {
                                                            ...childSchema,
                                                            ...def,
                                                        };
                                                    }
                                                );
                                            return {
                                                ...sectionLayoutDefinition,
                                                elementLayoutDefinitions:
                                                    defsWithChildSchema,
                                            };
                                        }
                                    ),
                            };
                            return {
                                ...schema,
                                ...elementLayoutWithSchema,
                            };
                        }
                    ),
            };
        }
    ) as FormSectionLayoutDefinition[],
});
