import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as casesSelectors from './cases.selectors';
import * as casesActions from './cases.actions';
import { tap, map, filter } from 'rxjs';
import { CrudStatus } from '@wdx/shared/utils';

@Injectable({ providedIn: 'root' })
export class CasesStoreFacade {
    constructor(private store$: Store) {}

    public kycHeatmap$(id: string) {
        return this.store$.select(casesSelectors.getKycHeatmap(id)).pipe(
            tap((hm) => {
                if (
                    hm?.status === undefined ||
                    hm.status < CrudStatus.Loading
                ) {
                    this.getKycHeatmap(id);
                }
            }),
            filter((hm) => Boolean(hm?.record)),
            map((hm) => hm?.record)
        );
    }

    public getKycHeatmap(id: string) {
        this.store$.dispatch(casesActions.getKycHeatmap({ id }));
    }
}
