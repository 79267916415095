import { Pipe, PipeTransform } from '@angular/core';
import { EntityAction, HeatMap } from '@wdx/clmi/api-models';
import { FeaturesService, NavItem } from '@wdx/shared/utils';
import { ICON_CONTEXT_MENU } from '../constants/icons.constants';

@Pipe({ name: 'heatMapToMenuItems' })
export class HeatMapToMenuItemsPipe implements PipeTransform {
    constructor(private featuresService: FeaturesService) {}

    transform(
        heatMap: HeatMap,
        staticItems?: NavItem[],
        staticMoreActionItems?: NavItem[],
        actions?: EntityAction[]
    ): NavItem[] {
        if (!heatMap) {
            return [];
        }

        const actionsItems: NavItem[] =
            actions?.length &&
            actions.map((action) => ({
                label: action.displayName.value,
                translationKey: action.displayName.key,
                icon: action.icon,
                data: {
                    url: action.url,
                    type: action.type
                },
            }));

        const moreActionsItem: NavItem = (staticMoreActionItems?.length ||
            actionsItems?.length) && {
            label: 'More Actions',
            value: 'moreActions',
            cySelector: 'menu-item-More Actions',
            icon: ICON_CONTEXT_MENU.icon,
            linkedMenu: [
                ...(staticMoreActionItems || []),
                ...(actionsItems || []),
            ],
        };

        return [
            ...(staticItems || []),
            ...(moreActionsItem ? [moreActionsItem] : []),
        ];
    }
}
