import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MoleculeSortMenuModule } from '../../../components/molecules/molecule-sort-menu/molecule-sort-menu.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomDropdownButtonModule } from '../../atoms/atom-dropdown-button/atom-dropdown-button.module';
import { AtomFilterButtonModule } from '../../atoms/atom-filter-button/atom-filter-button.module';
import { OrganismModalModule } from '../../organisms/organism-modal/organism-modal.module';
import { MoleculeContextMenuDropdownModule } from '../molecule-context-menu-dropdown/molecule-context-menu-dropdown.module';
import { MoleculeContextMenuModule } from '../molecule-context-menu/molecule-context-menu.module';
import { MoleculeFilterBarComponent } from './molecule-filter-bar.component';
import { WdxToggleButtonComponent } from '@wdx/shared/components/wdx-toggle-button';

@NgModule({
    imports: [
        AtomDropdownButtonModule,
        AtomFilterButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeContextMenuDropdownModule,
        MoleculeContextMenuModule,
        MoleculeSortMenuModule,
        OrganismModalModule,
        PipesModule,
        RouterModule,
        WdxToggleButtonComponent,
    ],
    declarations: [MoleculeFilterBarComponent],
    exports: [MoleculeFilterBarComponent],
})
export class MoleculeFilterBarModule {}
