import { Component, Input } from '@angular/core';
import { DIALOG_TITLE_MAP } from '../../../constants/dialog.constants';
import { DialogMode, DialogOptionsData } from '../../../models/dialog.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-dialog',
    templateUrl: './molecule-dialog.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: '' },
})
export class MoleculeDialogComponent {
    constructor(private activeModal: NgbActiveModal) {}

    readonly DIALOG_MODE = DialogMode;

    @Input() dialogMode: DialogMode;
    @Input() data: DialogOptionsData;

    getTitle(dialogMode: DialogMode): string {
        return DIALOG_TITLE_MAP[dialogMode];
    }

    dismiss(): void {
        this.activeModal.dismiss();
    }

    confirm(): void {
        this.activeModal.close();
    }
}
