import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Query } from '@angular/core';
import { Salutation, StatusType } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class SalutationService {
    defaultPageSize = 50;

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getSalutation(
        paginationOptions?: PaginationOptions
    ): Observable<PaginatedApiResponse<Salutation>> {
        const headers = new HttpHeaders(
            this.apiPaginationService.mapPaginationOptions({
                ...paginationOptions,
                pageSize: paginationOptions?.pageSize || this.defaultPageSize,
            })
        );
        return this.http.get<PaginatedApiResponse<Salutation>>(
            `${this.config.getConfiguration().API_BASE}/salutation`,
            { headers }
        );
    }

    updateSalutation(
        paginationOptions: PaginationOptions
    ): Observable<PaginatedApiResponse<Salutation>> {
        return this.getSalutation(paginationOptions);
    }

    querySalutation(
        query: Query
    ): Observable<PaginatedApiResponse<Salutation>> {
        const headers = new HttpHeaders(
            this.apiPaginationService.mapPaginationOptions({ pageSize: 200 })
        );
        return this.http.post<PaginatedApiResponse<Salutation>>(
            `${this.config.getConfiguration().API_BASE}/query/salutation`,
            query,
            { headers }
        );
    }

    disableSalutation(id: string, isDisabled: boolean): Observable<Salutation> {
        const status: StatusType = isDisabled
            ? StatusType.Inactive
            : StatusType.Active;

        return this.http.patch<Salutation>(
            `${
                this.config.getConfiguration().API_BASE
            }/salutation/${id}/status/${status}`,
            {}
        );
    }

    deleteSalutation(id: string): Observable<Salutation> {
        return this.http.delete<Salutation>(
            `${this.config.getConfiguration().API_BASE}/Salutation/${id}`
        );
    }
}
