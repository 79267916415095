import { SortDirection } from '@wdx/clmi/api-models';
import { NavItem } from '@wdx/shared/utils';
import { SortMenuSortBy, SortMenuValue } from '../models/sort-menu.model';

export enum FeedSortByType {
    Value = 'Value',
    Urgency = 'Urgency',
    ManageClient = 'ManageClient',
    StayConnected = 'StayConnected',
    StayCompliant = 'StayCompliant',
    GrowBusiness = 'GrowBusiness',
}

export const SORT_MENU_ARTICLES: NavItem<SortMenuValue>[] = [
    {
        label: 'Alphabetical',
        value: { sortBy: SortMenuSortBy.Name },
    },
    {
        label: 'Date Published',
        value: { sortBy: SortMenuSortBy.PublishedOn },
    },
];

export const SORT_MENU_CASES: NavItem<SortMenuValue>[] = [
    {
        label: 'Created on',
        cySelector: 'span-created-on',
        value: {
            sortBy: SortMenuSortBy.CreatedOn,
        },
    },

    {
        label: 'Due',
        cySelector: 'span-date-due',
        value: {
            sortBy: SortMenuSortBy.DueDate,
        },
    },
    {
        label: 'Next Action Due',
        cySelector: 'span-next-action',
        value: {
            sortBy: SortMenuSortBy.NextActionDue,
        },
    },
];

export const SORT_MENU_DOCUMENTS: NavItem[] = [
    {
        value: 'DocumentListSortByType.Uploaded',
        label: 'Recently Uploaded',
    },
    {
        value: 'DocumentListSortByType.Alphabetical',
        label: 'Alphabetical (A-Z)',
    },
    {
        value: 'DocumentListSortByType.AlphabeticalDesc',
        label: 'Alphabetical (Z-A)',
    },
];

export const SORT_MENU_FEED: NavItem<FeedSortByType>[] = [
    {
        label: 'Client value',
        value: FeedSortByType.Value,
    },
    {
        label: 'Urgency',
        value: FeedSortByType.Urgency,
    },
];

export const SORT_MENU_NOTES: NavItem[] = [
    {
        value: 'dateCreated',
        label: 'Recently Added',
    },
];

export const SORT_MENU_OPPORTUNITIES: NavItem<SortMenuValue>[] = [
    {
        label: 'Estimated Close',
        value: {
            sortBy: SortMenuSortBy.EstimatedCloseDate,
            sortDirection: SortDirection.Descending,
        },
    },
    {
        label: 'Value',
        value: {
            sortBy: SortMenuSortBy.Pipeline,
            sortDirection: SortDirection.Descending,
        },
    },
    {
        label: 'Name',
        value: {
            sortBy: SortMenuSortBy.Name,
            sortDirection: SortDirection.Ascending,
        },
    },
];
