<ng-container *ngIf="form">
    <div
        *ngIf="
            !readonlyMode && formStylingOptions?.actionButtonPosition === 'top'
        "
        class="form-action-buttons px-4 py-2 active-component d-flex align-items-center"
        [class.justify-content-center]="formStylingOptions?.hideStatus"
        data-cy="form-framework-action-button-banner-top"
    >
        <ng-container *ngTemplateOutlet="actionButtonBanner"></ng-container>
    </div>

    <div
        class="form-container flex-grow-1 overflow-hidden"
        [wdxIsLoading]="!!isSaving"
        [loadingOverlay]="true"
    >
        <h6 *ngIf="hasError">Sorry, something went wrong.</h6>
        <div class="h-100 d-flex flex-column overflow-hidden">
            <div *ngIf="form?.invalid && $any(validationSummary?.length) > 0">
                <wdx-ff-validation-summary
                    *ngIf="submitAttempted"
                    class="d-block mb-2"
                    data-cy="lbl-message"
                    [message]="validationMessage"
                    [validationSummary]="validationSummary"
                    (errorClicked)="onErrorClicked($event)"
                >
                    <button
                        *ngIf="saveDraftMode"
                        [wdxButton]="WdxButtonStyle.OutlinePrimary"
                        class="text-nowrap ms-2"
                        data-cy="btn-save-draft"
                        (click)="onDraftSaveClick()"
                    >
                        Save as Draft
                    </button></wdx-ff-validation-summary
                >
            </div>

            <wdx-ff-pending-changes
                *ngIf="formData?.pendingChanges?.length"
                data-cy="pending-changes"
                [pendingChanges]="$any(formPendingChangesService?.coc)"
                [pendingChangesSubForm]="
                    $any(
                        formPendingChangesService?.pendingChangesSubFormCounter
                    )
                "
            ></wdx-ff-pending-changes>

            <wdx-alert
                *ngIf="messageDefinitionLength"
                class="remove-margin-bottom"
                themeColor="warning"
            >
                <wdx-icon
                    class="pe-3 align-self-start"
                    icon="info-circle"
                ></wdx-icon>
                <span
                    *ngFor="
                        let messageDefinition of messageDefinition | keyvalue;
                        trackBy: trackByMessage
                    "
                    [innerHTML]="
                        $any(messageDefinition?.value)?.label
                            | markdownToHtml
                            | safeHtml
                    "
                ></span>
            </wdx-alert>

            <wdx-ff-version-info *ngIf="showFormSwitcherPanel">
                <ng-container
                    *ngTemplateOutlet="formVersionInfo"
                ></ng-container>
            </wdx-ff-version-info>

            <div *ngIf="formData?.lock">
                <wdx-ff-lock-summary
                    [formData]="formData"
                ></wdx-ff-lock-summary>
            </div>

            <ng-container
                *ngIf="summaryInfoCards$ | ngrxPush; let summaryInfoCards"
            >
                <div *ngIf="summaryInfoCards.length" class="border-bottom">
                    <wdx-ff-summary-info-cards
                        [infoCards]="summaryInfoCards"
                    ></wdx-ff-summary-info-cards>
                </div>
            </ng-container>

            <form
                class="form flex-grow-1 overflow-hidden"
                autocomplete="off"
                [formGroup]="form"
                data-cy="ff-form"
            >
                <div class="d-block h-100 overflow-auto scroll-smooth">
                    <ngb-accordion
                        #accordion
                        class="accordion form-accordion accordion-flush"
                        [activeIds]="activePanelIds"
                        [destroyOnHide]="false"
                        [closeOthers]="false"
                    >
                        <ng-container
                            *ngFor="
                                let formSectionLayoutDefinition of condService.layoutAndDefinition;
                                let i = index;
                                let first = first;
                                trackBy: trackSection
                            "
                        >
                            <ng-container
                                *ngIf="
                                    !formSectionLayoutDefinition?.section
                                        ?.isHidden
                                "
                            >
                                <ngb-panel
                                    id="reactive-form-{{ i }}"
                                    [cardClass]="
                                        'form-section border-bottom-0' +
                                        (formStylingOptions?.background ===
                                        'muted'
                                            ? ' bg-muted'
                                            : '') +
                                        (first ? '' : ' mt-1') +
                                        (condService.layoutAndDefinition
                                            .length > 1
                                            ? ''
                                            : ' no-panel-toggle')
                                    "
                                >
                                    <ng-template
                                        ngbPanelHeader
                                        let-opened="opened"
                                    >
                                        <button
                                            *ngIf="
                                                condService.layoutAndDefinition
                                                    .length > 1
                                            "
                                            ngbPanelToggle
                                            class="form-section__header accordion-header accordion-button d-flex align-items-center bg-muted"
                                            [attr.data-cy]="
                                                'panel-toggle-' +
                                                formSectionLayoutDefinition
                                                    .section?.name
                                            "
                                        >
                                            <div
                                                class="d-flex align-items-center flex-grow-1"
                                            >
                                                <h6>
                                                    {{
                                                        formSectionLayoutDefinition
                                                            .section?.label
                                                    }}
                                                </h6>

                                                <wdx-icon
                                                    *ngIf="
                                                        submitAttempted &&
                                                        sectionsInError?.includes(
                                                            $any(
                                                                formSectionLayoutDefinition
                                                                    .section
                                                                    ?.name
                                                            )
                                                        )
                                                    "
                                                    class="text-danger ms-2"
                                                    icon="exclamation-triangle"
                                                >
                                                </wdx-icon>
                                            </div>
                                        </button>
                                    </ng-template>

                                    <ng-template ngbPanelContent>
                                        <div class="form-section__body">
                                            <h6
                                                *ngIf="
                                                    formSectionLayoutDefinition
                                                        .section?.guidanceText
                                                "
                                                class="form-section__body__blurb small"
                                            >
                                                {{
                                                    formSectionLayoutDefinition
                                                        .section?.guidanceText
                                                }}
                                            </h6>

                                            <div
                                                class="form-section__body__fields form__fields grid"
                                            >
                                                <ng-container
                                                    *ngFor="
                                                        let elementDefinition of formSectionLayoutDefinition.layoutAndDefinition;
                                                        let iLayout = index;
                                                        trackBy: trackElement
                                                    "
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            !elementDefinition.isHidden &&
                                                            (elementDefinition.elementType ||
                                                                elementDefinition.fieldType)
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                elementDefinition.forcesNewRow &&
                                                                !elementDefinition.forcesFullWidth
                                                            "
                                                            class="g-col-12"
                                                        ></div>

                                                        <div
                                                            class="g-col-12 input form-input"
                                                            [class.g-col-lg-6]="
                                                                !elementDefinition.forcesFullWidth &&
                                                                elementDefinition.fieldType !==
                                                                    FORM_FIELD_TYPE.Address &&
                                                                (!elementDefinition.forcesNewRow ||
                                                                    !elementDefinition.forcesFullWidth)
                                                            "
                                                            [attr.data-cy]="
                                                                'lbl-' +
                                                                elementDefinition.name
                                                            "
                                                            [attr.data-form-control]="
                                                                elementDefinition.name
                                                            "
                                                        >
                                                            <ng-container
                                                                *ngIf="
                                                                    ARRAY_CONTROL_TYPES.includes(
                                                                        $any(
                                                                            elementDefinition?.fieldType
                                                                        )
                                                                    );
                                                                    else standardControls
                                                                "
                                                            >
                                                                <h6
                                                                    *ngIf="
                                                                        elementDefinition
                                                                            ?.children?.[0]
                                                                            ?.section
                                                                            ?.guidanceText
                                                                    "
                                                                    class="form-section__body__blurb small"
                                                                >
                                                                    {{
                                                                        elementDefinition
                                                                            ?.children?.[0]
                                                                            ?.section
                                                                            ?.guidanceText
                                                                    }}
                                                                </h6>
                                                                <wdx-ff-form-array
                                                                    [entityId]="
                                                                        entityId
                                                                    "
                                                                    [formArrayName]="
                                                                        $any(
                                                                            elementDefinition.name
                                                                        )
                                                                    "
                                                                    [elementDefinition]="
                                                                        elementDefinition
                                                                    "
                                                                    [fbService]="
                                                                        fbService
                                                                    "
                                                                    [formContext]="
                                                                        formData?.context
                                                                    "
                                                                    [parentData]="
                                                                        formData.data
                                                                    "
                                                                ></wdx-ff-form-array>
                                                            </ng-container>

                                                            <ng-template
                                                                #standardControls
                                                            >
                                                                <wdx-ff-controls-container
                                                                    [entityId]="
                                                                        entityId
                                                                    "
                                                                    [formElement]="
                                                                        elementDefinition
                                                                    "
                                                                    [formContext]="
                                                                        formData?.context
                                                                    "
                                                                ></wdx-ff-controls-container>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ng-container>
                        </ng-container>
                    </ngb-accordion>
                    <div
                        *ngIf="
                            !readonlyMode &&
                            formStylingOptions?.actionButtonPosition ===
                                'bottom'
                        "
                        class="form-action-buttons px-4 py-2 d-flex align-items-center"
                        [class.justify-content-center]="
                            formStylingOptions?.hideStatus
                        "
                        data-cy="form-framework-action-button-banner-bottom"
                    >
                        <ng-container
                            *ngTemplateOutlet="actionButtonBanner"
                        ></ng-container>
                    </div>
                </div>
            </form>

            <ng-template #formVersionInfo>
                <div>
                    You are viewing a
                    <span class="fw-bold">{{ formData.status }}</span>
                    version of this form (by
                    <button
                        [wdxButton]="WdxButtonStyle.Link"
                        (click)="
                            openLinkInNewTab(
                                $any(
                                    formData?.context?.lastUpdatedBy?.partyType
                                ),
                                $any(formData?.context?.lastUpdatedBy?.id)
                            )
                        "
                    >
                        {{ formData?.lastUpdatedBy || 'unknown user' }}</button
                    >,
                    {{
                        $any(formData?.lastUpdatedOn)
                            | displayDate : { relative: true }
                    }}) -
                    <button
                        [wdxButton]="WdxButtonStyle.Link"
                        (click)="switchFormVersion()"
                    >
                        Click here to view the
                        {{
                            formData.status === FORM_DATA_STATUS.Draft
                                ? 'previously saved'
                                : 'draft'
                        }}
                        version
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #actionButtonBanner>
    <div
        *ngIf="!formStylingOptions?.hideStatus && entityId && appId"
        class="fw-bold me-2"
        data-cy="form-completion-info"
    >
        <ng-container
            *ngIf="completionSummary?.expected; else noRequiredFields"
        >
            {{ completionSummary?.completed }} of
            {{ completionSummary?.expected }} fields completed
        </ng-container>

        <ng-template #noRequiredFields>No required fields</ng-template>
    </div>
    <div
        class="d-flex align-items-center"
        [class.ms-auto]="!formStylingOptions?.hideStatus"
    >
        <wdx-badge
            *ngIf="
                !formStylingOptions?.hideStatus &&
                saveDraftMode &&
                (isDraftForm || isPublishedForm)
            "
            class="me-2"
            [themeColor]="
                $any((isDraftForm && 'info') || (isPublishedForm && 'success'))
            "
            data-cy="form-status"
        >
            {{ formData?.status }}
        </wdx-badge>
        <button
            [wdxButton]="WdxButtonStyle.OutlinePrimary"
            data-cy="btn-cancel"
            (click)="onCancelClick()"
        >
            Cancel
        </button>

        <button
            *ngIf="saveCompleteMode"
            [wdxButton]="WdxButtonStyle.Primary"
            class="ms-2"
            data-cy="btn-save-complete"
            [disabled]="isSaving || fullFormLock"
            (click)="onSaveClick('complete=true')"
        >
            <wdx-spinner *ngIf="isSaving" [small]="true"></wdx-spinner>
            Save and Complete
        </button>

        <button
            *ngIf="saveMode && !saveDraftMode"
            [wdxButton]="WdxButtonStyle.Primary"
            class="ms-2"
            data-cy="btn-save"
            [disabled]="isSaving || fullFormLock"
            (click)="onSaveClick()"
        >
            <wdx-spinner *ngIf="isSaving" [small]="true"></wdx-spinner>
            Save
        </button>

        <wdx-split-button
            *ngIf="saveMode && saveDraftMode"
            class="ms-2"
            [isLoading]="isSaving"
            [disabled]="fullFormLock"
            [menuItems]="$any(splitButtonMenuItems)"
            (menuItemClicked)="onSplitMenuItemClicked($event)"
            data-cy="form-split-action-button"
        >
            <button
                splitbutton
                [wdxButton]="WdxButtonStyle.Primary"
                data-cy="btn-save"
                [disabled]="isSaving || fullFormLock"
                (click)="onSaveClick()"
            >
                <wdx-spinner *ngIf="isSaving" [small]="true"></wdx-spinner>
                Save
            </button>
        </wdx-split-button>
    </div>
</ng-template>
