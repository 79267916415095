import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccessVisibility,
    HistoryEntry,
    Case,
    CaseCycle,
    CaseTeamMember,
    SystemEntity,
    WorkItemCategorySummary,
    HeatMap,
} from '@wdx/clmi/api-models';
import { CaseWork } from '../../models/case-work.model';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class CasesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getCases(entityType: SystemEntity, entityId: string): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/case`
        );
    }

    getCase(caseId: string): Observable<Case> {
        return this.http.get<Case>(
            `${this.config.getConfiguration().API_BASE}/case/${caseId}`
        );
    }

    getCaseWork(caseId: string): Observable<CaseWork[]> {
        return this.http.get<CaseWork[]>(
            `${this.config.getConfiguration().API_BASE}/case/${caseId}/work`
        );
    }

    getCaseHistory(caseId: string): Observable<HistoryEntry[]> {
        return this.http.get<HistoryEntry[]>(
            `${this.config.getConfiguration().API_BASE}/case/${caseId}/history`
        );
    }

    getCasesForClient(clientId: string): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${this.config.getConfiguration().API_BASE}/client/${clientId}/case`
        );
    }

    getFavourites(): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${this.config.getConfiguration().API_BASE}/case/favourite`
        );
    }

    // Team members

    assignTeamMemberToCase(
        caseId: string,
        partyId: string
    ): Observable<CaseTeamMember> {
        return this.http.patch<CaseTeamMember>(
            `${
                this.config.getConfiguration().API_BASE
            }/case/${caseId}/member/${partyId}`,
            {}
        );
    }

    deleteTeamMemberFromCase(
        caseId: string,
        partyId: string
    ): Observable<CaseTeamMember> {
        return this.http.delete<CaseTeamMember>(
            `${
                this.config.getConfiguration().API_BASE
            }/case/${caseId}/member/${partyId}`,
            {}
        );
    }

    getAllMembersForCase(caseId: string): Observable<CaseTeamMember[]> {
        return this.http.get<CaseTeamMember[]>(
            `${this.config.getConfiguration().API_BASE}/case/${caseId}/member`
        );
    }

    completeCase(caseId: string): Observable<Case> {
        return this.http.patch<Case>(
            `${
                this.config.getConfiguration().API_BASE
            }/case/${caseId}/complete`,
            {}
        );
    }

    cancelCase(caseId: string): Observable<Case> {
        return this.http.patch<Case>(
            `${this.config.getConfiguration().API_BASE}/case/${caseId}/cancel`,
            {}
        );
    }

    getCategoriesForCase(
        caseId: string
    ): Observable<WorkItemCategorySummary[]> {
        return this.http.get<WorkItemCategorySummary[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/case/${caseId}/categories`
        );
    }

    getVisibilityForId(id: string): Observable<AccessVisibility[]> {
        return this.http.get<AccessVisibility[]>(
            `${this.config.getConfiguration().API_BASE}/case/${id}/visibility`
        );
    }

    getReviewsForId(id: string): Observable<CaseCycle[]> {
        return this.http.get<CaseCycle[]>(
            `${this.config.getConfiguration().API_BASE}/case/${id}/review`
        );
    }

    getKycHeatmap(id: string): Observable<HeatMap> {
        return this.http.get<HeatMap>(
            `${this.config.getConfiguration().API_BASE}/case/${id}/heatmap/kyc`
        );
    }
}
