import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import {
    EntityPermissionType,
    Opportunity,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import { OPPORTUNITY_FORM_ID } from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ICON_ADD } from '../../../../constants/icons.constants';
import { USER_FEEDBACK_NO_DATA } from '../../../../constants/user-feedback.constants';
import { AccordionPanel } from '../../../../models/accordion-panel.model';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { ScrollMode } from '../../../../models/scroll-mode.model';
import { SystemEntityActionProps } from '../../../../models/system-entity-action-props.model';
import * as assignToActions from '../../../../state/assign/assign.actions';
import * as relatedRecordsActions from '../../../../state/related-records/related-records.actions';
import { RelatedRecordsFacadeService } from '../related-records-facade.service';

@Component({
    selector: 'clmi-related-opportunities',
    templateUrl: './related-opportunities.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedOpportunitiesComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() entityId: string;
    @Input() entityType: SystemEntity;
    @Input() cardsPerRow: number;
    @Input() isInactiveRecord: boolean;

    @ViewChild('suggestedOpportunitiesContentTemplate')
    suggestedOpportunitiesContentTemplate: TemplateRef<any>;

    relatedOpportunities$: Observable<Opportunity[]>;
    relatedOpportunitiesIsLoading$: Observable<boolean>;
    relatedOpportunitiesHasError$: Observable<boolean>;

    suggestedOpportunities$: Observable<Opportunity[]>;
    suggestedOpportunitiesIsLoading$: Observable<boolean>;
    suggestedOpportunitiesHasError$: Observable<boolean>;
    suggestedOpportunitiesAccordionPanels$: Observable<AccordionPanel[]>;

    destroyed$ = new Subject<boolean>();

    createOpportunityActionButton: ActionButton;

    readonly SCROLL_MODE = ScrollMode;
    readonly NO_DATA_MESSAGE = USER_FEEDBACK_NO_DATA;

    constructor(
        private relatedRecordsFacadeService: RelatedRecordsFacadeService,
        private actionsSubject$: ActionsSubject
    ) {}

    ngOnInit(): void {
        this.actionsSubject$
            .pipe(
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe((action: any) => {
                if (action.response?.entityType === SystemEntity.Opportunity) {
                    const props = {
                        entityId: this.entityId,
                        entityType: this.entityType,
                    };

                    this.relatedRecordsFacadeService.loadRelatedOpportunities(
                        props
                    );
                }
            });

        this.actionsSubject$
            .pipe(
                ofType(
                    assignToActions.assignToSuccess,
                    relatedRecordsActions.createLinkToOpportunityForEntitySuccess,
                    relatedRecordsActions.deleteLinkToOpportunityForEntitySuccess
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                const props = {
                    entityId: this.entityId,
                    entityType: this.entityType,
                };

                this.relatedRecordsFacadeService.loadRelatedOpportunities(
                    props
                );

                this.relatedRecordsFacadeService.loadSuggestedOpportunities(
                    props
                );
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const entityId = this.entityId ?? changes.entityId.currentValue;
        const entityType = this.entityType ?? changes.entityType.currentValue;

        if (entityId && entityType) {
            const props = {
                entityId,
                entityType,
            };

            this.createOpportunityActionButton = {
                mode: ActionButtonMode.IconButton,
                cySelector: 'action-create-opportunities',
                icon: ICON_ADD.icon,
                operationsSetup: props,
                ...(!this.isInactiveRecord
                    ? { permission: EntityPermissionType.Edit }
                    : {}),
                formSetup: {
                    formId: OPPORTUNITY_FORM_ID,
                    initialisationParams: { activityId: entityId },
                },
            };

            this.setRelatedOpportunitites(props);

            this.setSuggestedOpportunitites(props);
        }
    }

    setRelatedOpportunitites(props: SystemEntityActionProps): void {
        this.relatedOpportunities$ =
            this.relatedRecordsFacadeService.getRelatedOpportunities$(props);

        this.relatedOpportunitiesIsLoading$ =
            this.relatedRecordsFacadeService.getRelatedOpportunitiesIsLoading$(
                props
            );

        this.relatedOpportunitiesHasError$ =
            this.relatedRecordsFacadeService.getRelatedOpportunitiesHasError$(
                props
            );
    }

    setSuggestedOpportunitites(props: SystemEntityActionProps): void {
        this.suggestedOpportunities$ =
            this.relatedRecordsFacadeService.getSuggestedOpportunities$(props);

        this.suggestedOpportunitiesIsLoading$ =
            this.relatedRecordsFacadeService.getSuggestedOpportunitiesIsLoading$(
                props
            );

        this.suggestedOpportunitiesHasError$ =
            this.relatedRecordsFacadeService.getSuggestedOpportunitiesHasError$(
                props
            );

        this.suggestedOpportunitiesAccordionPanels$ =
            this.suggestedOpportunities$.pipe(
                debounceTime(0),
                map((suggestedOpportunities) => {
                    return [
                        {
                            label: `Suggested Opportunities (${
                                suggestedOpportunities?.length || 0
                            })`,
                            template:
                                this.suggestedOpportunitiesContentTemplate,
                            guidanceText: `Click the 'chain' icon to link this engagement to the suggested case.`,
                        },
                    ];
                })
            );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
