import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IThemeService } from '@wdx/shared/utils';
import { BehaviorSubject, delay, takeUntil, tap } from 'rxjs';
import showdown from 'showdown';
import { RawEditorOptions } from 'tinymce';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';

@Component({
    selector: 'wdx-ff-rich-text-control',
    templateUrl: './form-rich-text-control.component.html',
})
export class FormRichTextControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    editorApiKey = 'hq75q3gj9wdn5of71vw6i0ly4et010pmlekas8oddc053jls';
    editorConfig: RawEditorOptions = {
        menubar: false,
        plugins: 'autolink link lists',
        toolbar: 'bold italic link | bullist numlist',
        branding: false,
        height: 300,
        statusbar: false,
        content_css: 'default',
        font_css:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap',
        browser_spellcheck: true,
        contextmenu: false,
    };
    initialValue$ = new BehaviorSubject<string>('');
    ready$ = new BehaviorSubject<boolean>(false);
    private contentInit = false;

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private currentThemeService: IThemeService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.currentThemeService.currentTheme$
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.editorConfig = {
                        ...this.editorConfig,
                        content_style: `
                        html {
                            font-size: ${this.currentThemeService.getThemeValue(
                                'root-font-size'
                            )};
                        }
                        body {
                            color: ${this.currentThemeService.getThemeValue(
                                'body-color'
                            )};
                            background-color: ${this.currentThemeService.getThemeValue(
                                'body-bg'
                            )};
                            font-family: ${this.currentThemeService.getThemeValue(
                                'body-font-family'
                            )};
                            font-size: ${this.currentThemeService.getThemeValue(
                                'body-font-size'
                            )};
                        }
                    `,
                    };
                }),
                delay(50)
            )
            .subscribe(() => {
                this.initialValue$.next(
                    this.convertIn(this.formControl?.value)
                );
                this.ready$.next(true);
            });
    }

    convertOut(htmlValue: string): string {
        return new showdown.Converter().makeMarkdown(htmlValue || '');
    }

    convertIn(markdownValue: string): string {
        return new showdown.Converter().makeHtml(markdownValue || '');
    }

    onEditorChange(event: any) {
        const content = event.editor.getContent();
        if (this.contentInit) {
            this.formControl?.markAsDirty();
        } else {
            this.contentInit = true;
        }
        this.formControl?.setValue(this.convertOut(content));
    }
}
