import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { MoleculeContextMenuDropdownModule } from '../../../components/molecules/molecule-context-menu-dropdown/molecule-context-menu-dropdown.module';
import { MoleculeFilterBarModule } from '../../../components/molecules/molecule-filter-bar/molecule-filter-bar.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { AtomAsyncDataModule } from '../../atoms/atom-async-data/atom-async-data.module';
import { AtomWidgetFullWidthButtonModule } from '../../atoms/atom-widget-full-width-button/atom-widget-full-width-button.module';
import { MoleculeWidgetContainerComponent } from './molecule-widget-container.component';
import { FunctionPipe } from '@wdx/shared/utils';
@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomFeatureSvgModule,
        AtomWidgetFullWidthButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeContextMenuDropdownModule,
        MoleculeFilterBarModule,
        FunctionPipe,
        RouterModule,
        WdxButtonModule,
    ],
    declarations: [MoleculeWidgetContainerComponent],
    exports: [MoleculeWidgetContainerComponent],
})
export class MoleculeWidgetContainerModule {}
