import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * @deprecated This component as been deprecated and replaced by `isLoadingDirective`
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-async-data',
    templateUrl: './atom-async-data.component.html',
    styleUrls: ['./atom-async-data.component.scss'],
})
export class AtomAsyncDataComponent implements OnInit {
    @Input() set isLoading(isLoading: boolean) {
        this._isLoading = isLoading;
        if (isLoading === false) {
            this.isInitialLoad = false;
        }
        this.updateContainerClasses();
    }
    get isLoading(): boolean {
        return this._isLoading;
    }
    _isLoading = true;
    @Input() hasError = false;
    @Input() isForInfinityScroll = false;
    @Input() overlayContent = false;
    @Input() throbberContainerStyle = true;
    @Input() spinnerPosition: 'top' | 'bottom' = 'bottom';

    isInitialLoad = true;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.updateContainerClasses();
    }

    updateContainerClasses(): void {
        const containerClasses = ['h-100', 'd-block'];
        containerClasses.forEach((containerClass) => {
            if (this.isLoading) {
                this.renderer.removeClass(
                    this.elementRef.nativeElement,
                    containerClass
                );
            } else {
                this.renderer.addClass(
                    this.elementRef.nativeElement,
                    containerClass
                );
            }
        });
    }
}
