<nav [attr.aria-label]="dataSource">
    <ul
        class="pagination"
        [class.pagination-sm]="size === 'sm'"
        [class.pagination-lg]="size === 'lg'"
    >
        <li
            class="page-item"
            data-cy="page-item-first"
            [class.disabled]="disabled || page === 1"
        >
            <button
                class="page-link"
                data-cy="page-link-first"
                (click)="first()"
            >
                First
            </button>
        </li>
        <li
            class="page-item"
            data-cy="page-item-back"
            [class.disabled]="disabled || page === 1"
        >
            <button class="page-link" data-cy="page-link-back" (click)="back()">
                Back
            </button>
        </li>
        <ng-container *ngIf="lowerRotationIndex && lowerRotationIndex > 0">
            <li
                class="page-item"
                data-cy="page-item-low"
                [class.disabled]="disabled"
            >
                <button
                    class="page-link"
                    data-cy="page-link-low"
                    (click)="first()"
                >
                    1
                </button>
            </li>
            <ng-container
                *ngTemplateOutlet="ellipsis; context: { id: 'low' }"
            ></ng-container>
        </ng-container>
        <li
            *ngFor="let pageNumber of pages"
            class="page-item"
            [attr.data-cy]="'page-item-' + pageNumber"
            [class.active]="pageNumber === page"
            [class.disabled]="disabled"
        >
            <button
                class="page-link"
                [attr.data-cy]="'page-link-' + pageNumber"
                (click)="toPage(pageNumber)"
            >
                {{ pageNumber }}
            </button>
        </li>
        <ng-container
            *ngIf="upperRotationIndex && upperRotationIndex < collectionSize"
        >
            <ng-container
                *ngTemplateOutlet="ellipsis; context: { id: 'high' }"
            ></ng-container>
            <li
                class="page-item"
                data-cy="page-item-high"
                [class.disabled]="disabled"
            >
                <button
                    class="page-link"
                    data-cy="page-link-high"
                    (click)="last()"
                >
                    {{ collectionSize }}
                </button>
            </li>
        </ng-container>
        <li
            class="page-item"
            data-cy="page-item-next"
            [class.disabled]="disabled || page === collectionSize"
        >
            <button class="page-link" data-cy="page-link-next" (click)="next()">
                Next
            </button>
        </li>
        <li
            class="page-item"
            data-cy="page-item-last"
            [class.disabled]="disabled || page === collectionSize"
        >
            <button class="page-link" data-cy="page-link-last" (click)="last()">
                Last
            </button>
        </li>
    </ul>
</nav>

<ng-template #ellipsis let-id="id">
    <li class="page-item disabled" [attr.data-cy]="'page-item-ellipsis-' + id">
        <span class="page-link" [attr.data-cy]="'page-link-ellipsis-' + id">
            <wdx-icon icon="ellipsis"></wdx-icon>
        </span>
    </li>
</ng-template>
