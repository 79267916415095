<div
    class="card card-clickable h-100"
    [routerLink]="[ROUTE_PREFIX, '/articles', articleListItem.id]"
    [attr.data-cy]="'article-id-' + articleListItem.internalIdentifier"
>
    <ng-container [ngSwitch]="listStyle">
        <ng-container *ngSwitchCase="LIST_STYLE.Grid">
            <ng-container
                *ngTemplateOutlet="
                    cardImg;
                    context: { imgClass: 'card-img-top' }
                "
            ></ng-container>
            <ng-container *ngTemplateOutlet="cardBody"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="LIST_STYLE.List">
            <div class="grid gap-0">
                <ng-container
                    *ngTemplateOutlet="
                        cardImg;
                        context: { imgClass: 'g-col-4 h-100 rounded-start' }
                    "
                ></ng-container>
                <div class="g-col-8">
                    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #cardImg let-imgClass="imgClass">
    <div
        *ngIf="showArticleImage"
        class="card-img-bg-cover"
        [ngClass]="imgClass"
        [style.background-image]="
            'url(' + (articleListItem | articleImageUrl) + ')'
        "
    ></div>
</ng-template>

<ng-template #cardBody>
    <div
        class="card-body"
        [attr.data-cy]="'article-card-' + articleListItem.name"
    >
        <h5 class="card-title">
            <a
                class="card-link"
                [routerLink]="[ROUTE_PREFIX, 'articles', articleListItem.id]"
            >
                {{ articleListItem.name }}
            </a>
        </h5>
        <div class="card-text d-flex flex-wrap small">
            <div
                *ngIf="articleListItem.articleType"
                class="divider--short"
                [class.divider-end]="
                    articleListItem.source || articleListItem.internalIdentifier
                "
            >
                {{ articleListItem.articleType }}
            </div>
            <ng-container *ngIf="articleListItem.source">
                <div
                    *ngIf="!articleListItem.url; else sourceUrlLink"
                    class="divider--short"
                    [class.divider-end]="articleListItem.internalIdentifier"
                >
                    {{ articleListItem.source }}
                </div>
                <ng-template #sourceUrlLink>
                    <a
                        class="d-flex align-items-center divider--short"
                        target="_blank"
                        [class.divider-end]="articleListItem.internalIdentifier"
                        [attr.href]="articleListItem.url"
                        (click)="$event.stopPropagation()"
                    >
                        <div>{{ articleListItem.source }}</div>

                        <wdx-icon
                            [scale]="1.75"
                            icon="external-link"
                            class="ms-1"
                        ></wdx-icon>
                    </a>
                </ng-template>
            </ng-container>
            <div>{{ articleListItem.internalIdentifier }}</div>
        </div>
        <div class="card-text">
            <molecule-article-summary
                [avatar]="articleListItem.avatar"
                [publishDate]="articleListItem.datePublished"
                [status]="articleListItem.status"
                [isInternal]="
                    articleListItem.articleType === ARTICLE_TYPE.Internal
                "
                [files]="articleListItem.files"
            ></molecule-article-summary>
        </div>
        <div class="card-text d-sm-block d-none">
            {{
                articleListItem.synopsis
                    | truncateText : (listStyle === LIST_STYLE.Grid ? 190 : 300)
            }}
        </div>
    </div>
    <div
        *ngIf="articleListItem.tags?.length"
        class="card-footer border-0 pt-0 d-flex flex-wrap mb-n1"
    >
        <ng-container *ngFor="let tag of articleListItem.tags; let i = index">
            <wdx-info-card *ngIf="i < 2" class="block me-1 mb-1">
                <div wdxInfoCardLabel>{{ tag.tag }}</div>
            </wdx-info-card>
            <div *ngIf="i === 2" class="d-flex align-items-center">
                <span>+{{ articleListItem.tags.length - 2 }}&nbsp;more</span>
            </div>
        </ng-container>
    </div>
    <div class="card-footer">
        <div class="d-flex align-items-center justify-content-between">
            <molecule-engagements
                [articleId]="articleListItem.id"
                [links]="articleListItem.links"
                [likes]="articleListItem.likes"
                [infoModalTitle]="articleListItem.name"
                [hasInfoModal]="true"
                [showText]="listStyle === LIST_STYLE.List"
                (click)="$event.stopPropagation()"
            ></molecule-engagements>
            <div class="d-flex">
                <ng-container *ngIf="canLink$ | ngrxPush">
                    <molecule-action-button
                        *ngIf="showLinkButton"
                        class="ms-2"
                        [actionButton]="isLinked | fn : getLinkButton"
                        (onClick)="onLinkToggled($event)"
                    ></molecule-action-button>
                </ng-container>
                <molecule-action-button
                    class="ms-2"
                    [actionButton]="
                        articleListItem?.isFavourite | fn : getLikeButton
                    "
                    (onClick)="onLikeToggled($event)"
                ></molecule-action-button>
            </div>
        </div>
    </div>
</ng-template>
