import { Injectable, OnDestroy, inject } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import {
    CreatedDocumentInfo,
    IFormDocumentHandler,
    dynamicFormsActions,
} from '@wdx/shared/infrastructure/form-framework';
import { DOCUMENT_FORM_ID, FormSetup } from '@wdx/shared/utils';
import { Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { DynamicFormsManagementService } from '../../../../services/dynamic-forms-management.service';
import { FiledDocumentsService } from '../../../../shared/features/filed-documents/shared/services/filed-documents.service';
import * as documentsActions from '../../../../state/documents/documents.actions';

@Injectable()
export class FormDocumentHandler
    extends IFormDocumentHandler
    implements OnDestroy
{
    private filedDocumentsService = inject(FiledDocumentsService);
    private actionsSubject$ = inject(ActionsSubject);
    private dynamicFormsManagementService = inject(
        DynamicFormsManagementService
    );

    destroyed$ = new Subject<boolean>();

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    deleteDocument(id: string, name: string): void {
        this.filedDocumentsService.deleteDocument({ id, name });
    }

    deleteDocumentSuccess$(): Observable<string> {
        return this.actionsSubject$.pipe(
            takeUntil(this.destroyed$),
            ofType(documentsActions.deleteDocumentSuccess),
            map((action) => action.documentId)
        );
    }

    createDocumentSuccess$(): Observable<CreatedDocumentInfo> {
        return this.actionsSubject$.pipe(
            takeUntil(this.destroyed$),
            ofType(
                ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
            ),
            filter((action: any) => action.formId === DOCUMENT_FORM_ID),
            map((action) => ({
                id: action.response?.entityId,
                name: action.response?.data?.name,
                fileIndex: action.response?.data?.document[0],
            }))
        );
    }

    editDocument(formSetup: FormSetup): void {
        this.dynamicFormsManagementService.openDynamicFormModal(formSetup);
    }

    addDocument(formSetup: FormSetup): void {
        this.dynamicFormsManagementService.openDynamicFormModal(formSetup);
    }
}
