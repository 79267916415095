<widget-cases-list
    *ngIf="showChildCases"
    widgetTitle="Child Cases"
    [spokeButton]="{ label: 'See all child cases' }"
    [cases]="childCases$ | ngrxPush"
    [isLoading]="childCasesIsLoading$ | ngrxPush"
    [hasError]="childCasesHasError$ | ngrxPush"
    [showCaseListActionsButtons]="true"
    [showFilters]="false"
    [showFavourite]="false"
    [showSearchBar]="false"
    [showLegend]="false"
    [totalRecordCount]="(childCases$ | ngrxPush)?.length"
    [scrollMode]="SCROLL_MODE.Responsive"
>
    <div class="cases-list-action-buttons d-flex align-items-center">
        <molecule-action-button
            [actionButton]="createCaseActionButton$ | ngrxPush"
        ></molecule-action-button>
    </div>
</widget-cases-list>

<molecule-accordion
    *ngIf="showChildCases && showRelatedCases"
    name="relatedCaseAccordion"
    [panels]="relatedCasesAccordionPanels$ | ngrxPush"
    [flush]="true"
></molecule-accordion>

<widget-cases-list
    *ngIf="!showChildCases && showRelatedCases"
    widgetTitle="Cases"
    [spokeButton]="{ label: 'See all cases' }"
    [cases]="relatedCases$ | ngrxPush"
    [isLoading]="relatedCasesIsLoading$ | ngrxPush"
    [hasError]="relatedCasesHasError$ | ngrxPush"
    [showCaseListActionsButtons]="true"
    [showFilters]="false"
    [showFavourite]="false"
    [showSearchBar]="false"
    [showLegend]="false"
    [totalRecordCount]="(relatedCases$ | ngrxPush)?.length"
    [scrollMode]="SCROLL_MODE.Responsive"
    [linkToEntityType]="entityType"
    [linkToEntityId]="entityId"
>
    <div class="cases-list-action-buttons d-flex align-items-center">
        <molecule-action-button
            [actionButton]="createCaseActionButton$ | ngrxPush"
        ></molecule-action-button>
    </div>
</widget-cases-list>

<molecule-accordion
    *ngIf="showSuggestedCases"
    name="suggestedCaseAccordion"
    [panels]="suggestedCasesAccordionPanels$ | ngrxPush"
    [flush]="true"
></molecule-accordion>

<ng-template #relatedCasesContentTemplate>
    <div class="accordion-content">
        <organism-cases-list
            class="d-block h-100"
            noDataSVGSize="xs"
            [noDataMessage]="NO_DATA_MESSAGE"
            [isLoading]="relatedCasesIsLoading$ | ngrxPush"
            [cases]="relatedCases$ | ngrxPush"
            [casesPerRow]="cardsPerRow"
            [totalRecordCount]="(relatedCases$ | ngrxPush)?.length"
            [showLegend]="false"
            [showFilterBar]="false"
            [showSearchBar]="false"
            [showFilters]="false"
            [isSuggestedCardList]="true"
            [scrollMode]="SCROLL_MODE.Responsive"
        ></organism-cases-list>
    </div>
</ng-template>

<ng-template #suggestedCasesContentTemplate>
    <div class="accordion-content">
        <organism-cases-list
            class="d-block h-100"
            noDataSVGSize="xs"
            [noDataMessage]="NO_DATA_MESSAGE"
            [isLoading]="suggestedCasesIsLoading$ | ngrxPush"
            [cases]="suggestedCases$ | ngrxPush"
            [casesPerRow]="cardsPerRow"
            [totalRecordCount]="(suggestedCases$ | ngrxPush)?.length"
            [showLegend]="false"
            [showFilterBar]="false"
            [showSearchBar]="false"
            [showFilters]="false"
            [isSuggestedCardList]="true"
            [linkToEntityType]="entityType"
            [linkToEntityId]="entityId"
            [scrollMode]="SCROLL_MODE.Responsive"
        ></organism-cases-list>
    </div>
</ng-template>
