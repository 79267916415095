import { Injectable, inject } from '@angular/core';
import {
    FormArray,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
} from '@angular/forms';
import {
    Action,
    ConditionalMessage,
    FieldDefinition,
    FormContext,
    FormDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
    FormFunctionResult,
    FormInitialisationMode,
    FormLock,
    FormSectionLayoutDefinition,
    INITIALISATION_MODE_MAP,
    Trigger,
    UpdateField,
    UpdateLabel,
    UpdateSection,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
    FormControlService,
    FormLockService,
    FormProcessConditionDataService,
    FormTriggersService,
} from '../../services';

import { IFormDataResult } from '../../constants';
import {
    IFormControlData,
    IReactiveStore,
    LayoutAndDefinitionEntry,
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
} from '../../models';
import { FormFieldsDataService } from '../form-fields-data';

@Injectable()
export class FormConditionsService extends WdxDestroyClass {
    private _layoutAndDefinition: LayoutAndDefinitionEntry[] = [];
    private _layoutAndDefinition$ = new BehaviorSubject<
        LayoutAndDefinitionEntry[]
    >([]);
    private formSectionLayoutDefinitions!: FormSectionLayoutDefinition[];
    private pristine = true;
    private form!: FormGroup;
    private formData!: IFormDataResult;
    private formDefinition!: FormDefinition;
    private formId!: string;
    private fieldsToNullCheck!: string[];
    private conditionFunctions: any[] = [];
    private conditionFunctions$: Observable<any>[] = [];
    private initialisationMode!: FormInitialisationMode;

    private formProcessService = inject(FormProcessConditionDataService);
    private formTriggersService = inject(FormTriggersService);
    private formLockService = inject(FormLockService);
    private formControlService = inject(FormControlService);
    private formFieldsDataService = inject(FormFieldsDataService);

    get layoutAndDefinition$(): Observable<LayoutAndDefinitionEntry[]> {
        return this._layoutAndDefinition$;
    }

    get layoutAndDefinition(): LayoutAndDefinitionEntry[] {
        return this._layoutAndDefinition$.value;
    }

    resetFormSectionLayoutDefinitions(): void {
        this._layoutAndDefinition$.next([...this._layoutAndDefinition]);
    }

    configService(
        form: FormGroup,
        formDefinition: FormDefinition,
        formData: IFormDataResult,
        formId: string,
        fieldsToNullCheck: string[],
        initialisationMode: FormInitialisationMode
    ): void {
        this.form = form;
        this.formData = formData;
        this.formDefinition = formDefinition;
        this.formId = formId;
        this.formSectionLayoutDefinitions = [
            ...(this.formDefinition?.layout?.sectionLayoutDefinitions || []),
        ];
        this.fieldsToNullCheck = fieldsToNullCheck;
        this.initialisationMode = initialisationMode;

        this.combinedLayoutAndDefinition();
        this.applyTriggerCondition();
    }

    setFormControlListener(
        trigger: Trigger,
        formControlData: IFormControlData
    ): void {
        this.formControlService.setValueChangeListener(
            trigger,
            formControlData,
            this.applyConditions.bind(this),
            this.destroyed$
        );
    }

    updateIsTrigger(
        trigger: any,
        triggerFieldValue: any,
        formControlData?: IFormControlData
    ): void {
        trigger.is?.forEach((is: any) => {
            const conditionIsTrue = this.formTriggersService.is(
                is,
                triggerFieldValue
            );
            const actionSource: any = conditionIsTrue ? is.then : is.else;

            this.updateSectionFromArray(actionSource);
            this.updateMessageDefinitionToShow(
                actionSource?.hideMessage,
                actionSource?.showMessage
            );

            actionSource?.updateFields?.forEach((updateField: any) => {
                this.updateField(updateField, formControlData);
            });

            actionSource?.updateLabels?.forEach((updateLabel: any) =>
                this.updateLabel(updateLabel)
            );

            actionSource?.functions?.forEach((updateLabel: any) =>
                this.executeFunction(updateLabel, trigger, formControlData)
            );
        });
    }

    reset() {
        this._layoutAndDefinition = [];
        this.layoutAndDefinitionNext$();
        this.formSectionLayoutDefinitions = [];
        this.pristine = true;
        this.conditionFunctions = [];
        this.conditionFunctions$ = [];
    }

    /**
     * Sets the isLocked status on definition fields
     * @param forceFullFormLock
     */
    updateLockStatuses(forceFullFormLock: boolean): void {
        this._layoutAndDefinition = this._layoutAndDefinition.map(
            (formElement) => {
                return {
                    ...formElement,
                    layoutAndDefinition: formElement.layoutAndDefinition?.map(
                        (definition) => {
                            return {
                                ...definition,
                                isLocked:
                                    forceFullFormLock ||
                                    this.formLockService.fieldIsLocked(
                                        definition.name as string,
                                        this.formData.lock as FormLock,
                                        false
                                    ),
                            };
                        }
                    ),
                };
            }
        );
        this.layoutAndDefinitionNext$();
    }

    private combinedLayoutAndDefinition(): void {
        this.formSectionLayoutDefinitions.forEach(
            (sectionLayoutDefinition: FormSectionLayoutDefinition) => {
                const STORE_DATA: {
                    section: any;
                    layoutAndDefinition: any[];
                } = {
                    section: sectionLayoutDefinition,
                    layoutAndDefinition: [],
                };
                let children: any[] = [];
                let ARR: IReactiveStore = {};

                sectionLayoutDefinition.elementLayoutDefinitions?.forEach(
                    (element: FormElementLayoutDefinition) => {
                        children = [];

                        const FORM_CONTROLS_DATA =
                            this.formControlService.getFormControlData(
                                this.form,
                                element.name as string
                            );

                        const DEFINITION: FormElementLayoutDefinition = {
                            ...this.findDefinitionFromLayoutFieldElement(
                                element,
                                this.formDefinition
                            ),
                        };

                        for (const FORM_CONTROL_DATA of FORM_CONTROLS_DATA) {
                            this.formProcessService.updateValidation(
                                FORM_CONTROL_DATA.formControl,
                                { ...DEFINITION, ...element }
                            );
                        }

                        if (element.elementType === `${FormFieldType.Array}`) {
                            const ARR_RES =
                                this.loopSubFormLayoutAndDefinitionData(
                                    element,
                                    DEFINITION,
                                    [element.name as string]
                                );

                            ARR = ARR_RES;

                            if (Object.keys(ARR).length) {
                                children = [ARR];
                            }
                        }

                        STORE_DATA.layoutAndDefinition.push({
                            ...DEFINITION,
                            ...element,
                            ...((DEFINITION as any).options && {
                                originalOptions: (DEFINITION as any).options,
                            }),
                            children,
                        });
                    }
                );

                this._layoutAndDefinition.push({
                    ...STORE_DATA,
                    section: {
                        ...STORE_DATA.section,
                        ...((STORE_DATA.section.isHidden ||
                            STORE_DATA.layoutAndDefinition.every((def) =>
                                Boolean(def.summaryLevel)
                            )) && { isHidden: true }),
                    },
                });
                this.layoutAndDefinitionNext$();
            }
        );
    }

    private applyTriggerCondition(): void {
        const TRIGGERS = this.formDefinition.conditions?.triggers;
        const INITIALISATION: Action | undefined =
            this.formDefinition.conditions?.initialisation;
        const INITIALISATION_BY_MODE =
            this.formDefinition.conditions?.initialisationByMode;

        if (INITIALISATION) {
            this.applyActions(INITIALISATION);
        }

        if (
            INITIALISATION_BY_MODE &&
            (INITIALISATION_BY_MODE as any)[
                INITIALISATION_MODE_MAP[this.initialisationMode]
            ]
        ) {
            this.applyActions(
                (INITIALISATION_BY_MODE as any)[
                    INITIALISATION_MODE_MAP[this.initialisationMode]
                ]
            );
        }

        this.callInitialisationFunctions();

        /**
         * Trigger fields can either be of context or data sources.
         * - 'context' fields are prefixed with the '$' symbol and values are read directly from context source.
         * - 'data' field values are read from the associated formControl.
         */

        TRIGGERS?.map((trigger) => {
            const USE_CONTEXT = this.formTriggersService.useContext(trigger);

            if (USE_CONTEXT) {
                const { context } = this.formData;

                const contextValue =
                    this.formTriggersService.getContextTriggerValue(
                        trigger,
                        context as FormContext
                    );

                if (context) {
                    this.updateIsTrigger(trigger, contextValue);
                }
            }

            if (!USE_CONTEXT) {
                const FORM_CONTROLS_DATA =
                    this.formControlService.getFormControlData(
                        this.form,
                        trigger.field as string
                    );

                for (const FORM_CONTROL_DATA of FORM_CONTROLS_DATA) {
                    this.applyConditions(trigger, FORM_CONTROL_DATA);
                    this.setFormControlListener(trigger, FORM_CONTROL_DATA);
                }
            }

            this.pristine = false;
        });
    }

    private applyConditions(
        trigger: Trigger,
        formControlData: IFormControlData
    ): void {
        if (formControlData.formControl) {
            const formControl = formControlData.formControl;
            this.updateIsTrigger(trigger, formControl.value, formControlData);

            if (trigger.changed && !this.pristine && !formControl?.pristine) {
                trigger.changed?.updateFields?.forEach((field) => {
                    const FIELD = this.updateValueForField(field);
                    this.updateField(FIELD);
                });

                trigger.changed?.recalculate?.forEach((field) => {
                    this.recalculateField(field);
                });

                trigger.changed?.functions?.forEach((field) => {
                    if (this.pristine) {
                        this.updateFunctionArray(field);
                    }

                    if (!this.pristine) {
                        this.executeFunction(field, trigger, formControlData);
                    }
                });
            }
        }
    }

    private executeFunction(
        field: {
            name?: string;
        }, // This is the api-model interface Function but that is a protected word so added it like this
        trigger: Trigger,
        formControlData?: IFormControlData
    ): void {
        const NAME = this.formControlService.stringToFormControlObject(
            trigger.field as string
        );

        if (formControlData) {
            this.formProcessService
                .getApiFunctionTrigger(
                    {
                        formId: this.formId,
                        formValue: this.formFieldsDataService.parseFormData(
                            this.fieldsToNullCheck,
                            this.form
                        ),
                    },
                    field.name as string,
                    NAME.Level1ControlName,
                    formControlData?.index
                )
                .pipe(
                    filter((res) => Boolean(res)),
                    take(1)
                )
                .subscribe((data: FormFunctionResult) => {
                    this.updateFunctionChanges(data);
                });
        }
    }

    private findDefinitionFromLayoutFieldElement(
        element: FormElementLayoutDefinition,
        formDefinition: FormDefinition
    ): FormElementLayoutDefinition {
        const MAX_COUNT = formDefinition.schema?.length || 0;
        let name: string | undefined = '';
        let count = 0;
        let definition: FormElementLayoutDefinition = {};

        while (element.name !== name && count < MAX_COUNT) {
            const definitionElement = formDefinition.schema?.[count];
            if (definitionElement?.name === element.name) {
                name = definitionElement?.name;
                definition = definitionElement as FormElementLayoutDefinition;
                count = MAX_COUNT;
            }

            count++;
        }

        return definition;
    }

    private loopSubFormLayoutAndDefinitionData(
        elementLayout: FormElementLayoutDefinition,
        definition: FieldDefinition,
        subFormPath: string[]
    ): IReactiveStore {
        const STORE_DATA: IReactiveStore = {
            section: {},
            layoutAndDefinition: [],
        };
        const ARR: any[] = [];
        const SUB_FORM_ARRAY: any[] = [];

        const FORM_ARRAY = this.formProcessService.getFormArray(
            this.form,
            subFormPath,
            0
        );

        // pass in FieldDefinition for subform array as we need to calculate appropriate min/max for schema and/or layout
        this.formProcessService.updateValidation(
            FORM_ARRAY as UntypedFormArray,
            elementLayout,
            definition
        );

        elementLayout?.sectionLayoutDefinitions?.forEach((childSection) => {
            STORE_DATA.section = childSection;

            childSection.elementLayoutDefinitions?.forEach((childElement) => {
                if (childElement.elementType === `${FormFieldType.Array}`) {
                    let childDefinition = {};
                    const updatedSubFormPath = [...subFormPath];
                    definition.childSchema?.forEach((item) => {
                        if (childElement.name === item.name) {
                            updatedSubFormPath.push(
                                childElement.name as string
                            );
                            childDefinition = item;
                        }
                    });

                    ARR.push(
                        this.loopSubFormLayoutAndDefinitionData(
                            childElement,
                            childDefinition,
                            updatedSubFormPath
                        )
                    );
                }

                FORM_ARRAY?.controls?.forEach((formControl) => {
                    const FORM_CONTROL = formControl.get(
                        childElement.name as string
                    ) as UntypedFormControl;

                    this.formProcessService.updateValidation(
                        FORM_CONTROL,
                        childElement
                    );
                });

                const MAX_COUNT = definition.childSchema?.length || 0;
                let name = '';
                let count = 0;
                let definitionChild = {};

                while (childElement.name !== name && count < MAX_COUNT) {
                    const definitionElement = definition.childSchema?.[count];

                    if (definitionElement?.name === childElement.name) {
                        name = definitionElement?.name as string;
                        definitionChild = definitionElement as FieldDefinition;
                    }

                    count++;
                }

                SUB_FORM_ARRAY.push(
                    JSON.parse(
                        JSON.stringify({
                            ...definitionChild,
                            ...childElement,
                            ...((definitionChild as any).options && {
                                originalOptions: (definitionChild as any)
                                    .options,
                            }),
                            children: [...ARR],
                        })
                    )
                );
            });
        });

        STORE_DATA.layoutAndDefinition = [];
        FORM_ARRAY?.controls.map(() => {
            STORE_DATA.layoutAndDefinition?.push(
                JSON.parse(JSON.stringify(SUB_FORM_ARRAY))
            );
        });

        return STORE_DATA;
    }

    private updateFunctionArray(action: any): void {
        if (!this.conditionFunctions.some((e: any) => e.name === action.name)) {
            this.conditionFunctions.push(action);
            this.conditionFunctions$.push(
                this.formProcessService.getApiFunctionTrigger(
                    {
                        formId: this.formId,
                        formValue: this.formFieldsDataService.parseFormData(
                            this.fieldsToNullCheck,
                            this.form
                        ),
                    },
                    action.name
                )
            );
        }
    }

    private applyActions(data: any): void {
        Object.keys(data).map((actions) => {
            const ACTIONS = data[actions];
            if (ACTIONS.length) {
                ACTIONS.map((action: any) => {
                    if (actions === 'functions') {
                        this.updateFunctionArray(action);
                    }

                    if (actions === 'recalculate') {
                        this.recalculateField(action);
                    }

                    if (actions === 'updateFields') {
                        const FIELD = this.updateValueForField(action);
                        this.updateField(FIELD);
                    }

                    if (actions === 'updateSections') {
                        this.updateSection(action);
                    }

                    if (actions === 'showMessage') {
                        this.updateMessageDefinitionToShow(undefined, ACTIONS);
                    }

                    if (actions === 'hideMessage') {
                        this.updateMessageDefinitionToShow(ACTIONS, undefined);
                    }
                });
            }
        });
    }

    private callInitialisationFunctions(): void {
        if (this.conditionFunctions.length && this.pristine) {
            this.pristine = false;
            forkJoin(this.conditionFunctions$)
                .pipe(
                    filter((res) => Boolean(res)),
                    take(1)
                )
                .subscribe((data: FormFunctionResult[]) => {
                    data?.map((functionRes) => {
                        this.updateFunctionChanges(functionRes);
                    });
                });
        }
    }

    private updateFunctionChanges(functionRes: any): void {
        if (functionRes?.dataChanges) {
            Object.keys(functionRes?.dataChanges).map((item) => {
                const NAME = this.formControlService.stringToFormControlObject(
                    item as string
                );

                let formControl = this.form.get(NAME.Level1ControlName);

                if (typeof NAME.Level1SubFormIndex === 'number') {
                    formControl =
                        this.formControlService.getFormArrayIndexFormControl(
                            formControl as FormArray,
                            NAME.Level1SubFormIndex,
                            NAME.Level2ControlName as string
                        );
                }

                const IS_FORM_ARRAY = formControl instanceof FormArray;
                const DATA = functionRes.dataChanges[item];

                this.formProcessService.updateFormControlValue(
                    formControl as UntypedFormControl,
                    DATA,
                    this.pristine,
                    IS_FORM_ARRAY ? true : undefined
                );
            });
        }

        if (functionRes?.schemaChanges) {
            Object.keys(functionRes?.schemaChanges).forEach((propertyName) => {
                const FIELD_DATA = functionRes.schemaChanges[propertyName];
                this.updateField(FIELD_DATA);
            });
        }
    }

    private recalculateField(field: string): void {
        const TOTAL_SUM = this.formProcessService.recalculate(
            this.form,
            field,
            this.formDefinition?.conditions
        );
        this.updateField({
            name: field,
            value: TOTAL_SUM,
        });
    }

    private updateSectionFromArray(sectionsArray: any): void {
        sectionsArray?.updateSections?.forEach((updateSection: any) =>
            this.updateSection(updateSection)
        );
    }

    private updateMessageDefinitionToShow(
        hideMessage?: ConditionalMessage[],
        showMessage?: ConditionalMessage[]
    ): void {
        if (hideMessage?.length) {
            hideMessage.forEach((removeMessage) => {
                delete this.formFieldsDataService.messageDefinitionToShow[
                    removeMessage.name as string
                ];
            });
        }

        if (showMessage?.length) {
            showMessage.forEach((addMessage) => {
                this.formFieldsDataService.messageDefinitionToShow[
                    addMessage.name as string
                ] =
                    this.formFieldsDataService.messageDefinitionToObject[
                        addMessage.name as string
                    ];
            });
        }
    }

    private updateSection(updateSection: UpdateSection): void {
        let count = 0;
        const FINAL = this._layoutAndDefinition?.length as number;

        while (count < FINAL) {
            if (
                this._layoutAndDefinition?.[count].section?.name ===
                updateSection.name
            ) {
                (this._layoutAndDefinition as ReactiveFormElement[])[
                    count
                ].section = {
                    ...this._layoutAndDefinition?.[count].section,
                    ...updateSection,
                };

                count = FINAL;
            }

            count++;
        }
    }

    private updateLabel(updateLabel: UpdateLabel): void {
        this._layoutAndDefinition = this._layoutAndDefinition.map((section) => {
            return {
                ...section,
                layoutAndDefinition: section.layoutAndDefinition?.map(
                    (definition) => {
                        if (definition.name === updateLabel.name) {
                            return {
                                ...definition,
                                label: updateLabel.label,
                            };
                        }
                        return definition;
                    }
                ),
            };
        });

        this.layoutAndDefinitionNext$();
    }

    private updateValueForField(updateField: UpdateField): UpdateField {
        const UPDATE_FIELD = { ...updateField };
        const VALUE = UPDATE_FIELD?.value;

        if (
            typeof VALUE === 'string' &&
            VALUE?.includes('{') &&
            VALUE?.includes('}')
        ) {
            const VALUES = UPDATE_FIELD?.value?.split(/[{}]/);
            let values = UPDATE_FIELD?.value;

            if (VALUES?.length) {
                VALUES.forEach((value: string) => {
                    if (value.trim()) {
                        const DATA = this.formControlService.getFormControlData(
                            this.form,
                            value
                        )?.[0]?.formControl?.value;

                        values = values?.replaceAll(`{${value}}`, DATA || '');

                        UPDATE_FIELD.value = values;
                    }
                });

                this.updateFieldFormControls(
                    this.formControlService.getFormControlData(
                        this.form,
                        updateField.name as string
                    ),
                    { name: UPDATE_FIELD.name },
                    UPDATE_FIELD
                );
            }
        }

        return UPDATE_FIELD;
    }

    private updateField(
        updateField: UpdateField,
        formControlData?: IFormControlData
    ): void {
        this._layoutAndDefinition.forEach(
            (sectionObject: ReactiveFormElement) => {
                let firstLevelCount = 0;
                const FIRST_LEVEL_LAYOUT_LENGTH =
                    sectionObject.layoutAndDefinition?.length || 0;
                const UPDATE_FIELD_NAME =
                    this.formControlService.stringToFormControlObject(
                        updateField.name as string
                    );

                while (firstLevelCount < FIRST_LEVEL_LAYOUT_LENGTH) {
                    const DEFINITION = sectionObject.layoutAndDefinition?.[
                        firstLevelCount
                    ] as ReactiveFormLayoutAndDefinition;

                    if (
                        DEFINITION.name === UPDATE_FIELD_NAME.Level1ControlName
                    ) {
                        const FORM_CONTROLS_DATA =
                            this.formControlService.getFormControlData(
                                this.form,
                                updateField.name as string,
                                typeof formControlData?.index === 'number'
                                    ? [formControlData.index]
                                    : undefined
                            );

                        if (UPDATE_FIELD_NAME.Level1HasSubForm) {
                            const hasFormGroupIndex = Boolean(
                                typeof formControlData?.index === 'number'
                            );

                            if (!hasFormGroupIndex) {
                                (
                                    DEFINITION.children?.[0]
                                        ?.layoutAndDefinition as ReactiveFormLayoutAndDefinition[][]
                                ).map((_, index) => {
                                    this.updateField(
                                        updateField,
                                        FORM_CONTROLS_DATA[index]
                                    );
                                });
                            }

                            const SUB_FORM_LAYOUT_AND_DEFINITION = (
                                DEFINITION.children?.[0]
                                    ?.layoutAndDefinition as ReactiveFormLayoutAndDefinition[][]
                            )[formControlData?.index as number];

                            const SUB_FORM_MAX =
                                SUB_FORM_LAYOUT_AND_DEFINITION?.length;
                            let subFormItemCount = 0;

                            while (subFormItemCount < SUB_FORM_MAX) {
                                const SUB_DEFINITION =
                                    SUB_FORM_LAYOUT_AND_DEFINITION[
                                        subFormItemCount
                                    ];

                                if (
                                    SUB_DEFINITION.name ===
                                    UPDATE_FIELD_NAME.Level2ControlName
                                ) {
                                    this.updateFieldFormControls(
                                        FORM_CONTROLS_DATA,
                                        SUB_DEFINITION,
                                        updateField
                                    );

                                    (SUB_FORM_LAYOUT_AND_DEFINITION[
                                        subFormItemCount
                                    ] as FieldDefinition) = JSON.parse(
                                        JSON.stringify({
                                            ...SUB_DEFINITION,
                                            ...updateField,
                                            // This is very important as it will let us update updateField
                                            name: SUB_DEFINITION.name,
                                            ...((SUB_DEFINITION as any)
                                                .originalOptions && {
                                                options:
                                                    (updateField.options
                                                        ?.length || 0) > 0
                                                        ? updateField.options
                                                        : (
                                                              SUB_DEFINITION as any
                                                          ).originalOptions,
                                            }),
                                        })
                                    );

                                    subFormItemCount = SUB_FORM_MAX;
                                }

                                subFormItemCount++;
                            }
                        }

                        if (!UPDATE_FIELD_NAME.Level1HasSubForm) {
                            this.updateFieldFormControls(
                                FORM_CONTROLS_DATA,
                                DEFINITION,
                                updateField
                            );

                            ((
                                sectionObject.layoutAndDefinition as ReactiveFormLayoutAndDefinition[]
                            )[firstLevelCount] as FieldDefinition) = JSON.parse(
                                JSON.stringify({
                                    ...DEFINITION,
                                    ...updateField,
                                    // This is very important as it will allow us to update FieldName
                                    name: DEFINITION.name,
                                    ...((DEFINITION as any).originalOptions && {
                                        options:
                                            (updateField.options?.length || 0) >
                                            0
                                                ? updateField.options
                                                : (DEFINITION as any)
                                                      .originalOptions,
                                    }),
                                })
                            );

                            firstLevelCount = FIRST_LEVEL_LAYOUT_LENGTH;
                        }
                    }

                    firstLevelCount++;
                }
            }
        );

        this._layoutAndDefinition = [...this._layoutAndDefinition];
        this.layoutAndDefinitionNext$();
    }

    private updateFieldFormControls(
        formControlsData: IFormControlData[],
        definition: ReactiveFormLayoutAndDefinition,
        updateField: UpdateField
    ): void {
        for (const formControlData of formControlsData) {
            const FORM_CONTROL = formControlData.formControl;

            // fields that are 'locked' should ignore disable and validation actions
            if (
                !this.formLockService.fieldIsLocked(
                    definition.name as string,
                    this.formData?.lock as FormLock
                )
            ) {
                this.formProcessService.updateFormControlValidation(
                    FORM_CONTROL,
                    {
                        ...definition,
                        ...updateField,
                    } as FieldDefinition
                );
            }

            this.formProcessService.findFormControlUpdateValue(
                FORM_CONTROL,
                updateField,
                definition,
                this.pristine
            );
        }
    }

    private layoutAndDefinitionNext$(): void {
        this._layoutAndDefinition$.next(this._layoutAndDefinition);
    }
}
