import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import {
    FieldDefinition,
    FormFieldType,
    SelectApiSource,
    SummaryLevel,
    UTILS_ICON_DANGER,
} from '@wdx/shared/utils';

import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { LOOKUP_SUMMARY_TYPES } from '../../../constants';
import { ISubformCustomHandler } from '../../../interfaces';
import { FormSummaryService } from '../../../services';
import { FormSubformControlDirective } from './subform-control-host.directive';

@Component({
    selector: 'wdx-ff-subform-info',
    templateUrl: './subform-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubformInfoComponent implements OnDestroy, OnInit {
    private formSummaryService = inject(FormSummaryService);
    private subformCustomHandler = inject(ISubformCustomHandler, {
        optional: true,
    });

    @Input() entityId!: string;
    @Input() fieldDefinition!: FieldDefinition;
    get data() {
        return this._data;
    }
    @Input() set data(val: any) {
        this._data = val;
        if (val) {
            this.setSummaryLevelData();
        }

        if (
            !LOOKUP_SUMMARY_TYPES.includes(
                this.titleField?.fieldType as FormFieldType
            )
        ) {
            return;
        }
        if (this.subformCustomHandler) {
            this.setupAvatarRenderer(
                this.avatarHost,
                this.subformCustomHandler
            );
        } else {
            this.avatarDefaultIcon = true;
        }
    }
    @Input() sectionLayoutDefinitionName!: string;
    @Input() parentData!: any;
    @Input() schema!: FieldDefinition[];
    @Input() index!: any;
    @ViewChild(FormSubformControlDirective, { static: true })
    avatarHost!: FormSubformControlDirective;

    titleField!: FieldDefinition;
    subtitle1Field!: FieldDefinition;
    subtitle2Field!: FieldDefinition;
    subtitle3Field!: FieldDefinition;
    primaryField!: FieldDefinition;
    status!: string;
    avatarData!: any;
    avatarDefaultIcon = false;

    isPrimaryField = false;

    titleValue!: any;
    title$!: Observable<string>;
    subtitles$ = new BehaviorSubject<string[]>(null as any);

    destroyed$ = new Subject<boolean>();

    readonly LOOKUP_SUMMARY_TYPES = LOOKUP_SUMMARY_TYPES;
    readonly CUSTOM_SUMMARY_TYPES = [...LOOKUP_SUMMARY_TYPES];
    readonly SELECT_API_SOURCE = SelectApiSource;
    readonly ICON_DANGER = UTILS_ICON_DANGER;

    private _data!: any;

    ngOnInit(): void {
        this.setSummaryLevelData();
    }

    setSummaryLevelData(): void {
        this.titleField = this.findFieldBySummaryLevel(
            SummaryLevel.Title
        ) as FieldDefinition;
        this.subtitle1Field = this.findFieldBySummaryLevel(
            SummaryLevel.SubTitle1
        ) as FieldDefinition;
        this.subtitle2Field = this.findFieldBySummaryLevel(
            SummaryLevel.SubTitle2
        ) as FieldDefinition;
        this.subtitle3Field = this.findFieldBySummaryLevel(
            SummaryLevel.SubTitle3
        ) as FieldDefinition;

        this.primaryField = this.findFieldBySummaryLevel(
            SummaryLevel.IsPrimary
        ) as FieldDefinition;

        this.titleValue = this.data?.[this.titleField?.name as string];

        const title = this.getDataForInfo(this.titleField?.name as string);

        this.title$ = this.formSummaryService.getStringValue(
            title,
            this.titleField,
            {
                formData: this.data,
                entityId: '',
            },
            this.parentData,
            this.schema
        ) as Observable<string>;

        this.isPrimaryField = this.getDataForInfo(
            this.primaryField?.name as string
        );
        const subTitle1Data = this.getDataForInfo(
            this.subtitle1Field?.name as string
        );
        const subTitle2Field = this.getDataForInfo(
            this.subtitle2Field?.name as string
        );
        const subTitle3Field = this.getDataForInfo(
            this.subtitle3Field?.name as string
        );

        combineLatest([
            this.formSummaryService.getStringValue(
                subTitle1Data,
                this.subtitle1Field,
                {
                    formData: this.data,
                    entityId: '',
                },
                this.parentData,
                this.schema
            ),
            this.formSummaryService.getStringValue(
                subTitle2Field,
                this.subtitle2Field,
                {
                    formData: this.data,
                    entityId: '',
                },
                this.parentData,
                this.schema
            ),
            this.formSummaryService.getStringValue(
                subTitle3Field,
                this.subtitle3Field,
                {
                    formData: this.data,
                    entityId: '',
                },
                this.parentData,
                this.schema
            ),
        ])
            .pipe(take(1))
            .subscribe({
                next: (subtitles) => {
                    subtitles = this.formSummaryService.overrideSubTitle(
                        subtitles,
                        title,
                        this.titleField,
                        'addressType'
                    );

                    this.subtitles$.next(
                        (subtitles as string[]).filter((subtitle) => subtitle)
                    );
                },
            });
    }

    findFieldBySummaryLevel(summaryLevel: SummaryLevel) {
        return this.fieldDefinition?.childSchema?.find(
            (definition) => definition?.summaryLevel === summaryLevel
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    private setupAvatarRenderer(
        host: FormSubformControlDirective,
        subformCustomHandler: ISubformCustomHandler
    ) {
        this.avatarData = subformCustomHandler.getAvatarData(this.titleValue);
        const viewContainerRef = host.viewContainerRef;
        viewContainerRef.clear();

        if (!this.avatarData) {
            this.avatarDefaultIcon = true;
            return;
        }

        const { component, inputs, avatarInput } =
            subformCustomHandler.getAvatarComponent();
        const componentRef = viewContainerRef.createComponent(component);
        Object.entries(inputs).forEach(([key, value]) => {
            componentRef.instance[key] = value;
        });
        componentRef.instance[avatarInput] = this.avatarData;
    }

    private getDataForInfo(name: string) {
        return name in this.data
            ? this.data[name]
            : this.parentData?.[this.fieldDefinition?.name as string]?.[
                  this.index
              ]?.[name];
    }
}
