import { SlaStatusType } from '@wdx/clmi/api-models';

export const CASEWORK_STATUS_COMPLETE = 'Complete';
export const CASEWORK_STATUS_OPEN = 'Open';
export const CASEWORK_STATUS_HAS_ISSUE = 'Has Issue';
export const CASEWORK_STATUS_OVERDUE = 'Overdue';
export const CASEWORK_STATUS_DUE_SOON = 'Due Soon';
export const CASEWORK_STATUS_DUE_LATER = 'Due Later';
export const CASEWORK_STATUS_NO_DEADLINE = 'No Deadline';
export const CASEWORK_STATUS_CANCELLED = 'Cancelled';

export const CASEWORK_COMPLETED_SLA_STATUS_MAP = {
    [SlaStatusType.None]: 'Completed',
    [SlaStatusType.Within]: 'Completed within SLA',
    [SlaStatusType.Breached]: 'Completed outside SLA',
};

export const CASEWORK_ACTIVE_SLA_STATUS_MAP = {
    [SlaStatusType.None]: 'No SLA',
    [SlaStatusType.Within]: 'SLA Due',
    [SlaStatusType.Approaching]: 'SLA Due',
    [SlaStatusType.Breached]: 'SLA Overdue',
};
