<molecule-input-wrapper
    [formInputData]="formInputData"
    [validationErrorMessage]="validationErrorMessage"
    [hideGuidanceText]="hideGuidanceText"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [value]="value"
>
    <div class="input-group">
        <div class="input-group-text"><i class="fal fa-search"></i></div>
        <input
            #inputSearch
            class="form-control"
            type="search"
            inputmode="search"
            [attr.data-cy]="cySelector || formInputData?.cySelector"
            [placeholder]="
                formInputData?.placeholder ?? placeholder ?? 'Search'
            "
            [disabled]="formInputData?.isDisabled"
            [(ngModel)]="value"
            (search)="$event.stopPropagation()"
            (ngModelChange)="onChange($event)"
        />
    </div>
</molecule-input-wrapper>
