import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FilesFacadeService } from '@wdx/shared/infrastructure/file-io';
import { FileIndex } from '@wdx/shared/utils';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { IFormDynamicData } from '../../../interfaces';

@Component({
    selector: 'wdx-ff-file-upload-control',
    templateUrl: './form-file-upload-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFileUploadControlComponent
    extends BaseWdxFormControlClass
    implements OnInit, OnDestroy
{
    fileIndex!: FileIndex | null;
    fileIndexesToDelete: string[] = [];
    initialContainerValue!: FileIndex;

    get containerField() {
        return this.controlContainer.control?.get(
            this.formElement.name as string
        );
    }

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private filesFacadeService: FilesFacadeService
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        if (this.formControl?.value?.length) {
            this.fileIndex = this.formControl.value[0];
        }

        this.setInitialContainerValue();
    }

    setInitialContainerValue() {
        this.initialContainerValue = this.containerField?.value
            ? this.containerField.value[0]
            : null;
    }

    onFileUploaded(fileIndex: FileIndex) {
        this.fileIndex = fileIndex;
        this.formControl?.setValue([fileIndex]);
    }

    onDeleteFile(fileIndex: FileIndex): void {
        this.fileIndexesToDelete.push(fileIndex.fileIndex as string);
        this.fileIndex = null;
        this.formControl?.setValue(null);
    }

    /**
     * Perform file cleanup on files not saved to parent control
     */
    fileCleanup() {
        // if container is disabled don't attempt to cleanup
        if (this.containerField?.disabled) {
            return;
        }

        const currentContainerValue = this.containerField?.value;

        // delete all files removed from control using delete button if doesn't match container value
        if (this.fileIndexesToDelete.length) {
            this.fileIndexesToDelete
                .filter(
                    (fileIndex) =>
                        fileIndex !== this.initialContainerValue?.fileIndex
                )
                .forEach((fileIndex) => {
                    this.filesFacadeService.deleteFile(fileIndex);
                });
        }

        // if file index exists but inital value and current value is null, delete the file
        if (
            this.fileIndex &&
            this.initialContainerValue === null &&
            currentContainerValue === null
        ) {
            this.filesFacadeService.deleteFile(
                this.fileIndex?.fileIndex as string
            );
        }
    }

    override ngOnDestroy(): void {
        this.fileCleanup();
        super.ngOnDestroy();
    }
}
