import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ClientLifecycleStage, PartyType } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import {
    FeaturesService,
    TranslationsService,
    WdxDateFormat,
    WdxDateTimeService,
} from '@wdx/shared/utils';
import {
    ADD_TO_LIST_MENU_ITEM,
    VISIBILITY_MENU_ITEM,
} from '../../../../constants/menu.constants';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { OperationsSetup } from '../../../../models/operations.model';
import { DateComparePipe } from '../../../../pipes/date-compare.pipe';
import { ADD_TO_MENU_ITEM, ADD_TO_MENU_ITEM_FEATURE } from '../components';

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    constructor(
        private translationsService: TranslationsService,
        private dateTimeService: WdxDateTimeService,
        private featuresService: FeaturesService
    ) {}

    getContextMenuActionButton(
        operationsSetup?: OperationsSetup
    ): ActionButton {
        return {
            mode: ActionButtonMode.DropdownButtonMenu,
            cySelector: 'ellipsis-context-menu-btn',
            dropdownMenu: [
                VISIBILITY_MENU_ITEM,
                ...ADD_TO_MENU_ITEM,
                ...(this.featuresService.hasFeature(FeatureFlag.Qwil)
                    ? ADD_TO_MENU_ITEM_FEATURE
                    : []),
                ADD_TO_LIST_MENU_ITEM,
            ],
            ...(operationsSetup ? { operationsSetup } : {}),
        };
    }

    getStatusDescription(sources: any) {
        const sourcesArray = Array.isArray(sources) ? sources : [sources];

        const clientStatusOrPartyType =
            sourcesArray
                .map((source) =>
                    source?.clientStatus?.clientLifecycle ===
                    ClientLifecycleStage.Onboarding
                        ? ClientLifecycleStage.Onboarding
                        : source?.clientStatus?.clientStatus
                )
                .filter((clientStatus) => Boolean(clientStatus))[0] ||
            PartyType[sourcesArray[0].partyType];

        const inceptionDates = sourcesArray
            .map((source) => source?.inceptionDate)
            .filter((inceptionDate) => Boolean(inceptionDate));

        const earliestInceptionDate = new DateComparePipe().transform(
            inceptionDates,
            'earliest'
        );

        const label =
            this.translationsService.getTranslatedEntityType(
                clientStatusOrPartyType
            ) || new TitleCasePipe().transform(clientStatusOrPartyType);

        return [
            label,
            ...(earliestInceptionDate
                ? [
                      'since',
                      this.dateTimeService.convertDateToViewFriendlyFormat(
                          earliestInceptionDate,
                          {
                              format: WdxDateFormat.RelativeDate,
                          }
                      ),
                  ]
                : []),
        ].join(' ');
    }
}
