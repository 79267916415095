import { Action, createReducer, on } from '@ngrx/store';
import { newSystemEvent } from './system-events.actions';

export const SYSTEM_EVENTS_FEATURE_KEY = 'systemEvents';

export interface SystemEvent {
    sourceAction: Action;
    timeStamp: number;
}

export interface State {
    events: SystemEvent[];
}

export const initialState: State = {
    events: [],
};

const reducerSetup = createReducer(
    initialState,

    on(
        newSystemEvent,
        (state, props): State => ({
            events: [...state.events, props.event],
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
