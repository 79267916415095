export enum Privilege {
    Administrator = ':Administrator',
    ActivityDelete = 'Activity:Delete',
    CreateActivity = 'Activity:Create',
    CreateAdviser = 'Adviser:Create',
    CreateArticle = 'Article:Create',
    CreateCase = 'Case:Create',
    CreateIntermediary = 'Intermediary:Create',
    CreateLead = 'Lead:Create',
    CreateOpportunity = 'Opportunity:Create',
    CreateOrganisation = 'Organisation:Create',
    CreatePerson = 'Person:Create',
    CreateProductProvider = 'ProductProvider:Create',
    DeleteOpportunity = 'Opportunity:Delete',
    ExportActivity = 'Activity:Export',
    ExportAdviser = 'Adviser:Export',
    ExportCase = 'Case:Export',
    ExportClient = 'Client:Export',
    ExportIntermediary = 'Intermediary:Export',
    ExportLead = 'Lead:Export',
    ExportOpportunity = 'Opportunity:Export',
    ExportOrganisation = 'Organisation:Export',
    ExportPerson = 'Person:Export',
    ExportProductProvider = 'ProductProvider:Export',
    ImportActivity = 'Activity:Import',
    ImportAdviser = 'Adviser:Import',
    ImportCase = 'Case:Import',
    ImportClient = 'Client:Import',
    ImportIntermediary = 'Intermediary:Import',
    ImportLead = 'Lead:Import',
    ImportOpportunity = 'Opportunity:Import',
    ImportOrganisation = 'Organisation:Import',
    ImportPerson = 'Person:Import',
    ImportProductProvider = 'ProductProvider:Import',
    ImportProspect = 'Prospect:Import',
    ReadActivity = 'Activity:Read',
    ReadAdviser = 'Adviser:Read',
    ReadArticle = 'Article:Read',
    ReadCase = 'Case:Read',
    ReadClient = 'Client:Read',
    ReadClientProduct = 'ClientProduct:Read',
    ReadIntermediary = 'Intermediary:Read',
    ReadLead = 'Lead:Read',
    ReadList = 'List:Read',
    ReadOpportunity = 'Opportunity:Read',
    ReadOrganisation = 'Organisation:Read',
    ReadPerson = 'Person:Read',
    TenantAdministrator = ':TenantAdministrator',
    UpdateOpportunity = 'Opportunity:Update',
    OverrideDataLock = 'OverrideDataLock',
    ListCreate = 'List:Create',
}
