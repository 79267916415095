import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class QuerySectionsFacadeService {
    showBuilder$ = new BehaviorSubject<boolean>(false);

    toggleQueryBuilder(): void {
        this.showBuilder$.pipe(take(1)).subscribe((res) => {
            this.showBuilder$.next(!res);
        });
    }
}
