<ngx-dropzone
    #drop
    [accept]="imageUpload ? 'image/jpeg,image/jpg,image/png,image/gif' : '*'"
    [multiple]="false"
    [disableClick]="true"
    [disabled]="disabled"
    (change)="onAddFiles($event)"
>
    <ngx-dropzone-label>
        <div
            *ngIf="fileUploadPackage$ | ngrxPush; else selectFileUpload"
            data-cy="input-document"
        >
            <ng-container
                *ngIf="fileUploadStatus$ | ngrxPush; let fileUploadStatus"
            >
                <div
                    class="uploader__content--progress m-2"
                    *ngIf="
                        (fileUploadStatus$ | ngrxPush) !==
                        FILE_UPLOAD_STATUS.Failed
                    "
                >
                    <div>
                        {{
                            fileUploadStatus === FILE_UPLOAD_STATUS.Started
                                ? 'Uploading'
                                : fileUploadStatus
                        }}
                        -
                        {{ (fileUploadPackage$ | ngrxPush)?.file?.name }}
                    </div>

                    <ngb-progressbar
                        [type]="$any(progressType$ | ngrxPush)"
                        [textType]="$any(progressType$ | ngrxPush)"
                        [value]="$any(fileUploadProgress$ | ngrxPush)"
                    ></ngb-progressbar>
                </div>

                <div
                    *ngIf="
                        (fileUploadStatus$ | ngrxPush) ===
                        FILE_UPLOAD_STATUS.Failed
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="uploadFailed"
                    ></ng-container>
                </div>
            </ng-container>
        </div>

        <ng-template #selectFileUpload>
            <ng-container *ngIf="rejectedFiles"
                ><ng-container *ngTemplateOutlet="uploadFailed"></ng-container
            ></ng-container>

            <ng-container *ngIf="!rejectedFiles">
                <div class="text-center body-text-medium">
                    <div>Drag and drop your file here</div>
                    <div>or</div>
                </div>
                <div class="d-flex justify-content-center">
                    <wdx-icon-button
                        class="mx-1"
                        wdxStyle="outline-primary"
                        (wdxOnClick)="drop.showFileSelector()"
                        ><wdx-icon icon="file-upload"></wdx-icon
                    ></wdx-icon-button></div
            ></ng-container>
        </ng-template>

        <ng-template #uploadFailed>
            <div class="danger-text form-section__body__blurb sub-3 mb-2">
                File upload failed.
            </div>

            <div class="uploader__content--progress mb-2">
                <ngb-progressbar
                    type="danger"
                    textType="danger"
                    [value]="100"
                ></ngb-progressbar>
            </div>

            <button
                class="btn btn-ghost ms-1 me-1"
                (click)="drop.showFileSelector()"
            >
                Retry
            </button>

            <button class="btn btn-ghost ms-1" (click)="onCancel()">
                Cancel
            </button>
        </ng-template>
    </ngx-dropzone-label>
</ngx-dropzone>
