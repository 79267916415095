import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxIsLoadingComponent } from './components/wdx-is-loading/wdx-is-loading.component';
import { WdxIsLoadingDirective } from './wdx-is-loading.directive';

@NgModule({
    declarations: [WdxIsLoadingComponent, WdxIsLoadingDirective],
    imports: [CommonModule, WdxSpinnerModule],
    exports: [WdxIsLoadingDirective],
})
export class WdxIsLoadingModule {}
