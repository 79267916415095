import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import {
    LocaleFacadeService,
    PersonalSettingsService,
    SystemApiService,
} from '@wdx/clmi/api-services/services';
import {
    FeaturesService,
    PERSONAL_SETTINGS_KEYS,
    TranslationsService,
    WdxAppThemeService,
} from '@wdx/shared/utils';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigService } from '../../../services/config.service';
import { SystemSettingsService } from '../../../shared/services';
import * as rootReducer from '../../../state/_setup/reducers';
import * as globalActions from '../../../state/global/global.actions';
import { GuardUserInfoService } from '../../services/guard-user-info';
import { NavigationService } from '../../services/navigation/navigation.service';

@Injectable({
    providedIn: 'root',
})

/**
 * Guard that is run once on the top level App component
 */
export class AppGuard implements CanActivate {
    private router = inject(Router);
    private store$ = inject(Store<rootReducer.State>);
    private authenticationService = inject(AuthenticationService);
    private configService = inject(ConfigService);
    private systemApiService = inject(SystemApiService);
    private wdxAppThemeService = inject(WdxAppThemeService);
    private personalSettingsService = inject(PersonalSettingsService);
    private guardUserInfoService = inject(GuardUserInfoService);
    private systemSettingsService = inject(SystemSettingsService);
    private featuresService = inject(FeaturesService);
    private translationsService = inject(TranslationsService);
    private localeFacadeService = inject(LocaleFacadeService);
    private navigationService = inject(NavigationService);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.isAuthenticated$.pipe(
            switchMap((isAuthenticated) => {
                /**
                 * Set isLoggedIn status on AuthenticationService
                 */
                this.authenticationService.loggedIn = isAuthenticated;

                /**
                 * If user not authenticated redirect to welcome page and restrict access to app
                 */
                if (!isAuthenticated) {
                    this.router.navigate(['/'], {
                        queryParams: {
                            ['return_to']: state.url,
                        },
                    });

                    return of(false);
                }

                /**
                 * Handles redirectTo url in the background (if applicable)
                 * Get appstate will only be subscribed to on a successfull login callback
                 */
                this.authenticationService
                    .getAppState$()
                    .pipe(
                        take(1),
                        filter((appState) => Boolean(appState.returnToUrl))
                    )
                    .subscribe((appState) =>
                        this.authenticationService.redirectToUrl(
                            appState.returnToUrl
                        )
                    );

                return this.guardUserInfoService
                    .setTenantAndGetUser(route.params?.['tenant'])
                    .pipe(
                        //  loads eager apis (those required to start the application)
                        switchMap(() =>
                            forkJoin({
                                features: this.systemApiService.getFeatures(),
                                sitemap: this.systemApiService.getSiteMap(),
                                settings:
                                    this.systemApiService.getSystemSettings(),
                                theme: this.systemApiService.getTheme(),
                                translations:
                                    this.systemApiService.getTranslations(),
                                locales:
                                    this.localeFacadeService.loadLocales$(),
                                personalSetting:
                                    this.personalSettingsService.getSettings(),
                            })
                        ),
                        tap((res) => {
                            // set the eager responses on relevant core services
                            this.systemSettingsService.set(res.settings);
                            this.featuresService.set(res.features);
                            const themeSetting =
                                res.personalSetting?.[
                                    PERSONAL_SETTINGS_KEYS.theme
                                ] || undefined;
                            this.wdxAppThemeService.set(
                                res.theme,
                                themeSetting
                            );
                            this.translationsService.set(res.translations);
                            this.navigationService.setSiteMap(res.sitemap);

                            // initialise lazy global actions
                            this.store$.dispatch(
                                globalActions.initialiseApp({ isAuthenticated })
                            );
                        }),

                        // Return to app
                        map(() => true)
                    );
            })
        );
    }

    get isAuthenticated$() {
        return this.configService?.config?.TestMode
            ? of(true)
            : this.authenticationService.auth0Service.isAuthenticated$;
    }
}
