import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';

import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { InterpolationDisplayComponent } from './interpolation-display.component';

@NgModule({
    imports: [CommonModule, RouterModule, PipesModule, WdxButtonModule],
    declarations: [InterpolationDisplayComponent],
    exports: [InterpolationDisplayComponent],
})
export class InterpolationDisplayModule {}
