import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import {
    FileUploadPackage,
    FileUploadStatus,
    FilesFacadeService,
} from '@wdx/shared/infrastructure/file-io';
import {
    FileIndex,
    RandomStringPipe,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { NgxDropzoneChangeEvent, NgxDropzoneModule } from 'ngx-dropzone';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'wdx-file-upload',
    templateUrl: './wdx-file-upload.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgbProgressbarModule,
        NgxDropzoneModule,
        PushModule,
        WdxIconButtonModule,
        WdxIconModule,
    ],
})
export class WdxFileUploadComponent extends WdxDestroyClass {
    @Input() disabled = false;
    @Input() imageUpload = false;
    @Input() customEndpoint!: string | undefined;

    @Output() fileUploaded = new EventEmitter<FileIndex>();

    fileUploadStatus$?: Observable<FileUploadStatus>;
    fileUploadProgress$?: Observable<number>;
    fileUploadPackage$ = new BehaviorSubject<FileUploadPackage | null>(null);

    progressType$ = new BehaviorSubject<string | null>(null);
    rejectedFiles = false;

    readonly FILE_UPLOAD_STATUS = FileUploadStatus;

    constructor(private filesFacadeService: FilesFacadeService) {
        super();
    }

    onAddFiles(event: NgxDropzoneChangeEvent) {
        if (event.rejectedFiles.length) {
            this.rejectedFiles = true;
            return;
        }
        const fileUploadPackage = {
            id: new RandomStringPipe().transform(),
            file: event.addedFiles[0],
        };

        this.filesFacadeService.loadFileIndex(
            fileUploadPackage,
            this.customEndpoint
        );

        this.filesFacadeService
            .getFileUploadFileIndex$(fileUploadPackage)
            .pipe(
                filter((fileIndex) => Boolean(fileIndex)),
                take(1)
            )
            .subscribe((fileIndex) => this.fileUploaded.emit(fileIndex));

        this.fileUploadStatus$ = this.filesFacadeService
            .getFileUploadStatus$(fileUploadPackage)
            .pipe(
                takeUntil(this.destroyed$),
                tap((fileUploadStatus) => {
                    this.progressType$.next(
                        this.getProgressType(fileUploadStatus)
                    );
                })
            );

        this.fileUploadProgress$ = this.filesFacadeService
            .getFileUploadProgress$(fileUploadPackage)
            .pipe(takeUntil(this.destroyed$));

        this.fileUploadPackage$.next(fileUploadPackage);
    }

    getProgressType(fileUploadStatus: FileUploadStatus) {
        if (fileUploadStatus) {
            return {
                [FileUploadStatus.Ready]: 'success',
                [FileUploadStatus.Requested]: 'success',
                [FileUploadStatus.Started]: 'success',
                [FileUploadStatus.Failed]: 'danger',
                [FileUploadStatus.Completed]: 'success',
            }[fileUploadStatus];
        }
        return null;
    }

    onCancel() {
        this.fileUploadPackage$.next(null);
        this.rejectedFiles = false;
    }
}
