<widget-articles-list
    widgetTitle="Articles"
    [totalRecordCount]="(relatedArticles$ | ngrxPush)?.length"
    [articlesPerRow]="cardsPerRow"
    [articleListItems]="relatedArticles$ | ngrxPush"
    [isLoading]="relatedArticlesIsLoading$ | ngrxPush"
    [hasError]="relatedArticlesHasError$ | ngrxPush"
    [spokeButton]="{ label: 'See all articles' }"
    [showArticleImage]="false"
    [showLinkButton]="true"
    [showArticlesListActionsButtons]="true"
    [responsiveScroll]="true"
    [linkToEntityType]="entityType"
    [linkToEntityId]="entityId"
>
    <div class="articles-list-action-buttons d-flex align-items-center">
        <molecule-action-button
            class="me-2"
            [actionButton]="articlesSearchActionButton"
            [id]="articlesSearchActionButton.id"
        ></molecule-action-button>
    </div>
</widget-articles-list>

<molecule-accordion
    name="suggestedArticleAccordion"
    [panels]="suggestedArticlesAccordionPanels$ | ngrxPush"
    [flush]="true"
></molecule-accordion>

<ng-template #suggestedArticlesContentTemplate>
    <div class="accordion-content">
        <div class="data-list-content">
            <div
                *ngIf="(suggestedArticles$ | ngrxPush)?.length; else noRecords"
                class="data-list-content__article-row grid"
            >
                <ng-container
                    *ngFor="let article of suggestedArticles$ | ngrxPush"
                >
                    <molecule-article-card
                        class="data-list-content__card-container suggested-article-list-item"
                        [responsiveColumns]="{ staticColumns: cardsPerRow }"
                        [showArticleImage]="false"
                        [article]="article"
                        [showLinkButton]="true"
                        [isLinked]="false"
                        [linkToEntityType]="entityType"
                        [linkToEntityId]="entityId"
                    ></molecule-article-card>
                </ng-container>
            </div>
            <ng-template #noRecords>
                <div class="d-flex align-items-center justify-content-center">
                    <atom-feature-svg size="xs" [svg]="NO_DATA_SVG">
                        {{ NO_DATA_MESSAGE }}
                    </atom-feature-svg>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>

<atom-modal-wrapper
    size="lg"
    [modalId]="SEARCH_ARTICLES_MODAL_ID"
    [modalTemplate]="searchArticlesModal"
>
    <ng-template #searchArticlesModal>
        <organism-modal modalTitle="Search Articles">
            <organism-articles-list
                [listStyle]="LIST_STYLE.Grid"
                [articlesPerRow]="2"
                [showFilters]="false"
                [showFilterBar]="true"
                [showLinkButton]="true"
                [isSuggestedCardList]="true"
                [linkToEntityType]="entityType"
                [linkToEntityId]="entityId"
            ></organism-articles-list>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
