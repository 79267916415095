import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import {
    UTILS_ICON_WARNING,
    WdxMarkdownToHtmlPipe,
    WdxSafeHtmlPipe,
} from '@wdx/shared/utils';
import { ValidationSummaryError, ValidationSummarySection } from '../../models';

@Component({
    selector: 'wdx-ff-validation-summary',
    templateUrl: './form-validation-summary.component.html',
    styleUrls: ['./form-validation-summary.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
        WdxIconModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormValidationSummaryComponent {
    @Input() message!: string;
    @Input() validationSummary!:
        | ValidationSummarySection
        | ValidationSummarySection[];

    @Output() errorClicked = new EventEmitter<ValidationSummaryError>();

    readonly ICON_WARNING = UTILS_ICON_WARNING;

    onErrorClicked($event: ValidationSummaryError) {
        this.errorClicked.emit($event);
    }

    get isArrayOfSections(): boolean {
        return this.validationSummary
            ? Array.isArray(this.validationSummary)
            : false;
    }
}
