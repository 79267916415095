<ul
    *ngIf="isVisible && activity"
    class="engagement-bar nav nav-tabs nav-flush flex-column text-center bg-body shadow card border-0"
>
    <li
        class="nav-item border-bottom"
        ngbPopover="Close"
        [triggers]="popUpTriggers.triggers"
        [container]="popUpTriggers.container"
        [openDelay]="popUpTriggers.openDelay"
        [closeDelay]="popUpTriggers.closeDelay"
    >
        <button class="nav-link" (click)="onCloseClick()">
            <wdx-icon [scale]="2" icon="times"></wdx-icon>
        </button>
    </li>

    <li
        class="nav-item"
        ngbPopover="Back to Activity"
        [triggers]="popUpTriggers.triggers"
        [container]="popUpTriggers.container"
        [openDelay]="popUpTriggers.openDelay"
        [closeDelay]="popUpTriggers.closeDelay"
    >
        <a
            class="nav-link"
            data-cy="btn-phone-engagement-bar"
            [routerLink]="routers"
            [queryParams]="{ activityId: activity?.id }"
        >
            <ng-container [ngSwitch]="activity.activityType">
                <ng-container *ngSwitchCase="ACTIVITY_TYPE.Glia">
                    <svg class="icon-size-{{ ICON_SIZE }}">
                        <svg:use
                            xlink:href="assets/images/svg/glia.svg#glia"
                        ></svg:use>
                    </svg>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <wdx-icon
                        [scale]="2"
                        [icon]="ACTIVITY_ICON_MAP[activity?.activityType]"
                    >
                    </wdx-icon>
                </ng-container>
            </ng-container>
        </a>
    </li>

    <li
        class="nav-item border-top border-bottom"
        *ngIf="activity.activityType !== ACTIVITY_TYPE.WorkItem"
        ngbPopover="Related party"
        [triggers]="popUpTriggers.triggers"
        [container]="popUpTriggers.container"
        [openDelay]="popUpTriggers.openDelay"
        [closeDelay]="popUpTriggers.closeDelay"
    >
        <a
            class="nav-link align-items-center"
            [routerLink]="SYSTEM_ENTITY.Party | entityRoute : party?.id"
        >
            <div class="position-relative">
                <molecule-avatar
                    class="d-block"
                    [size]="ICON_SIZE"
                    [avatar]="party | partyToAvatar"
                    [isAvatarInfoEnabled]="false"
                ></molecule-avatar>
                <div
                    *ngIf="engagementState"
                    class="badge d-block position-absolute mt-n1 me-n1 p-2 rounded-circle top-0 end-0"
                    [ngClass]="
                        'badge-' +
                        ENGAGEMENT_STATE_HIGHLIGHT_MAP[engagementState]
                    "
                ></div>
            </div>
            <div *ngIf="engagementState" class="small mt-2">
                {{ ENGAGEMENT_STATE_STATUS_MAP[engagementState] }}
            </div>
        </a>
    </li>

    <ng-container
        *ngFor="
            let button of activity
                | fn
                    : buttons
                    : this
                    : relatedActivitiesCount
                    : relatedArticlesCount
                    : relatedCasesCount
                    : relatedOpportunitiesCount
                    : featuresService
        "
    >
        <ng-container *ngIf="!button.feature || (button.feature | hasFeature)">
            <ng-container
                *ngTemplateOutlet="
                    button.menu?.length
                        ? engagementButtonWithMenu
                        : button.clickEvent
                        ? engagementButtonClick
                        : engagementButton;
                    context: { button: button }
                "
            ></ng-container>

            <ng-template #engagementButtonWithMenu let-button="button">
                <li
                    ngbDropdown
                    class="nav-item"
                    placement="left-top"
                    container="body"
                >
                    <button
                        ngbDropdownToggle
                        class="nav-link"
                        [id]="button.id"
                        [attr.data-cy]="button.id"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                engagementButtonContent;
                                context: { button: button }
                            "
                        ></ng-container>
                    </button>

                    <div ngbDropdownMenu [attr.aria-labelledby]="button.id">
                        <molecule-context-menu
                            [menu]="button.menu"
                        ></molecule-context-menu>
                    </div>
                </li>
            </ng-template>

            <ng-template #engagementButton let-button="button">
                <li
                    class="nav-item"
                    [ngbPopover]="button.label"
                    [triggers]="popUpTriggers.triggers"
                    [container]="popUpTriggers.container"
                    [openDelay]="popUpTriggers.openDelay"
                    [closeDelay]="popUpTriggers.closeDelay"
                >
                    <button
                        class="nav-link"
                        [id]="button.id"
                        [attr.data-cy]="button.id"
                        [modalId]="button.modalId"
                        [dynamicFormSetup]="button.formSetup"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                engagementButtonContent;
                                context: { button: button }
                            "
                        ></ng-container>
                    </button>
                </li>
            </ng-template>

            <ng-template #engagementButtonClick let-button="button">
                <li
                    class="nav-item"
                    [ngbPopover]="button.label"
                    [triggers]="popUpTriggers.triggers"
                    [container]="popUpTriggers.container"
                    [openDelay]="popUpTriggers.openDelay"
                    [closeDelay]="popUpTriggers.closeDelay"
                >
                    <button
                        class="nav-link"
                        [id]="button.id"
                        [attr.data-cy]="button.id"
                        (click)="buttonClicked(button?.clickEvent)"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                engagementButtonContent;
                                context: { button: button }
                            "
                        ></ng-container>
                    </button>
                </li>
            </ng-template>
        </ng-container>
    </ng-container>

    <ng-container
        *ngIf="activity && activity.status !== ACTIVITY_STATUS_TYPE.Complete"
    >
        <li
            *ngIf="activity.activityType !== ACTIVITY_TYPE.WorkItem"
            class="nav-item border-top"
            ngbPopover="Complete"
            [triggers]="popUpTriggers.triggers"
            [container]="popUpTriggers.container"
            [openDelay]="popUpTriggers.openDelay"
            [closeDelay]="popUpTriggers.closeDelay"
        >
            <button
                data-cy="btn-completed-engagement-bar"
                class="nav-link text-success"
                (click)="onCompleteClicked($event)"
            >
                <wdx-icon icon="check-circle" [scale]="2"> </wdx-icon>
            </button>
        </li>

        <li
            *ngIf="activity.activityType === ACTIVITY_TYPE.WorkItem"
            class="nav-item border-top"
        >
            <div
                ngbDropdown
                class="nav-item"
                placement="left-top"
                container="body"
            >
                <button
                    ngbDropdownToggle
                    class="nav-link"
                    id="outcome"
                    [attr.data-cy]="'outcome'"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            engagementButtonContent;
                            context: { button: workItemMenuButton }
                        "
                    ></ng-container>
                </button>

                <div ngbDropdownMenu [attr.aria-labelledby]="'outcome'">
                    <molecule-context-menu
                        [menu]="workItemMenuOutComes"
                        (singleItemSelected)="onOutcomesClicked($event)"
                    ></molecule-context-menu>
                </div>
            </div>
        </li>
    </ng-container>
</ul>

<ng-template #engagementButtonContent let-button="button">
    <div class="position-relative d-flex flex-column align-items-center">
        <wdx-icon [scale]="1.75" [icon]="button.icon"></wdx-icon>
        <div
            *ngIf="button.count"
            class="badge d-block position-absolute mt-n3 me-n2 rounded-circle top-0 end-0 badge-info"
        >
            {{ button.count }}
        </div>
    </div>
</ng-template>

<atom-modal-wrapper
    size="lg"
    [modalId]="ENGAGEMENT_INFORMATION_MODAL_ID"
    [modalTemplate]="engagementInformationModal"
>
    <ng-template #engagementInformationModal>
        <organism-modal modalTitle="Activity Information">
            <div class="d-flex h-100 flex-column overflow-hidden">
                <div class="d-flex flex-grow-1 flex-column overflow-hidden">
                    <molecule-activity-header
                        class="d-block pt-3 px-3 widget-padding bg-muted"
                        [activity]="activity"
                    ></molecule-activity-header>
                    <div class="gutter-top flex-grow-1 overflow-hidden">
                        <div
                            class="activity__content-container h-100 overflow-auto"
                        >
                            <organism-form-summary
                                [formId]="
                                    ACTIVITY_FORM_ID_MAP[activity?.activityType]
                                "
                                [entityId]="activity?.id"
                            ></organism-form-summary>
                        </div>
                    </div>
                </div>
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

<atom-modal-wrapper
    size="lg"
    [modalId]="RELATED_RECORDS_MODAL_ID"
    [modalTemplate]="relatedRecordsModal"
>
    <ng-template #relatedRecordsModal>
        <organism-modal modalTitle="Related Records">
            <div class="widget-padding h-100 overflow-auto">
                <clmi-related-records
                    [entityId]="activity?.id"
                    [entityType]="SYSTEM_ENTITY.Activity"
                    [showRelatedCases]="true"
                    [showSuggestedCases]="true"
                    [showOpportunities]="
                        featuresService.hasFeature(FeatureFlag.Pipeline)
                    "
                    [showArticles]="true"
                    [showActivities]="true"
                    [cardsPerRow]="2"
                ></clmi-related-records>
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

<atom-modal-wrapper
    [modalId]="ADD_NEW_ACTIVITY_MODAL_ID"
    [modalTemplate]="addNewActivityModal"
>
    <ng-template #addNewActivityModal>
        <organism-modal modalTitle="Activity Type">
            <organism-activity-template-search
                [entityType]="SYSTEM_ENTITY.Activity"
                [entityId]="activity?.id"
            ></organism-activity-template-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

<atom-modal-wrapper
    [modalId]="ADD_NEW_CASE_MODAL_ID"
    [modalTemplate]="addNewCaseModal"
>
    <ng-template #addNewCaseModal>
        <organism-modal modalTitle="Case Type">
            <organism-case-template-search
                [activityType]="activity?.activityType"
                [activityId]="activityId"
                [clientId]="activity?.regarding[0]?.id"
            ></organism-case-template-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

<atom-modal-wrapper
    size="lg"
    [modalId]="ARTICLES_MODAL_ID"
    [modalTemplate]="articlesModal"
>
    <ng-template #articlesModal>
        <organism-modal modalTitle="Articles">
            <organism-articles-list
                *ngIf="activityId"
                [listStyle]="LIST_STYLE.Grid"
                [articlesPerRow]="2"
                [showFilters]="false"
                [showFilterBar]="true"
                [resetFilters]="true"
                [isSuggestedCardList]="true"
                [showLinkButton]="true"
                [linkToEntityType]="SYSTEM_ENTITY.Activity"
                [linkToEntityId]="activityId"
            ></organism-articles-list>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
