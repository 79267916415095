import { Action, createReducer, on } from '@ngrx/store';
import { AppLanguage, Version } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { Build } from '@wdx/clmi/api-services/services';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as systemActions from './system.actions';

export interface State {
    version?: CrudStateObject<Version>;
    build?: CrudStateObject<Build>;
    appLanguages?: CrudState<AppLanguage>;
}

export const initialState: State = {
    version: {},
    build: {},
    appLanguages: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        systemActions.getVersion,
        (state): State => ({
            ...state,
            version: {
                ...state.version,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.version[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Version>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        systemActions.getVersionSuccess,
        (state, props): State => ({
            ...state,
            version: {
                ...state.version,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.version[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.version,
                },
            },
        })
    ),

    on(
        systemActions.getVersionFailure,
        (state): State => ({
            ...state,
            version: {
                ...state.version,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.version[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        systemActions.getBuild,
        (state): State => ({
            ...state,
            build: {
                ...state.build,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.build[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Build>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        systemActions.getBuildSuccess,
        (state, props): State => ({
            ...state,
            build: {
                ...state.build,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.build[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.build,
                },
            },
        })
    ),

    on(
        systemActions.getBuildFailure,
        (state): State => ({
            ...state,
            build: {
                ...state.build,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.build[GLOBAL_STATE_INDEX_ID],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        systemActions.getAppLanguages,
        (state): State => ({
            ...state,
            appLanguages: {
                ...state.appLanguages,
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        systemActions.getAppLanguagesSuccess,
        (state, props): State => ({
            ...state,
            appLanguages: {
                ...state.appLanguages,
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.applicationLanguages,
            },
        })
    ),

    on(
        systemActions.getAppLanguagesFailure,
        (state): State => ({
            ...state,
            appLanguages: {
                ...state.appLanguages,
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
