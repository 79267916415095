import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ExtractPdfService } from '../extract-pdf/extract-pdf.service';
import * as investorRiskProfileActions from './investor-risk-profile.actions';

@Injectable()
export class InvestorRiskProfileEffects {
    private actions$ = inject(Actions);
    private extractPdfService = inject(ExtractPdfService);
    private toastService = inject(WdxToastService);

    extractPdfFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investorRiskProfileActions.extractPdfFile),
            mergeMap((action) =>
                this.extractPdfService
                    .extractPdf(action.file, action.entityId, action.formName)
                    .pipe(
                        map(() =>
                            investorRiskProfileActions.extractPdfFileSuccess()
                        ),
                        catchError((error) =>
                            of(
                                investorRiskProfileActions.extractPdfFileError({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    extractPdfFileSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(investorRiskProfileActions.extractPdfFileSuccess),
                tap(() => {
                    this.toastService.show({
                        body: ['The file is parsed successfully'],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    extractPdfFileError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investorRiskProfileActions.extractPdfFileError),
            tap((error) => {
                this.toastService.show({
                    body: [error.error.message],
                    severity: Severity.Danger,
                });
            })
        )
    );
}
