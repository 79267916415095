import { Action, createReducer, on } from '@ngrx/store';
import { Salutation } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/shared/utils';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as salutationActions from './salutation.actions';

export interface State {
    salutation: CrudStateObject<Salutation>;
    query?: CrudState<Salutation>;
}

export const initialState: State = {
    salutation: {},
    query: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        salutationActions.getSalutation,
        (state, props): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.salutation[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Salutation>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props?.reset
                        ? null
                        : state.salutation[GLOBAL_STATE_INDEX_ID]?.infinity,
                },
            },
        })
    ),

    on(
        salutationActions.getSalutationSuccess,
        (state, props): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.salutation[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.salutation.paging,
                        combinedList: props.salutation.results,
                    },
                },
            },
        })
    ),

    on(
        salutationActions.getSalutationFailure,
        (state): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.salutation[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        salutationActions.updateSalutation,
        (state): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.salutation[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Salutation>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        })
    ),

    on(
        salutationActions.updateSalutationSuccess,
        (state, props): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.salutation[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.payload.paging,
                        combinedList: [
                            ...(state.salutation[GLOBAL_STATE_INDEX_ID].infinity
                                ?.combinedList || []),
                            ...props.payload.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        salutationActions.updateSalutationFailure,
        (state): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.salutation[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        salutationActions.querySalutation,
        (state): State => ({
            ...state,
            query: {
                ...(state.query || ({} as CrudState<Salutation>)),
                isLoadingPage: true,
                hasLoadingPageError: false,
            },
        })
    ),

    on(
        salutationActions.querySalutationSuccess,
        (state, props): State => ({
            ...state,
            query: {
                ...state.query,
                isLoadingPage: false,
                hasLoadingPageError: false,
                infinity: {
                    paging: props.salutation.paging,
                    combinedList: props.salutation.results,
                },
            },
        })
    ),

    on(
        salutationActions.querySalutationFailure,
        (state): State => ({
            ...state,
            query: {
                ...state.query,
                isLoadingPage: false,
                hasLoadingPageError: true,
            },
        })
    ),

    on(
        salutationActions.clearQuery,
        (state): State => ({
            ...state,
            query: {
                isLoadingPage: false,
                hasLoadingPageError: false,
                infinity: null,
            },
        })
    ),

    on(
        salutationActions.getSalutationById,
        (state, props): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [props.id]: {
                    ...(state.salutation[props.id] ||
                        ({} as CrudStateObject<Salutation>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(salutationActions.getSalutationByIdSuccess, (state, props): State => {
        return {
            ...state,
            salutation: {
                ...state.salutation,
                [props.id]: {
                    ...state.salutation[props.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.salutation,
                },
            },
        };
    }),

    on(
        salutationActions.getSalutationByIdFailure,
        (state, props): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [props.id]: {
                    ...state.salutation[props.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        salutationActions.disableSalutation,
        (state): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.salutation[GLOBAL_STATE_INDEX_ID],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        salutationActions.disableSalutationSuccess,
        (state, props): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.salutation[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Salutation>)),
                    infinity: {
                        ...state.salutation[GLOBAL_STATE_INDEX_ID].infinity,
                        combinedList: state.salutation[
                            GLOBAL_STATE_INDEX_ID
                        ]?.infinity?.combinedList.map((item: Salutation) =>
                            item.id === props.id
                                ? props.updatedSalutation
                                : item
                        ),
                    },
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        salutationActions.disableSalutationFailure,
        (state): State => ({
            ...state,
            salutation: {
                ...state.salutation,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.salutation[GLOBAL_STATE_INDEX_ID],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
