import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';

import { PushModule } from '@ngrx/component';

import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { MoleculeInputLookupModule } from '../../../components/molecules/molecule-input-lookup/molecule-input-lookup.module';

import { WdxAccordionModule } from '@wdx/shared/components/wdx-accordion';
import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { WdxCounterModule } from '@wdx/shared/components/wdx-counter';
import { WdxDialogComponent } from '@wdx/shared/components/wdx-dialog';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { TeamMembersComponent } from './team-members.component';

@NgModule({
    declarations: [TeamMembersComponent],
    imports: [
        CommonModule,
        MoleculeInfoCardModule,
        AtomIconButtonModule,
        MoleculeActionButtonModule,
        MoleculeInputLookupModule,
        PushModule,
        AtomAsyncDataModule,
        AtomFeatureSvgModule,
        WdxAccordionModule,
        WdxCounterModule,
        WdxDialogComponent,
        WdxButtonModule,
        WdxIconModule,
        WdxIconContainerModule,
    ],
    exports: [TeamMembersComponent],
})
export class TeamMembersModule {}
