import { Injectable, inject } from '@angular/core';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { RouterStateAndUrl } from './custom-route-serializer';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import {
    selectQueryParam,
    selectQueryParams,
    selectRouteData,
    selectRouteDataParam,
    selectRouteParam,
    selectRouteParams,
    selectUrl,
} from './router.selector';

@Injectable({ providedIn: 'root' })
export class RouteFacade {
    private router = inject(Router);
    protected route = inject(ActivatedRoute);

    private store = inject(Store) as Store<{
        router: RouterReducerState<RouterStateAndUrl>;
    }>;

    getRouteParams$(): Observable<Params> {
        return this.store.select(selectRouteParams);
    }

    getRouteParamField$(field: string): Observable<string | undefined> {
        return this.store.select(selectRouteParam(field));
    }

    getRouteData$(): Observable<Data> {
        return this.store.select(selectRouteData);
    }

    getRouteDataField$<T>(field: string): Observable<T> {
        return this.store.select(selectRouteDataParam(field)) as Observable<T>;
    }

    getUrl$(): Observable<string> {
        return this.store.select(selectUrl);
    }

    getQueryParams$(): Observable<Params> {
        return this.store.select(selectQueryParams);
    }

    getQueryParam$(field: string): Observable<string | undefined> {
        return this.store.select(selectQueryParam(field));
    }

    updateQueryParam(updatedQueryParams: Record<string, string>): void {
        this.getQueryParams$()
            .pipe(take(1))
            .subscribe((queryParams) => {
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        ...queryParams,
                        ...updatedQueryParams,
                    },
                    queryParamsHandling: 'merge',
                });
            });
    }
}
