import { createSelector } from '@ngrx/store';
import * as viewsReducer from './views.reducer';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';

/**
 * Reducer state selector
 */
export const getState = (state: {
    views: viewsReducer.State;
}): viewsReducer.State => state.views;

/**
 * Reducer property selectors
 */
export const getIsLoadingAll = createSelector(
    getState,
    (state: viewsReducer.State) =>
        state.views[GLOBAL_STATE_INDEX_ID].isLoadingList
);
export const getHasLoadingAllError = createSelector(
    getState,
    (state: viewsReducer.State) =>
        state.views[GLOBAL_STATE_INDEX_ID].hasLoadingListError
);
export const getAll = createSelector(getState, (state: viewsReducer.State) =>
    Object.keys(state.views).reduce(
        (accumulator, view) => [...accumulator, ...state.views[view].list],
        []
    )
);
export const getAllByEntity = createSelector(
    getState,
    (state: viewsReducer.State) =>
        Object.keys(state.views).reduce(
            (accumulator, view) => ({
                ...accumulator,
                ...(state.views[view]?.list?.length
                    ? {
                          [view]: state.views[view].list,
                      }
                    : {}),
            }),
            {}
        )
);

export const getIsLoadingList = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.list
);

export const getDefaultView = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.defaultView[props.entityType]?.single
);
export const getIsLoadingSingleDefaultView = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.defaultView[props.entityType]?.isLoadingSingle
);
export const getHasLoadingSingleDefaultViewError = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.defaultView[props.entityType]?.hasLoadingSingleError
);

export const getIsCreating = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.isCreating
);

export const getHasCreatingError = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.hasCreatingError
);

export const getIsUpdating = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.isUpdating
);

export const getHasUpdatingError = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.hasUpdatingError
);

export const getIsDeleting = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.isDeleting
);

export const getHasDeletingError = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.views[props.entityType]?.hasDeletingError
);

export const getCurrentView = createSelector(
    getState,
    (state: viewsReducer.State) => state.currentView
);

export const getInfinityPaging = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.search[props.id]?.infinity?.paging
);

export const getInfinityCombinedList = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        state.search[props.id]?.infinity?.combinedList
);

export const getIsLoadingListForIds = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        props.ids.some((id) => state.search[id]?.isLoadingPage)
);

export const getHasLoadingListErrorForIds = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        props.ids.some((id) => state.search[id]?.hasLoadingPageError)
);

export const getListForIds = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.search[id]?.infinity?.combinedList || [],
            }),
            {}
        )
);

export const getPagingForIds = createSelector(
    getState,
    (state: viewsReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.search[id]?.infinity?.paging || {},
            }),
            {}
        )
);
