import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuerySectionsFacadeService } from './shared/services/query-sections-facade/query-sections-facade.service';

@Component({
    selector: 'clmi-form-filters',
    templateUrl: './form-filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [QuerySectionsFacadeService],
})
export class FormFiltersComponent extends WdxDestroyClass {
    get isQueryBuilderVisible$(): Observable<boolean> {
        return this.querySectionsFacadeService.showBuilder$.pipe(
            takeUntil(this.destroyed$)
        );
    }

    constructor(
        private querySectionsFacadeService: QuerySectionsFacadeService
    ) {
        super();
    }
}
