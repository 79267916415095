import {
    Component,
    ComponentFactoryResolver,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    EntityPermissionType,
    KanbanType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import {
    MenuItemAction,
    NavItem,
    OPPORTUNITY_FORM_ID,
    OPPORTUNITY_LOST_FORM_ID,
    OPPORTUNITY_WON_FORM_ID,
    TenantsService,
} from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BaseComponentClass } from '../../../classes/base-component.class';
import { OrganismCreateCaseModalComponent } from '../../../components/organisms/organism-create-case-modal/organism-create-case-modal.component';
import {
    CREATE_CASE_MENU_ITEM,
    DELETE_MENU_ITEM,
    EDIT_MENU_ITEM,
    MOVE_TO_STAGE_MENU_ITEM,
    VIEW_CONTACT_MENU_ITEM,
    VIEW_IN_KANBAN_MENU_ITEM,
    VISIBILITY_MENU_ITEM,
} from '../../../constants/menu.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { DialogMode } from '../../../models/dialog.model';
import { ModalSize } from '../../../models/modal.model';
import { StageCode } from '../../../models/opportunities.model';
import { OpportunityCardItem } from '../../../models/opportunity-card-item.model';
import { DialogService } from '../../../services/dialog.service';
import { DynamicFormsManagementService } from '../../../services/dynamic-forms-management.service';
import { ModalManagementService } from '../../../services/modal-management.service';
import { AccessVisibilityService } from '../../../shared/features/access-visibility/shared/services/access-visibility.service';
import { AssignToService } from '../../../shared/features/assign-to/services/assign-to.service';
import * as rootReducer from '../../../state/_setup/reducers';
import * as kanbanStagesActions from '../../../state/kanban-stages/kanban-stages.actions';
import * as kanbanStagesSelectors from '../../../state/kanban-stages/kanban-stages.selectors';
import * as opportunitiesActions from '../../../state/opportunities/opportunities.actions';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-opportunity-context-menu',
    templateUrl: './molecule-opportunity-context-menu.component.html',
})
export class MoleculeOpportunityContextMenuComponent
    extends BaseComponentClass
    implements OnInit
{
    @Input() opportunity: OpportunityCardItem;
    @Input() btnClass = 'btn-outline-primary';
    @Input() isKanban: boolean;
    @Input() isSuggestedCardsList: boolean;
    @Input() showDeleteLinkMenuItem: boolean;

    actionButton: ActionButton;
    hasToggled = false;

    stagesMenu$ = new BehaviorSubject<NavItem[]>(null);

    private entityRouteService = inject(EntityRouteService);

    constructor(
        private resolver: ComponentFactoryResolver,
        private dialogService: DialogService,
        private accessVisibilityService: AccessVisibilityService,
        private assignToService: AssignToService,
        private tenantsService: TenantsService,
        dynamicFormsManagementService: DynamicFormsManagementService,
        router: Router,
        store$: Store<rootReducer.State>,
        modalManagementService: ModalManagementService
    ) {
        super();
        this.patchInjectedItems({
            resolver,
            dialogService,
            dynamicFormsManagementService,
            router,
            store$,
            modalManagementService,
        });
    }

    ngOnInit() {
        this.actionButton = {
            cySelector: `btn-to-do-oppotunity-${this.opportunity.id}`,
            operationsSetup: {
                entityType: SystemEntity.Opportunity,
                entityId: this.opportunity.id,
            },
            mode: ActionButtonMode.DropdownButtonMenu,
            dropdownMenu: [
                ...(!this.isKanban
                    ? [
                          {
                              ...VIEW_IN_KANBAN_MENU_ITEM,
                              cySelector: `option-viewInKanban-${this.opportunity.id}`,
                              routerLink: [
                                  ...this.tenantsService.TENANT_CODE,
                                  'pipeline',
                                  'kanban',
                              ],
                          },
                      ]
                    : []),
                {
                    ...VISIBILITY_MENU_ITEM,
                    cySelector: `option-visibility-${this.opportunity.id}`,
                },
                {
                    ...MOVE_TO_STAGE_MENU_ITEM,
                    cySelector: `option-moveToStage-${this.opportunity.id}`,
                    linkedMenu$: this.stagesMenu$,
                    permission: EntityPermissionType.Edit,
                },
                {
                    ...EDIT_MENU_ITEM,
                    cySelector: `option-edit-${this.opportunity.id}`,
                    formSetup: {
                        formId: OPPORTUNITY_FORM_ID,
                        entityId: this.opportunity.id,
                    },
                    permission: EntityPermissionType.Edit,
                },
                {
                    ...CREATE_CASE_MENU_ITEM,
                    cySelector: `option-createCase-${this.opportunity.id}`,
                    permission: EntityPermissionType.Edit,
                },
                {
                    ...this.assignToService.getAssignToMenuItem(
                        SystemEntity.Opportunity,
                        this.opportunity.id
                    ),
                    permission: EntityPermissionType.Edit,
                },

                {
                    ...DELETE_MENU_ITEM,
                    cySelector: `option-delete-${this.opportunity.id}`,
                    permission: EntityPermissionType.Delete,
                },
                ...(this.opportunity.primaryContact &&
                this.opportunity.primaryContact.id !==
                    this.opportunity.currentPartyId
                    ? [
                          {
                              ...VIEW_CONTACT_MENU_ITEM,
                              cySelector: `option-viewContact-${this.opportunity.id}`,
                          },
                      ]
                    : []),
            ],
        };
    }

    onDropdownMenuToggled(): void {
        if (!this.hasToggled) {
            this.store$
                .select(kanbanStagesSelectors.getList, {
                    kanbanTypeId: KanbanType.Pipeline,
                })
                .pipe(
                    tap((kanbanStages) => {
                        if (!kanbanStages) {
                            this.store$.dispatch(
                                kanbanStagesActions.getForKanbanType({
                                    kanbanTypeId: KanbanType.Pipeline,
                                })
                            );
                        }
                    }),
                    filter((kanbanStages) => !!kanbanStages)
                )
                .subscribe((kanbanStages) => {
                    this.stagesMenu$.next(
                        kanbanStages
                            .map((kanbanStage, i) => ({
                                label: kanbanStage.name,
                                value: MenuItemAction.MoveToStage,
                                data: {
                                    code: kanbanStage.code,
                                    index: i,
                                },
                            }))
                            .filter(
                                (kanbanStage) =>
                                    kanbanStage.data.code !==
                                    this.opportunity.stageCode
                            )
                    );
                });
            this.hasToggled = true;
        }
    }

    onSelectMenuItem(navItem: NavItem): void {
        switch (navItem.value) {
            case MenuItemAction.ViewInKanban:
                this.moveToKanbanAction();
                break;

            case MenuItemAction.MoveToStage:
                this.moveToStageAction(navItem.data?.code);
                break;

            case MenuItemAction.CreateCase:
                this.createCaseAction();
                break;

            case MenuItemAction.Delete:
                this.deleteOpportunityAction();
                break;

            case MenuItemAction.ViewContact:
                this.viewContactAction();
                break;

            case MenuItemAction.Visibility:
                this.accessVisibilityService.open(
                    this.opportunity.id,
                    SystemEntity.Opportunity
                );
                break;
        }
    }

    moveToKanbanAction(): void {
        this.modalManagementService.closeActiveModal();
    }

    moveToStageAction(stageCode: string): void {
        this.store$.dispatch(
            opportunitiesActions.setActiveKanbanStage({
                stageCode,
            })
        );

        if (stageCode === StageCode.Lost) {
            this.dynamicFormsManagementService.openDynamicFormModal({
                formId: OPPORTUNITY_LOST_FORM_ID,
                entityId: this.opportunity.id,
            });
            return;
        }
        if (stageCode === StageCode.Won) {
            this.dynamicFormsManagementService.openDynamicFormModal({
                formId: OPPORTUNITY_WON_FORM_ID,
                entityId: this.opportunity.id,
            });
            return;
        }
        this.store$.dispatch(
            opportunitiesActions.moveToStage({
                opportunity: this.opportunity,
                fromStage: this.opportunity.stageCode,
                toStage: stageCode,
            })
        );
    }

    createCaseAction(): void {
        const createCaseModal =
            this.modalManagementService.openModalWithComponent(
                OrganismCreateCaseModalComponent,
                {
                    size: ModalSize.Md,
                }
            );
        createCaseModal.componentInstance.opportunityId = this.opportunity.id;
    }

    deleteOpportunityAction(): void {
        this.dialogService.open({
            data: this.opportunity,
            dialogMode: DialogMode.DeleteOpportunity,
            confirmAction: opportunitiesActions.deleteOpportunity({
                opportunity: this.opportunity,
                stageCode: this.opportunity.stageCode,
            }),
        });
    }

    viewContactAction(): void {
        if (this.opportunity?.organisation) {
            this.router.navigate(
                this.entityRouteService.routeForEntity(
                    SystemEntity.Organisation,
                    this.opportunity?.organisation?.id
                )
            );
        } else {
            this.router.navigate(
                this.entityRouteService.routeForEntity(
                    SystemEntity.Party,
                    this.opportunity?.primaryContact?.id
                )
            );
        }
        this.modalManagementService.closeActiveModal();
    }
}
