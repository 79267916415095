import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MoleculeInputGroupModule } from '../molecule-input-group/molecule-input-group.module';
import { MoleculeInputWrapperModule } from '../molecule-input-wrapper/molecule-input-wrapper.module';
import { MoleculeInputSearchComponent } from './molecule-input-search.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MoleculeInputGroupModule,
        MoleculeInputWrapperModule,
    ],
    declarations: [MoleculeInputSearchComponent],
    exports: [MoleculeInputSearchComponent],
})
export class MoleculeInputSearchModule {}
