import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config, TenantsService } from '@wdx/shared/utils';
import { throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigService } from '../services/config.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private environment: ConfigService,
        private tenantsService: TenantsService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const config = this.environment.getConfiguration();

        // if the request is to the portal (e.g. retrieving config.json or assets)
        // OR the request is to retrieve a token then ignore the interception
        if (
            request &&
            (this.isPortalRequest(request.url) ||
                this.isLoginRequest(config, request.url))
        ) {
            return next.handle(request);
        }

        return this.authenticationService.getTokenSilently$().pipe(
            mergeMap((token) => {
                const tokenReq = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'x-clmi-tenant': this.tenantsService.tenantCode,
                    },
                });

                return next.handle(tokenReq);
            }),
            catchError((err) => throwError(err))
        );
    }

    private isPortalRequest(url: string): boolean {
        if (!url || (url && url.indexOf('http') === -1)) {
            return true;
        } else {
            return false;
        }
    }

    private isLoginRequest(config: Config, url: string): boolean {
        if (url && config && url.indexOf(`${config.API_BASE}/Login`) !== -1) {
            return true;
        } else {
            return false;
        }
    }
}
