import { SystemEntity } from '@wdx/clmi/api-models';

/**
 * List of error statuses that should be internally ignored
 */
export const APP_HANDLED_ERROR_CODES = [409, 422];

/**
 * The list of 'primary' error routes that should trigger a page redirect if encountered a pattern of api/party/{id}
 */
export const PRIMARY_ERROR_APIS = [
    SystemEntity.User,
    SystemEntity.Party,
    SystemEntity.Team,
    SystemEntity.Opportunity,
    SystemEntity.Client,
    SystemEntity.Case,
    SystemEntity.Activity,
    SystemEntity.List,
    SystemEntity.ClientProduct,
    SystemEntity.Article,
];
