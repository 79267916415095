import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { View } from '@wdx/clmi/api-models';
import {
    FilterHttpService,
    ViewApiService,
} from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as viewsActions from './views.actions';
import * as viewsSelectors from './views.selectors';

@Injectable()
export class ViewsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private viewsService = inject(ViewApiService);
    private filterHttpService = inject(FilterHttpService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.getAll),
            mergeMap(() =>
                this.viewsService.getAll().pipe(
                    map((views: View[]) => {
                        return viewsActions.getAllSuccess({ views });
                    }),
                    catchError((error: Error) =>
                        of(viewsActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );

    getForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.getForEntityType),
            mergeMap((action) =>
                this.viewsService.getForEntity(action.entityType).pipe(
                    map((views: View[]) => {
                        return viewsActions.getForEntityTypeSuccess({
                            entityType: action.entityType,
                            views,
                        });
                    }),
                    catchError((error: Error) =>
                        of(
                            viewsActions.getForEntityTypeFailure({
                                entityType: action.entityType,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getSingleForEntityType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.getSingleForEntityType),
            switchMap((action) =>
                this.viewsService
                    .getSingleForEntityType(action.viewId, action.entityType)
                    .pipe(
                        map((view: View) =>
                            viewsActions.getSingleForEntityTypeSuccess({
                                entityType: action.entityType,
                                view,
                            })
                        ),
                        catchError((error: Error) =>
                            of(
                                viewsActions.getSingleForEntityTypeFailure({
                                    entityType: action.entityType,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    createByEntityType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.createByEntityType),
            switchMap((action) =>
                this.viewsService.create(action.entityType, action.view).pipe(
                    map((view: View) =>
                        viewsActions.createByEntityTypeSuccess({
                            entityType: action.entityType,
                            view: view,
                        })
                    ),
                    catchError((error) =>
                        of(
                            viewsActions.createByEntityTypeFailure({
                                entityType: action.entityType,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateByEntityType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.updateByEntityType),
            switchMap((action) =>
                this.viewsService
                    .update(action.viewId, action.entityType, action.view)
                    .pipe(
                        map((view: View) =>
                            viewsActions.updateByEntityTypeSuccess({
                                entityType: action.entityType,
                                view,
                            })
                        ),
                        catchError((error) =>
                            of(
                                viewsActions.updateByEntityTypeFailure({
                                    entityType: action.entityType,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteByEntityType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.deleteByEntityType),
            switchMap((action) =>
                this.viewsService
                    .deleteView(action.viewId, action.entityType)
                    .pipe(
                        map(() =>
                            viewsActions.deleteByEntityTypeSuccess({
                                entityType: action.entityType,
                                viewId: action.viewId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                viewsActions.deleteByEntityTypeFailure({
                                    entityType: action.entityType,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewsActions.getViewFilterSearch),
            mergeMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store$.select(viewsSelectors.getInfinityPaging, {
                            id: action.viewId,
                        })
                    ),
                    mergeMap(([action, paging]) =>
                        this.filterHttpService
                            .getRecords(
                                action.entityType,
                                {
                                    pageNumber: action.reset
                                        ? 1
                                        : (paging?.page || 0) + 1,
                                    pageSize: action.pageSize,
                                },
                                action.filter
                            )
                            .pipe(
                                map((search) =>
                                    viewsActions.getViewFilterSearchSuccess({
                                        search,
                                        viewId: action.viewId,
                                    })
                                ),
                                catchError((error) =>
                                    of(
                                        viewsActions.getViewFilterSearchFailure(
                                            {
                                                viewId: action.viewId,
                                                error,
                                            }
                                        )
                                    )
                                )
                            )
                    )
                )
            )
        )
    );
}
