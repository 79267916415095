import { createAction, props } from '@ngrx/store';
import { Salutation } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/shared/utils';
import { PaginationOptions } from '../../models/pagination-options';

export const getSalutation = createAction(
    '[Salutation] Get Salutation',
    props<{ reset?: boolean }>()
);

export const getSalutationSuccess = createAction(
    '[Salutation] Get Salutation Success',
    props<{
        salutation: PaginatedApiResponse<Salutation>;
    }>()
);

export const getSalutationFailure = createAction(
    '[Salutation] Get Salutation Failure',
    props<{
        error: Error;
    }>()
);

export const updateSalutation = createAction(
    '[Salutation] Update Salutation',
    props<{
        paginationOptions: PaginationOptions;
    }>()
);

export const updateSalutationSuccess = createAction(
    '[Salutation] Update Salutation Success',
    props<{
        payload: PaginatedApiResponse<Salutation>;
    }>()
);

export const updateSalutationFailure = createAction(
    '[Salutation] Update Salutation Failure',
    props<{
        error: Error;
    }>()
);

export const querySalutation = createAction(
    '[Salutation] Query Salutation',
    props<{
        reset?: boolean;
        query: any;
    }>()
);

export const querySalutationSuccess = createAction(
    '[Salutation] Query Salutation Success',
    props<{
        salutation: PaginatedApiResponse<Salutation>;
    }>()
);

export const querySalutationFailure = createAction(
    '[Salutation] Query Salutation Failure',
    props<{
        error: Error;
    }>()
);

export const clearQuery = createAction('[Salutation] Clear Query');

export const disableSalutation = createAction(
    '[Salutation] Disable Salutation',
    props<{
        id: string;
        isDisabled: boolean;
    }>()
);

export const disableSalutationSuccess = createAction(
    '[Salutation] Disable Salutation Success',
    props<{
        id: string;
        isDisabled: boolean;
        updatedSalutation: Salutation;
    }>()
);

export const disableSalutationFailure = createAction(
    '[Salutation] Disable Salutation Failure',
    props<{
        id: string;
    }>()
);

export const getSalutationById = createAction(
    '[Salutation] Get Salutation By Id',
    props<{
        id: string;
    }>()
);

export const getSalutationByIdSuccess = createAction(
    '[Salutation] Get Salutation By Id Success',
    props<{
        id: string;
        salutation: Salutation;
    }>()
);

export const getSalutationByIdFailure = createAction(
    '[Salutation] Get Salutation By Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const deleteSalutation = createAction(
    '[Salutation] Salutation',
    props<{
        id: string;
    }>()
);

export const deleteSalutationSuccess = createAction(
    '[Salutation] Salutation Success',
    props<{
        id: string;
    }>()
);

export const deleteSalutationFailure = createAction(
    '[Salutation] Salutation Failure',
    props<{
        id: string;
        error: Error;
    }>()
);
