import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';
import * as opportunitiesActions from './opportunities.actions';
import { OpportunitiesService } from './opportunities.service';

@Injectable()
export class OpportunitiesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private opportunitiesService = inject(OpportunitiesService);
    private toastService = inject(WdxToastService);

    getContextualOpportunity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.getContextualOpportunity),
            switchMap((action) =>
                this.opportunitiesService
                    .getContextualOpportunity(
                        action.contextualType,
                        action.contextualId
                    )
                    .pipe(
                        map((opportunities) =>
                            opportunitiesActions.getContextualOpportunitySuccess(
                                {
                                    contextualId: action.contextualId,
                                    contextualType: action.contextualType,
                                    opportunities,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                opportunitiesActions.getContextualOpportunityFailure(
                                    {
                                        contextualId: action.contextualId,
                                        contextualType: action.contextualType,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    moveToStage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.moveToStage),
            switchMap((action) =>
                this.opportunitiesService
                    .moveToStage(action.opportunity.id, action.toStage)
                    .pipe(
                        map((opportunity) =>
                            opportunitiesActions.moveToStageSuccess({
                                opportunity,
                                fromStage: action.fromStage,
                                toStage: action.toStage,
                            })
                        ),
                        catchError((error) =>
                            of(
                                opportunitiesActions.moveToStageFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPipelineSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.getPipelineSummary),
            mergeMap((action) =>
                this.opportunitiesService
                    .getPipelineSummary(action.filters)
                    .pipe(
                        map((pipelineSummary) =>
                            opportunitiesActions.getPipelineSummarySuccess({
                                pipelineSummary,
                            })
                        ),
                        catchError((error) =>
                            of(
                                opportunitiesActions.getPipelineSummaryFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteOpportunity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.deleteOpportunity),
            switchMap((action) =>
                this.opportunitiesService
                    .deleteOpportunity(action.opportunity.id)
                    .pipe(
                        map(() =>
                            opportunitiesActions.deleteOpportunitySuccess({
                                stageCode:
                                    action.stageCode || GLOBAL_STATE_INDEX_ID,
                                opportunity: action.opportunity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                opportunitiesActions.deleteOpportunityFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteOpportunitySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(opportunitiesActions.deleteOpportunitySuccess),
                tap(() => {
                    this.toastService.show({
                        body: ['Opportunity successfully deleted'],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.getForId),
            switchMap((action) =>
                this.opportunitiesService.getForId(action.opportunityId).pipe(
                    map((opportunity) =>
                        opportunitiesActions.getForIdSuccess({
                            opportunityId: action.opportunityId,
                            opportunity,
                        })
                    ),
                    catchError((error) =>
                        of(
                            opportunitiesActions.getForIdFailure({
                                opportunityId: action.opportunityId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getAllMembersForOpportunity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.getAllMembers),
            switchMap((action) =>
                this.opportunitiesService
                    .getAllMembersForOpportunity(action.opportunityId)
                    .pipe(
                        map((teamMembers) =>
                            opportunitiesActions.getAllMembersSuccess({
                                opportunityId: action.opportunityId,
                                teamMembers,
                            })
                        ),
                        catchError((error) =>
                            of(
                                opportunitiesActions.getAllMembersFailure({
                                    opportunityId: action.opportunityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteTeamMemberFromOpportunity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.deleteTeamMember),
            mergeMap((action) =>
                this.opportunitiesService
                    .deleteTeamMemberFromOpportunity(
                        action.opportunityId,
                        action.memberId
                    )
                    .pipe(
                        map(() =>
                            opportunitiesActions.deleteTeamMemberSuccess({
                                opportunityId: action.opportunityId,
                                memberId: action.memberId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                opportunitiesActions.deleteTeamMemberFailure({
                                    opportunityId: action.opportunityId,
                                    memberId: action.memberId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getVisibilityForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(opportunitiesActions.getVisibilityForId),
            mergeMap((action) =>
                this.opportunitiesService.getVisibilityForId(action.id).pipe(
                    map((visibility) =>
                        opportunitiesActions.getVisibilityForIdSuccess({
                            id: action.id,
                            visibility,
                        })
                    ),
                    catchError((error) =>
                        of(
                            opportunitiesActions.getVisibilityForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    // prettier-ignore
}
