import { Injectable } from '@angular/core';
import {
    Activity,
    ActivityStatusType,
    ActivityType,
    ConversationStatus,
} from '@wdx/clmi/api-models';
import { WdxDateFormat, WdxDateTimeService } from '@wdx/shared/utils';
import { take } from 'rxjs/operators';
import { ReactiveFormDynamicDataService } from '../../../../../shared/services';

@Injectable({
    providedIn: 'root',
})
export class ToDoService {
    locale: string;

    constructor(
        private dateTimeService: WdxDateTimeService,
        private dynamicDataService: ReactiveFormDynamicDataService
    ) {
        this.dynamicDataService
            .getMeLocale()
            .pipe(take(1))
            .subscribe((meLocale) => (this.locale = meLocale.name));
    }

    isCompleteActivity(activity: Activity) {
        return activity?.status === ActivityStatusType.Complete;
    }

    isCancelledActivity(activity: Activity) {
        return activity?.status === ActivityStatusType.Cancelled;
    }

    isDraft(activity: Activity) {
        return activity.status === ActivityStatusType.Draft;
    }

    isAllDay(activity: Activity) {
        return activity.isAllDay;
    }

    isEndedActivity(activity: Activity) {
        return activity?.conversationStatus === ConversationStatus.Ended;
    }

    isOverdueActivity(activity: Activity) {
        const overdueDate = this.getActivityStatusDate(activity);
        if (!overdueDate || activity.activityType === ActivityType.Unblu) {
            return false;
        }
        return new Date().getTime() > new Date(overdueDate).getTime();
    }

    isEmail(activity: Activity) {
        return activity.activityType === ActivityType.Email;
    }

    isMessage(activity: Activity) {
        return [
            ActivityType.Email,
            ActivityType.Glia,
            ActivityType.Qwil,
            ActivityType.Sms,
            ActivityType.SecureMessage,
            ActivityType.Unblu,
        ].includes(activity.activityType);
    }

    isTaskOrWorkItem(activity: Activity) {
        return [ActivityType.WorkItem, ActivityType.Task].includes(
            activity.activityType
        );
    }

    getFormattedActivityStatusDate(activity: Activity) {
        if (this.isTaskOrWorkItem(activity)) {
            return this.dateTimeService.convertDateToViewFriendlyFormat(
                activity.due as unknown as string,
                { format: WdxDateFormat.RelativeDate }
            );
        }

        if (activity.activityType === ActivityType.Unblu) {
            return this.dateTimeService.convertDateToViewFriendlyFormat(
                (activity.dateUpdated || activity.start) as unknown as string,
                { format: WdxDateFormat.RelativeDateTime }
            );
        }

        return this.dateTimeService.convertDateToViewFriendlyFormat(
            activity.start as unknown as string,
            { format: WdxDateFormat.RelativeDateTime }
        );
    }

    getActivityStatusDate(activity: Activity) {
        if (this.isTaskOrWorkItem(activity)) {
            return activity.due as unknown as string;
        }
        return activity.start as unknown as string;
    }
}
