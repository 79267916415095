import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { AtomHighlightCircleModule } from '../../atoms/atom-highlight-circle/atom-highlight-circle.module';
import { AtomIconLetterModule } from '../../atoms/atom-icon-letter/atom-icon-letter.module';
import { MoleculeLegendComponent } from './molecule-legend.component';

@NgModule({
    imports: [
        AtomHighlightCircleModule,
        AtomIconLetterModule,
        CommonModule,
        WdxIconModule,
    ],
    declarations: [MoleculeLegendComponent],
    exports: [MoleculeLegendComponent],
})
export class MoleculeLegendModule {}
