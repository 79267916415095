import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MoleculeActionButtonModule } from '../../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { ActionButton } from '../../../../models/action-button.model';
import { FiledDocumentsService } from '../../../../shared/features/filed-documents/shared/services/filed-documents.service';
import { SystemEntity } from '@wdx/clmi/api-models';

@Component({
    selector: 'clmi-document-action-button',
    templateUrl: './action-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MoleculeActionButtonModule],
})
export class DocumentActionButtonComponent {
    @Input() entityId: string;
    @Input() entityType: SystemEntity;

    constructor(private filedDocumentsService: FiledDocumentsService) {}

    get actionButton(): ActionButton {
        return this.filedDocumentsService.getAddDocumentActionButton(
            this.entityId,
            this.entityType
        );
    }
}
