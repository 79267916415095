import { Injectable } from '@angular/core';
import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateAndUrl {
    data: Data;
    url: string;
    params: Params;
    queryParams: Params;
}

export interface RouterFeatureState {
    state: RouterStateAndUrl;
}

@Injectable({ providedIn: 'root' })
export class CustomRouteSerializer
    implements RouterStateSerializer<RouterStateAndUrl>
{
    serialize(routerState: RouterStateSnapshot): RouterStateAndUrl {
        let route = routerState.root;
        let params = {};
        let data = {};

        while (route.firstChild) {
            params = { ...params, ...route.params };
            data = { ...data, ...route.data };
            route = route.firstChild;
        }

        if (route) {
            params = { ...params, ...route.params };
            data = { ...data, ...route.data };
        }

        const {
            url,
            root: { queryParams },
        } = routerState;

        return { url, params, data, queryParams };
    }
}
