import { createSelector } from '@ngrx/store';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as salutationReducer from './salutation.reducer';

/**
 * Reducer state selector
 */
// eslint-disable-next-line max-len
export const getState = (state: {
    salutation: salutationReducer.State;
}): salutationReducer.State => state.salutation;

/**
 * Reducer property selectors
 */

export const getSalutationIsLoadingPage = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.salutation[GLOBAL_STATE_INDEX_ID]?.isLoadingPage
);
export const getSalutationHasLoadingPageError = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.salutation[GLOBAL_STATE_INDEX_ID]?.hasLoadingPageError
);
export const getSalutation = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.salutation[GLOBAL_STATE_INDEX_ID]?.infinity
);
export const getQueryIsLoadingPage = createSelector(
    getState,
    (state: salutationReducer.State) => state.query?.isLoadingPage
);
export const getQueryHasLoadingPageError = createSelector(
    getState,
    (state: salutationReducer.State) => state.query?.hasLoadingPageError
);
export const getQueryPaging = createSelector(
    getState,
    (state: salutationReducer.State) => state.query?.infinity?.paging
);
export const getQuery = createSelector(
    getState,
    (state: salutationReducer.State) => state.query?.infinity?.combinedList
);
export const getSalutationOrQueryIsLoadingPage = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.query?.isLoadingPage ||
        state.salutation[GLOBAL_STATE_INDEX_ID].isLoadingPage
);
export const getSalutationOrQueryHasLoadingPageError = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.query?.hasLoadingPageError ||
        state.salutation[GLOBAL_STATE_INDEX_ID].hasLoadingPageError
);
export const getSalutationOrQueryPaging = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.query?.infinity?.paging ??
        state.salutation[GLOBAL_STATE_INDEX_ID]?.infinity?.paging
);
export const getSalutationOrQuery = createSelector(
    getState,
    (state: salutationReducer.State) =>
        state.query?.infinity?.combinedList ??
        state.salutation[GLOBAL_STATE_INDEX_ID]?.infinity?.combinedList
);
export const getSalutationTypeById = (props: { salutationName: string }) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.salutationName]?.single
    );
export const getSalutationTypeByIdIsLoading = (props: {
    salutationName: string;
}) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.salutationName]?.isLoadingSingle
    );
export const getSalutationTypeByIdHasLoadingError = (props: {
    salutationName: string;
}) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.salutationName]?.hasLoadingSingleError
    );
export const getSalutationInfinityCombinedList = (props: { id: string }) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.id]?.infinity?.combinedList
    );
export const getSalutationInfinityPaging = (props: { id: string }) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.id]?.infinity?.paging
    );

export const getAllSalutationIsLoadingPage = (props: { id: string }) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.id]?.isLoadingPage
    );
export const getAllSalutationHasLoadingPageError = (props: { id: string }) =>
    createSelector(
        getState,
        (state: salutationReducer.State) =>
            state.salutation[props.id]?.hasLoadingPageError
    );
