<div class="search-bar d-flex align-items-center">
    <div class="search-bar__prefix d-flex align-items-center flex-grow-1">
        <h5 class="search-bar__count no-wrap">
            <wdx-icon
                *ngIf="definition.isLocked"
                class="text-muted me-1"
                icon="lock"
                iconStyle="fas"
            ></wdx-icon>
            <span class="fw-normal">
                {{ definition?.label }}
            </span>
            <span
                [attr.data-cy]="'quantity-amount-' + definition?.label"
                [attr.data-cy-value]="amount"
                >({{ amount }})</span
            >
        </h5>

        <wdx-icon
            *ngIf="showValidation"
            [attr.data-cy]="'sub-form-header-validation-' + definition?.label"
            class="text-danger ms-2"
            icon="exclamation-triangle"
            [scale]="1"
        ></wdx-icon>

        <div
            *ngIf="showValidation && validationErrorMessage"
            [attr.data-cy]="'validation-error-message-' + definition?.label"
            class="ms-2 text-danger small"
        >
            {{ validationErrorMessage }}
        </div>
    </div>

    <div
        *ngIf="!disabled"
        class="d-flex align-items-center position-relative flex-shrink-0"
    >
        <div class="search-bar__search-input"></div>
        <wdx-icon-button
            [attr.data-cy]="'btn-add-' + definition?.label"
            wdxStyle="outline-primary"
            wdxButton
            [size]="'sm'"
            (wdxOnClick)="onAddToArrayClicked()"
        >
            <wdx-icon [icon]="addIcon"></wdx-icon>
        </wdx-icon-button>
    </div>
</div>
