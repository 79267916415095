import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StatusType } from '@wdx/clmi/api-models';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { Severity } from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as documentTemplatesActions from './document-templates.actions';
import { DocumentTemplatesService } from './document-templates.service';

@Injectable()
export class DocumentTemplatesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private documentTemplatesService = inject(DocumentTemplatesService);
    private toastService = inject(WdxToastService);

    getDocumentTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.getDocumentTemplates),
            mergeMap((action) =>
                this.documentTemplatesService
                    .getDocumentTemplates({
                        entityType: action.entityType,
                        documentTypeCode: action.documentTypeCode,
                    })
                    .pipe(
                        map((documentTemplates) =>
                            documentTemplatesActions.getDocumentTemplatesSuccess(
                                {
                                    documentTemplates,
                                    entityType: action.entityType,
                                    documentTypeCode: action.documentTypeCode,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                documentTemplatesActions.getDocumentTemplatesFailure(
                                    {
                                        entityType: action.entityType,
                                        documentTypeCode:
                                            action.documentTypeCode,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
    generateDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.getDocument),
            switchMap((action) =>
                this.documentTemplatesService
                    .generateDocument(action.id, action.entityId)
                    .pipe(
                        map(() =>
                            documentTemplatesActions.getDocumentSuccess()
                        ),
                        catchError((error) =>
                            of(
                                documentTemplatesActions.getDocumentFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    toggleStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.toggleStatus),
            map((action) =>
                action.newValue
                    ? documentTemplatesActions.activateDocumentTemplate({
                          id: action.id,
                      })
                    : documentTemplatesActions.inactivateDocumentTemplate({
                          id: action.id,
                      })
            )
        )
    );

    activate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.activateDocumentTemplate),
            mergeMap((action) =>
                this.documentTemplatesService
                    .setDocumentStatus(action.id, StatusType.Active)
                    .pipe(
                        map(() =>
                            documentTemplatesActions.activateDocumentTemplateSuccess(
                                {
                                    id: action.id,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                documentTemplatesActions.activateDocumentTemplateFailure(
                                    {
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    inactivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.inactivateDocumentTemplate),
            mergeMap((action) =>
                this.documentTemplatesService
                    .setDocumentStatus(action.id, StatusType.Inactive)
                    .pipe(
                        map(() =>
                            documentTemplatesActions.inactivateDocumentTemplateSuccess(
                                {
                                    id: action.id,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                documentTemplatesActions.inactivateDocumentTemplateFailure(
                                    {
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.deleteDocumentTemplate),
            mergeMap((action) =>
                this.documentTemplatesService
                    .deleteDocumentTemplate(action.id)
                    .pipe(
                        map(() =>
                            documentTemplatesActions.deleteTemplateSuccess({
                                id: action.id,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentTemplatesActions.deleteTemplateFailure({
                                    id: action.id,
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    generateDocumentTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentTemplatesActions.generateDocumentTemplate),
            tap(() => {
                this.toastService.show({
                    body: [`Generating document template`],
                    severity: Severity.Warning,
                    hideCloseOnBody: true,
                    throbber: true,
                    autohide: false,
                    closeOthers: true,
                });
            }),
            mergeMap((action) =>
                this.documentTemplatesService
                    .generateDocumentTemplate(
                        action.entityId,
                        action.entityType,
                        action.templateId
                    )
                    .pipe(
                        map(() =>
                            documentTemplatesActions.generateDocumentTemplateSuccess(
                                {
                                    entityId: action.entityId,
                                    entityType: action.entityType,
                                    templateId: action.templateId,
                                    documentTemplateType: action.documentTemplateType
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                documentTemplatesActions.generateDocumentTemplateError(
                                    {
                                        entityId: action.entityId,
                                        entityType: action.entityType,
                                        templateId: action.templateId,
                                        documentTemplateType: action.documentTemplateType,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    generateDocumentTemplateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    documentTemplatesActions.generateDocumentTemplateSuccess
                ),
                tap(() => {
                    this.toastService.removeAll();
                    this.toastService.show({
                        body: [`Document template successfully generated.`],
                        severity: Severity.Success,
                        closeOthers: true,
                    });
                }),
                map((action) =>
                    documentTemplatesActions.resetResults({
                        entityType: action.documentTemplateType,
                    })
                )
            ),
        { dispatch: true }
    );
}
