import { Injectable, inject } from '@angular/core';
import {
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import {
    FieldDefinition,
    FormCondition,
    FormElementLayoutDefinition,
    FormElementType,
    FormFieldType,
    FormFunctionResult,
    UpdateField,
} from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { DynamicFormsService } from '../../+state/dynamic-forms';
import { CURRENCY_FIELD_TYPES } from '../../constants';
import { FormControlService } from '../form-control/form-control.service';
import { FormValidationService } from '../form-validation';

@Injectable()
export class FormProcessConditionDataService {
    private dynamicFormsService = inject(DynamicFormsService);
    private formValidationService = inject(FormValidationService);
    private formControlService = inject(FormControlService);

    /**
     *
     * @param form - This is the angular FormGroup
     * @param names - This should be a array of array names in hierarchical order
     * @param index - This is for when dealing with arrays with multiple records to get a index
     * @returns FormArray
     */
    getFormArray(
        form: FormGroup,
        names: string[],
        index?: number
    ): UntypedFormArray | undefined {
        /**
         * name[0] - This gets the first string in the array of name
         */
        const FIRST_ARRAY = form?.get(names[0]) as UntypedFormArray;

        if (!names?.length && !form && !FIRST_ARRAY) {
            return;
        }

        if (names?.length === 1) {
            return FIRST_ARRAY;
        }

        if (names?.length > 1) {
            return FIRST_ARRAY?.at(index as number)?.get(
                names[1]
            ) as UntypedFormArray;
        }
        return;
    }

    sum(form: FormGroup, of: string[]): number {
        let totalSum = 0;

        of.map((formField) => {
            const FORM_CONTROLS = this.formControlService.getFormControlData(
                form,
                formField
            );
            for (const FORM_CONTROL_DATA of FORM_CONTROLS) {
                const FIELD_VAL = FORM_CONTROL_DATA.formControl
                    ? FORM_CONTROL_DATA.formControl.value
                    : null;
                const isFieldCurrencyType = FIELD_VAL?.amount;
                const AMOUNT = isFieldCurrencyType
                    ? FIELD_VAL.amount
                    : FIELD_VAL;

                totalSum =
                    typeof AMOUNT === 'number' ? totalSum + AMOUNT : totalSum;
            }
        });

        return totalSum;
    }

    recalculate(
        form: FormGroup,
        field: string,
        conditions: FormCondition | undefined
    ): number {
        const CALCULATIONS = conditions?.calculations;
        const MAX = (
            conditions ? conditions?.calculations?.length : 0
        ) as number;
        let totalSum;
        let count = 0;

        while (count < MAX) {
            if (CALCULATIONS?.[count]?.field === field) {
                if (CALCULATIONS?.[count]?.equalTo?.sum) {
                    totalSum = this.sum(
                        form,
                        CALCULATIONS?.[count]?.equalTo?.sum?.of as string[]
                    );
                }

                count = MAX;
            }

            count++;
        }

        return totalSum as number;
    }

    updateValueAccordingToType(
        fieldType: FormFieldType,
        currentValue: any,
        newValue: any
    ) {
        if (CURRENCY_FIELD_TYPES.includes(fieldType)) {
            return {
                isoCode: currentValue?.isoCode ? currentValue?.isoCode : '',
                amount: newValue,
            };
        }

        return newValue;
    }

    /**
     *
     * @param {{formId: string; formValue: any;}} form - This is the formId and formValue. Please note that the formValue needs to be used
     * @param functionName - This is the name of the function that needs to be called
     * @returns Observable<FormFunctionResult> - This should return an observable with FormFunctionResult
     */
    getApiFunctionTrigger(
        form: {
            formId: string;
            formValue: any;
        },
        functionName: string,
        subFormName?: string,
        subFormIndex?: number
    ): Observable<FormFunctionResult> {
        return this.dynamicFormsService.getFormFunctionResult(
            form.formId,
            {
                functionName,
                formData: form.formValue,
            },
            subFormName,
            subFormIndex
        );
    }

    findFormControlUpdateValue(
        formControl: UntypedFormControl,
        updateField: UpdateField,
        fieldData: FieldDefinition,
        pristine: boolean
    ): void {
        // eslint-disable-next-line no-prototype-builtins
        if (updateField.hasOwnProperty('value') && formControl) {
            this.updateFormControlValue(
                formControl,
                this.isNull(updateField.value)
                    ? null
                    : this.updateValueAccordingToType(
                          fieldData.fieldType as FormFieldType,
                          formControl.value,
                          updateField.value
                      ),
                pristine
            );
        }
    }

    updateFormControlValue(
        formControl: UntypedFormControl,
        val: any,
        pristine: boolean,
        preventEmitter?: boolean
    ): void {
        const TOUCHED = formControl?.touched;

        if (formControl) {
            if (!pristine && !preventEmitter) {
                formControl.patchValue(val);
            }

            if (pristine || preventEmitter) {
                formControl.patchValue(val, {
                    onlySelf: false,
                    emitEvent: false,
                });
            }

            if (pristine) {
                formControl.markAsPristine();
            }

            if (!TOUCHED || pristine) {
                formControl.markAsUntouched();
            }
        }
    }

    updateFormControlRequiredStatues(
        formControl: UntypedFormControl | UntypedFormArray,
        isRequired?: boolean
    ): void {
        if (formControl && typeof isRequired === 'boolean') {
            if (isRequired) {
                formControl.addValidators(Validators.required);
            }

            if (!isRequired) {
                formControl.removeValidators(Validators.required);
            }

            formControl.updateValueAndValidity();
        }
    }

    /**
     * updateFormControlMinValidator will set a min validator if the fieldDefinition or subFormDefinition includes one.
     * When both are supplied the highest of the two values will be used
     *
     * @param formControl - This needs to be FormControl
     * @param fieldDefinition - This needs to be an object that has isDisabled || isRequired
     * @param subFormDefinition - Optionally accepts a second definition for subform array controls
     */
    updateFormControlMinValidator(
        formControl: UntypedFormControl | UntypedFormArray,
        fieldDefinition: FormElementLayoutDefinition & FieldDefinition,
        subFormDefinition?: FieldDefinition
    ): void {
        let min = fieldDefinition?.min;
        if (subFormDefinition && typeof subFormDefinition?.min === 'number') {
            min =
                typeof min === 'number'
                    ? Math.max(min, subFormDefinition.min)
                    : subFormDefinition.min;
        }

        if (typeof min === 'number') {
            const VALIDATOR = this.formValidationService.getMinValidator(
                fieldDefinition.fieldType ||
                    (fieldDefinition?.elementType as FormElementType),
                min
            );

            if (VALIDATOR.length) {
                formControl.addValidators(VALIDATOR[0]);
                formControl.updateValueAndValidity();
            }
        }
    }

    /**
     * updateFormControlMaxValidator will set a max validator if the fieldDefinition or subFormDefinition includes one.
     * When both are supplied the lowest of the two values will be used
     *
     * @param formControl - This needs to be FormControl
     * @param fieldDefinition - This needs to be an object that has isDisabled || isRequired
     * @param subFormDefinition - Optionally accepts a second definition for subform array controls
     */
    updateFormControlMaxValidator(
        formControl: UntypedFormControl | UntypedFormArray,
        fieldDefinition: FormElementLayoutDefinition & FieldDefinition,
        subFormDefinition?: FieldDefinition
    ): void {
        let max = fieldDefinition?.max;
        if (subFormDefinition && typeof subFormDefinition?.max === 'number') {
            max =
                typeof max === 'number'
                    ? Math.min(max, subFormDefinition.max)
                    : subFormDefinition.max;
        }
        if (typeof max === 'number') {
            const VALIDATOR = this.formValidationService.getMaxValidator(
                fieldDefinition.fieldType ||
                    (fieldDefinition?.elementType as FormElementType),
                max
            );

            if (VALIDATOR.length) {
                formControl.addValidators(VALIDATOR[0]);
                formControl.updateValueAndValidity();
            }
        }
    }

    updateFormControlValidation(
        formControl: UntypedFormControl,
        fieldDefinition: FieldDefinition
    ): void {
        const VALIDATORS =
            this.formValidationService.getValidators(fieldDefinition);

        if (formControl) {
            formControl.clearValidators();
            formControl.updateValueAndValidity();

            formControl.setValidators(VALIDATORS);
            formControl.updateValueAndValidity();
        }
    }

    /**
     * updateValidation will call the following to method of the same class
     * updateFormControlRequiredStatues
     *
     * @param formControl - This needs to be FormControl
     * @param fieldDefinition - This needs to be an object that has isDisabled || isRequired
     * @param subFormDefinition - Optionally accepts a second definition for subform array controls
     */
    updateValidation(
        formControl: UntypedFormControl | UntypedFormArray,
        elementLayoutDefinition: FormElementLayoutDefinition & FieldDefinition,
        subFormDefinition?: FieldDefinition
    ): void {
        const hasMin =
            subFormDefinition &&
            (!!elementLayoutDefinition?.min || !!subFormDefinition?.min);
        this.updateFormControlRequiredStatues(
            formControl,
            hasMin ? true : elementLayoutDefinition.isRequired
        );

        this.updateFormControlMinValidator(
            formControl,
            elementLayoutDefinition,
            subFormDefinition
        );

        this.updateFormControlMaxValidator(
            formControl,
            elementLayoutDefinition,
            subFormDefinition
        );
    }

    /**
     * Returns true if string is representation of YAML null value
     * @param value
     * @returns true if null string value
     */
    isNull(value: string): boolean {
        return value === 'null' || value === '~';
    }
}
