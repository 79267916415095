import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './routes/welcome/welcome.component';
import { ConditionalGuard } from './shared/guards/app/app-conditional.guard';
import { AppGuard } from './shared/guards/app/app.guard';
import { TenantAccessGuard } from './shared/guards/tenant-access';
import { WelcomeGuard } from './shared/guards/welcome/welcome.guard';

const routes: Routes = [
    {
        path: 'tenant-access',
        canActivate: [TenantAccessGuard],
        loadComponent: (): any =>
            import('./features/tenant-access/tenant-access.component').then(
                (mod) => mod.TenantAccessComponent
            ),
    },
    {
        path: '',
        component: WelcomeComponent,
        pathMatch: 'full',
        canActivate: [WelcomeGuard],
    },
    {
        path: ':tenant',
        canActivate: [AppGuard, ConditionalGuard],
        loadChildren: (): any =>
            import('./routes/app-route/app-route.module').then(
                (m) => m.AppRouteModule
            ),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
